<template>
    <div
        v-if="wheel && wheel.StyleId"
        :class="[
            'share-container',
            'd-flex',
            vertical && 'flex-column justify-content-end align-items-end',
            horizontal && 'justify-content-end align-items-center',
            'w-100'
        ]"
    >
            <div
                :class="[
                    'd-flex justify-content-end align-items-center',
                    (showShareUrl || showMessageCopy) && 'px-2',
                    'cursor-pointer toggle-active',
                    showShareUrl &&'bg-brand-body py-1 me-3',
                    'animated-background',
                    'rounded'
                ]"
                @click.stop="copyURL"
            >
                <Icon
                    v-show="showShareUrl"
                    class="me-3"
                    icon-name="copy"
                />
                <input
                    type="text"
                    :value="getShareUrl()"
                    :class="[
                        'share-url-input text-muted',
                        'border-0 bg-transparent',
                        'cursor-pointer toggle-active',
                        showShareUrl && 'expand'
                    ]"
                    style="font-size: 80%"
                    readonly
                >

                <div
                    v-if="showMessageCopy && !showShareUrl"
                    class="text-brand-primary fw-medium text-nowrap"
                >
                    <small>Copied to clipboard!</small>
                </div>
            </div>

            <div
                :class="[
                    onMenu && 'share-toggler',
                    'd-flex justify-content-center align-items-center',
                    'border border-color-brand-primary rounded',
                    'text-brand-primary',
                    'cursor-pointer toggle-active user-select-none',
                    big ? 'px-4 py-1':'p-2 p-sm-0'
                ]"
                @click.stop="shareUrl"
            >
                <Icon icon-name="shareIos" class="share-icon"/>
                <small v-if="big" class="text-uppercase fw-medium ms-3">Share</small>
            </div>
    </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'ShareButton',
    components: {
        Icon
    },
    data: function () {
        return {
            showMessageCopy: false,
            showShareUrl: false
        }
    },
    props: {
        vehicle: {
            type: Object,
            required: false
        },
        wheel: {
            type: Object,
            required: false
        },
        horizontal: {
            type: Boolean,
            required: false,
            default: true
        },
        vertical: {
            type: Boolean,
            required: false,
            default: false
        },
        big: {
            type: Boolean,
            required: false
        },
        onMenu: {
            type: Boolean,
            required: false
        },
        focusOnVehicle: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        getShareUrl: function () {
            const origin = window.location.href.split('/builder')[0];
            const isEmbeded = process.env.VUE_APP_ROOT_URL !== window.location.origin;
            const vehicleColorId = this.vehicle ?
            (this.vehicle.ColorSelected ? this.vehicle.ColorSelected:this.vehicle.ImgColorId) : null;
            let path = this.vehicle ?
                `/builder?${!this.focusOnVehicle ? 'wheels_zoom&':''}vehicle_id=${this.vehicle.Id}${vehicleColorId ? '&vehicle_color=' + vehicleColorId:''}&wheel_brand=${this.wheel.Brand.replace(/\s/g, '%20')}&wheel_style_id=${this.wheel.StyleId}&wheel_id=${this.wheel.Id}`:
                `/builder?wheels_view&wheel_brand=${this.wheel.Brand}&wheel_style_id=${this.wheel.StyleId}&wheel_id=${this.wheel.Id}`;
            return `${origin}${isEmbeded ? '/autosync-visualizer':''}${path}${!isEmbeded ? '&key='+this.$store.getters.key : ''}`;
        },
        copyURL(event) {
            const clipboardData =
            event.clipboardData ||
            window.clipboardData ||
            event.originalEvent?.clipboardData ||
            navigator.clipboard;
            clipboardData.writeText(this.getShareUrl());
            this.showMessageCopy = true;
        },
        shareUrl: function (event) {
            if (navigator.share) {
                this.copyURL(event);

                navigator.share({
                    title: 'Share this vehicle configuration',
                    url: this.getShareUrl()
                }).then(() => {
                    // eslint-disable-next-line
                    console.log('Thanks for sharing!');
                })
                .catch((error) =>{
                    // eslint-disable-next-line
                    console.error(error);
                    this.showShareUrl = true;
                });
            } else {
                this.showShareUrl = true;
            }
        }
    },
    watch: {
        showMessageCopy: function (newVal) {
            if (newVal) {
                this.showShareUrl = false;

                setTimeout(() => {
                    this.showMessageCopy = false;
                }, 2000);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.share-container {
    // Same as in Options button
    .share-toggler {
        height: 38px;
        max-height: 38px;
        width: 38px;
        max-width: 38px;

        .share-icon {
            font-size: 18px
        }
    }

    .share-url-input {
        width: 0px;
        transition: width .6s ease-out;
        -o-transition: width .6s ease-out;
        -moz-transition: width .6s ease-out;
        -webkit-transition: width .6s ease-out;

        &.expand {
            width: 210px;
        }
    }

    .animated-background {
        transition: background-color .6s ease-out;
        -o-transition: background-color .6s ease-out;
        -moz-transition: background-color .6s ease-out;
        -webkit-transition: background-color .6s ease-out;
    }

    &.small {
        bottom: 50px;
        height: 40px;
    }

    &.mobile {
        bottom: 115px;
        font-size: 80%;
        z-index: 1;

        .share-url-input {
            &.expand {
                width: 200px;
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    // Same as in Options button
    .share-container {
        .share-toggler {
            height: 30px;
            max-height: 30px;
            width: 30px;
            max-width: 30px;
        }

        .share-url-input {
            width: 0px;
            transition: width .6s ease-out;
            -o-transition: width .6s ease-out;
            -moz-transition: width .6s ease-out;
            -webkit-transition: width .6s ease-out;

            &.expand {
                width: 100px;
            }
        }
    }
}
</style>