<template>
    <Container small :full-page="!previewerLoaded">
        <Garage
            v-if="showGarage"
            :open="showGarage"
            :vehicle-id="vehicle?.Id || null"
            @close="showGarage = false"
        />

        <Favorites
            v-if="wheelsBaseUrl"
            :open="showFavorites"
            :wheel-selected="wheelSelected"
            :wheel-base-url="wheelsBaseUrl"
            :vehicle="vehicle"
            @close="showFavorites = false"
        />

        <ColorPicker
            v-if="vehicle && previewerLoaded"
            :colors="getColorsFiltered(vehicle.Colors)"
            :color-current="`${vehicleColor}`"
            :open="openColorPicker"
            @close="colorPickerState"
            @change-current-color="changeCurrentColor"
        />

        <ZoomVehicleImages
            v-if="vehicle && wheelsBaseUrl"
            :vehicle="vehicle"
            :vehicle-images="zoomVehicleImages"
            :vehicle-coordinates="vehicleCoordinates"
            :vehicle-angles-img-codes="vehicleAnglesImgCodes"
            :vehicle-image-wheel-diameter="vehicleInImageWheelDiameters"
            :vehicle-side="zoomVehicleSideSelected"
            :wheel="wheelSelected"
            :wheel-diameter="wheelDiameterSelected"
            :wheel-img-url-base="wheelsBaseUrl"
            :open="openZoomVehicle"
            rotate-on-mobile
            @close="zoomVehicleState"
        />

        <ZoomWheelImages
            v-if="wheelSelected.StyleId && wheelsBaseUrl"
            :wheel="wheelZoomSelected"
            :wheel-img-url-base="wheelsBaseUrl"
            :wheel-view="zoomWheelViewSelected"
            :vehicle="vehicle"
            :open="openZoomWheel"
            @close="zoomWheelState"
        />

        <RequestQuoteForm
            v-if="wheelSelected.StyleId"
            :open="wheelRequestQuoteSelected !== null ? true:false"
            :vehicle="vehicle"
            :wheel="wheelRequestQuoteSelected || {}"
            @close="closeRequestQuote"
        />

        <VehicleConfiguration
            v-if="vehicle"
            :open="openVehicleConfigurationSelection"
            :vehicle="vehicle"
            :vehicle-configurations="vehicleConfigurations"
            :configuration-option="showOptionVehicleConfiguration"
            @change-vehicle-configuration="changeVehicleConfigurationSelected"
            @close="vehicleConfigurationSelectionState"
        />
        <section
            :class="[
                'col-12 m-0 p-0',
                'text-brand-text bg-brand-body',
                'visualizer-container',
                (showFiltersOptions || showWheelDetails) && 'allow-overflow',
                loadFilters && (wheelVisualizer || previewerLoaded) && !(wheelVisualizer && wheelSelected && !wheelSelected.StyleId) && 'shadow-sm',
                (!$store.getters.device.isMobile || ($store.getters.device.isMobile && !$store.getters.device.isLandscape)) && !showFiltersOptions && !showWheelSpecifications && 'sticky-top',
                (openWheelDetails || wheelVisualizer) && showWheelSpecifications && 'remove-max-heigth',
                (!showFiltersOptions && !showWheelDetails && $store.getters.device.isMobile && $store.getters.device.isLandscape) && 'remove-max-heigth'
            ]"
        >
            <div class="d-flex flex-column justify-content-start align-items-start">
                <div v-if="showWheelDetails" class="w-100 order-1">
                    <WheelDetails
                        :wheel="wheelSelected"
                        :wheel-diameter="wheelDiameterSelected"
                        :wheel-configurations="wheelConfigurations"
                        :wheel-img-url-base="wheelsBaseUrl"
                        :wheels-pagination="wheelsPagination"
                        :vehicle="vehicle"
                        :vehicle-large-image="getVehicleImageUrl(vehicleLargeImageFront)"
                        :vehicle-coordinates="vehicleCoordinates"
                        :vehicle-angles-img-codes="vehicleAnglesImgCodes"
                        :vehicle-image-wheel-diameter="vehicleInImageWheelDiameters"
                        :hide-specifications="hideSpecifications"
                        :start-with-specifications="(!wheelVisualizer || styleId) ? true:false"
                        :show-extra-filters="wheelVisualizer"
                        @show-specifications="toggleWheelSpecifications"
                        @view-selected="viewSelected"
                        @zoom-image-on="zoomWheelImageOn"
                        @request-quote="openRequestQuote"
                        @change-wheel-configuration="changeWheelConfiguration"
                        @close="closeWheelDetails()"
                    >
                        <div class="go-back-button" @click.stop="wheelDetailsBack()">
                            <GoBackButton disable-history-action/>
                        </div>

                        <div class="options-button d-flex justify-content-end align-items-center">

                            <div class="me-2 me-sm-3">
                                <IconButton zoom-in @activated="toggleZoom('wheel')"/>
                            </div>
                            
                            <div class="me-2 me-sm-3">
                                <ShareButton
                                    :vehicle="vehicle"
                                    :wheel="wheelSelected"
                                    on-menu
                                />
                            </div>

                            <div class="me-2 me-sm-3">
                                <IconButton garage @activated="showGarage = true"/>
                            </div>

                            <div>
                                <IconButton favorite @activated="showFavorites = true"/>
                            </div>
                        </div>
                    </WheelDetails>
                </div>

                <div v-if="wheelVisualizer && wheelSelected && !wheelSelected.StyleId" class="w-100 order-1">
                    <div class="w-100 h-100 py-5 position-relative">
                        <div class="go-back-button" @click="$router.push( directLink ? '/home' : '/wheels')">
                            <GoBackButton disable-history-action/>
                        </div>
                    </div>
                </div>

                <div
                    v-show="!showWheelSpecifications && (wheelVisualizer || (!wheelVisualizer && vehicle))"
                    class="w-100 order-2"
                >
                    <Filters
                        v-if="loadFilters && !(wheelVisualizer && wheelSelected && !wheelSelected.StyleId)"
                        class="w-100 filters-container"
                        :show-applications="wheelVisualizer"
                        :application="getWheelApplication"
                        :tags="getVehicleTags"
                        :is-dually="getDrw"
                        :brand="brandId"
                        :lug-count="getLugCount"
                        :bolt-circle="getBoltCircle"
                        :reset-filters="resetFilters"
                        :update-filters="updateFilters"
                        :vehicle-diameters="vehicleDiameters"
                        @change-filters="changeFilters"
                        @active="filtersAreActive"
                    />
                </div>

                <!-- Remove "!wheelVisualizer" from the conditional to allow the previewer on wheels page -->
                <div
                    v-if="!wheelVisualizer && !openWheelDetails && wheelsBaseUrl && wheelSelected && vehicle"
                    :class="[
                        'w-100',
                        'order-0',
                        'previewer',
                        'bg-brand-body',
                        'position-relative'
                    ]"
                >
                    <div
                        v-if="!allowPreviewerVisualization"
                        class="previewer-disabled-message w-100 h-100 d-flex flex-column justify-content-center align-items-center text-center p-2"
                    >
                        <div class="d-block d-md-none bg-disabled-message p-3 p-sm-5 rounded position-relative">
                            <div style="position: absolute; top: 0; right: 0;" class="m-2">
                                <CloseButton @click.stop="selectRandomWheel"/>
                            </div>

                            <h3 class="fw-medium">Ouch...</h3>

                            <span class="d-block text-muted fw-medium border-bottom pb-2">
                                That wheel is not made
                                <br>
                                for this vehicle.
                            </span>
                            <span class="d-block text-muted fw-regular">
                                <small>
                                    Available wheels for this vehicle are listed below.
                                </small>
                            </span>
                        </div> 

                        <div class="d-none d-md-block bg-disabled-message p-3 p-sm-5 rounded border-2 shadow position-relative">
                            <div style="position: absolute; top: 0; right: 0;" class="m-2">
                                <CloseButton @click.stop="selectRandomWheel"/>
                            </div>

                            <h2 class="fw-medium">Ouch...</h2>

                            <h6 class="text-muted fw-regular border-bottom pb-2">
                                That wheel is not available for this vehicle.
                            </h6>
                            <h6 class="text-muted fw-regular">
                                <small>
                                    Available wheels for this vehicle are listed below.
                                </small>
                            </h6>
                        </div>                        

                        <div class="go-back-button" @click="$router.push(`/vehicles/make/${vehicle.Make}`)">
                            <GoBackButton disable-history-action/>
                        </div>
                    </div>
                    
                    <Previewer
                        :class="[
                            !allowPreviewerVisualization && 'previewer-disabled',
                            loadingVehicle && 'loading'
                        ]"
                        :vehicle-large-image-back="getVehicleImageUrl(vehicleLargeImageBack)"
                        :vehicle-small-image-back="getVehicleImageUrl(vehicleSmallImageBack)"
                        :vehicle-large-image-side="getVehicleImageUrl(vehicleLargeImageSide)"
                        :vehicle-small-image-side="getVehicleImageUrl(vehicleSmallImageSide)"
                        :vehicle-large-image-front="getVehicleImageUrl(vehicleLargeImageFront)"
                        :vehicle-small-image-front="getVehicleImageUrl(vehicleSmallImageFront)"
                        :vehicle-coordinates="vehicleCoordinates"
                        :vehicle-angles-img-codes="vehicleAnglesImgCodes"
                        :vehicle-image-wheel-diameter="vehicleInImageWheelDiameters"
                        :wheel="wheelSelected"
                        :wheel-img-url-base="wheelsBaseUrl"
                        :wheel-diameter="wheelDiameterSelected"
                        :has-wheels="hasWheels && allowPreviewerVisualization"
                        :loading="loading"
                        @side-selected="sideSelected"
                        @zoom-image-on="zoomVehicleImageOn"
                        @image-loaded="previewerImageLoaded"
                    >
                        <div
                            v-if="allowPreviewerVisualization"
                            class="options-button d-flex justify-content-end align-items-center"
                        >
                            <div class="me-2 me-sm-3">
                                <IconButton zoom-in @activated="toggleZoom('vehicle')"/>
                            </div>

                            <div class="me-2 me-sm-3">
                                <ShareButton
                                    :vehicle="vehicle"
                                    :wheel="wheelSelected"
                                    on-menu
                                    focus-on-vehicle
                                />
                            </div>

                            <div class="me-2 me-sm-3">
                                <IconButton garage @activated="showGarage = true"/>
                            </div>

                            <div>
                                <IconButton favorite @activated="showFavorites = true"/>
                            </div>
                        </div>

                        <div
                            v-if="allowPreviewerVisualization"
                            class="go-back-button"
                            @click="showWheelsList && enableAccesories ? (showWheelsList = false) : $router.push(`/vehicles/make/${vehicle.Make}`)"
                        >
                            <GoBackButton disable-history-action/>
                        </div>

                        <div
                            v-show="allowPreviewerVisualization && (previewerLoaded || !hasWheels)"
                            class="w-100 tools"
                        >
                            <CustomizeTools
                                v-if="vehicle && allowPreviewerVisualization"
                                :enable-tools="previewerLoaded && wheels.length > 0"
                                :vehicle="vehicle"
                                :vehicle-configurations="vehicleConfigurations"
                                :color-picker-allowed="colorPickerIsAllowed"
                                vehicle-configuration-on-bar
                                :wheel-img-url-base="wheelsBaseUrl"
                                :wheel="wheelSelected"
                                @wheel-details="showDetails"
                                @display-color-picker="colorPickerState"
                                @display-vehicle-configuration="changeVehicleConfigurations"
                                @change-vehicle-configuration="changeVehicleConfigurationSelected"
                            />
                        </div>
                    </Previewer>
                </div>
            </div>
        </section>

        <section
            v-if="!wheelVisualizer && !loading && !showWheelSpecifications && !showWheelsList && wheelsBaseUrl && wheelSelected && vehicle"
            :class="[
                'col-12 m-0 p-0',
                'bg-brand-body',
                'product-card',
                'd-flex flex-column flex-md-row justify-content-center align-items-center'
            ]"
        >
            <div class="mx-lg-3">
                <Card
                    :link="`/builder?vehicle_make=${encodeURIComponent(vehicle.Make)}&vehicle_model=${encodeURIComponent(vehicle.Model)}&vehicle_year=${vehicle.Year}`"
                    @click="showWheelsList=true"
                >
                    <div class="mt-3 text-center">
                        <small class="text-muted">CUSTOM</small>
                        <h5 class="fw-bold text-brand-text">WHEELS</h5>
                    </div>
                    <img :src="`${wheelsBaseUrl}${wheelSelected.Img0001}`" alt="Wheels" class="mb-3 user-select-none">
                </Card>
            </div>

            <div class="mx-lg-3">
                <Card :link="`/builder-accessories?vehicle_id=${vehicle.Id}`">
                    <div class="mt-3 text-center">
                        <small class="text-muted">EXTERIOR</small>
                        <h5 class="fw-bold text-brand-text">ACCESSORIES</h5>
                    </div>
                    <img src="https://storage.googleapis.com/autosync-vvs/static/temp/accessories.png" alt="Wheels" class="mb-3 user-select-none">
                </Card>
            </div>
        </section>

        <section
            v-show="!showWheelSpecifications && (showWheelsList || wheelVisualizer)"
            :class="[
                'col-12 m-0 p-0',
                'bg-brand-body'
            ]"
        >
            <div
                v-if="!wheelVisualizer && !vehicle && !loading"
                class="d-flex flex-column justify-content-center align-items-center w-100 vh-100 text-center text-muted py-5"
            >
                <h1 class="fw-medium">Ooops!</h1>
                <h6 class="fw-regular">The vehicle selected is not available</h6>
                <br>
                <p
                    class="text-brand-primary fw-medium cursor-pointer toggle-active"
                    @click="$router.go(-1)"
                ><u>Go back to the previous page</u></p>
            </div>

            <div v-else class="container-fluid m-0 p-0">
                <div v-show="!previewerLoaded && !wheelVisualizer && !loading && wheels.length > 0" class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0">
                    <div class="col-12 d-flex justify-content-center align-items-center m-0 p-0">
                        <div class="py-3">
                            <DotsFlashingLoader big/>
                        </div>
                    </div>
                </div>
                <div
                    v-show="!wheelVisualizer && !loading && wheels.length == 0"
                    class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0"
                >
                    <!-- Wheels message when response has 0 items -->
                    <div v-if="wheels.length == 0 && !loading" class="col-12 my-5 text-center">
                        <h5 class="text-muted fw-medium">WHEELS COMING SOON</h5>
                        <small class="fw-regular" style="font-size: 65%">SELECT DIFFERENT OPTIONS</small>
                    </div>
                </div>
                <div v-show="(previewerLoaded || wheelVisualizer) && !loading" class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0">
                    <!-- Wheels option -->
                    <WheelCard
                        v-for="wheel in wheels"
                        :key="wheel.StyleId"
                        :wheel="wheel"
                        :wheel-base-url="wheelsBaseUrl"
                        :wheel-selected="wheelSelected"
                        :wheel-selected-image="wheelSelected.StyleId == wheel.StyleId ? wheelSelected.Img0001:null"
                        :wheel-selection-border="wheelVisualizer || allowPreviewerVisualization"
                        :wheel-diameter="wheelDiameterSelected"
                        :vehicle-id="vehicle ? vehicle.Id : null"
                        @wheel-select="wheelSelect"
                        @wheelFavoriteToggle="wheelFavoriteToggle"
                    />
                </div>
                <div v-show="previewerLoaded || showWheelDetails" class="row p-0 m-0">
                    <div class="col-12 px-3 px-sm-4 px-lg-5 text-center user-select-none mb-4">
                        <Pagination
                            scroll
                            :show-pagination="wheelsPagination.moreItems || wheelsPagination.current != null"
                            :items-pagination="wheelsPagination"
                            @previous-page="previousWheelsPage"
                            @next-page="nextWheelsPage"
                            @change-page-size="changePageSize"
                        />
                    </div>
                </div>
            </div>
        </section>
    </Container>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import apiPostRequest from "@/utils/apiPostRequest";

import vehiclesBodyTypeBySubmodel from '@/components/builder/mixins/vehiclesBodyTypeBySubmodel';
import vehiclesRearAxleBySubmodel from '@/components/builder/mixins/vehiclesRearAxleBySubmodel';

import Container from "@/components/Container.vue";
import Favorites from "@/components/builder/Favorites.vue";
import Garage from "@/components/builder/Garage.vue";
import Previewer from "@/components/builder/Previewer.vue";
import ColorPicker from "@/components/builder/ColorPicker.vue";
import Filters from "@/components/builder/Filters.vue";
import CustomizeTools from "@/components/builder/CustomizeTools.vue";
import ZoomVehicleImages from "@/components/builder/ZoomVehicleImages.vue";
import ZoomWheelImages from "@/components/builder/ZoomWheelImages.vue";
import WheelDetails from "@/components/builder/WheelDetails.vue";
import RequestQuoteForm from "@/components/builder/RequestQuoteForm.vue";
import VehicleConfiguration from "@/components/builder/VehicleConfiguration.vue";
import WheelCard from "@/components/wheels/WheelCard.vue";
import Pagination from "@/components/shared/Pagination.vue";
import GoBackButton from "@/components/shared/GoBackButton.vue";
import CloseButton from "@/components/shared/CloseButton.vue";
import DotsFlashingLoader from '@/components/shared/DotsFlashingLoader.vue';
import ShareButton from '@/components/shared/ShareButton.vue';
import IconButton from '@/components/shared/IconButton.vue';
import Card from "@/components/shared/Card.vue";

export default {
    name: 'Builder',
    components: {
        Container,
        Card,
        WheelCard,
        Previewer,
        Filters,
        CustomizeTools,
        Favorites,
        Garage,
        ColorPicker,
        Pagination,
        GoBackButton,
        ZoomVehicleImages,
        ZoomWheelImages,
        WheelDetails,
        RequestQuoteForm,
        CloseButton,
        VehicleConfiguration,
        DotsFlashingLoader,
        ShareButton,
        IconButton
    },
    mixins: [
        vehiclesBodyTypeBySubmodel,
        vehiclesRearAxleBySubmodel
    ],
    data: function () {
        return {
            vehicle: null,
            vehicleBaseUrl: null,
            vehicleLogoBaseUrl: null,
            vehicleCurrentColor: null,
            vehicleConfigurations: null,
            vehicleAnglesImgCodes: null,
            vehicleCoordinates: null,
            vehiclesRequested: null,
            vehicleSelectedSide: null,
            showOptionVehicleConfiguration: null,
            wheels: [],
            wheelSelectedFromWheelVisualizer: false,
            wheelSelectedFromWheelVisualizerAvailable: null,
            wheelSelected: {
                StyleId: null
            },
            wheelDiameterSelected: null,
            wheelZoomSelected: null,
            wheelRequestQuoteSelected: null,
            wheelConfigurations: null,
            wheelsBaseUrl: null,
            wheelsLogoBaseUrl: null,
            wheelsFilter: null,
            wheelsPagination: {
                loading: false,
                current: null,
                moreItems: null,
                pageSize: 24
            },
            wheelPreSelected: false,
            wheelSelectedView: null,
            zoomWheelViewSelected: null,
            zoomVehicleSideSelected: null,
            showWheelSpecifications: false,
            showFavorites: false,
            showGarage: false,
            showFiltersOptions: false,
            openWheelDetails: false,
            openZoomVehicle: false,
            openZoomWheel: false,
            openColorPicker: false,
            openVehicleConfigurationSelection: false,
            hideSpecifications: false,
            resetFilters: false,
            updateFilters: false,
            loading: true,
            loadFilters: false,
            previewerLoaded: false,
            loadingVehicle: false,
            showWheelsList: false,
            enableAccesories: false
        };
    },
    computed: {
        directLink: function () {
            return 'direct_link' in this.$route.query || null;
        },
        hasWheels: function () {
            return this.wheels.length == 0 ? false:true;
        },
        brandId: function () {
            return this.$route.query.wheel_brand || '';
        },
        styleId: function () {
            return this.$route.query.wheel_style_id || '';
        },
        wheelId: function () {
            return this.$route.query.wheel_id || null;
        },
        wheelPageNumber: function () {
            if (this.wheelPreSelected) {
                return this.$route.query.wheel_p_number || '';                
            }
            return null;
        },
        wheelZoom: function () {
            if ('wheels_zoom' in this.$route.query) {
                return true
            }
            return false;
        },
        wheelVisualizer: function () {
            if ('wheels_view' in this.$route.query) {
                return true
            }
            return false;
        },
        allowPreviewerVisualization: function () {
            return !this.wheelSelectedFromWheelVisualizer || this.wheelSelectedFromWheelVisualizerAvailable;
        },
        getWheelApplication: function () {
            const wheelTags = {
                Car: 'Car',
                SUV: 'SUV',
                Truck: 'Truck',
                Van: 'Truck/SUV',
                UTV: 'UTV/ATV/Golf',
            };
            return wheelTags[this.vehicleType] || null;
        },
        getBoltCircle: function () {
            if (this.vehicle) {
                return this.vehicle.BoltCircle;
            }
            return null;
        },
        getLugCount: function () {
            if (this.vehicle) {
                return this.vehicle.LugCount;
            }
            return null;
        },
        getVehicleTags: function () {
            if (this.vehicle) {
                return this.vehicle.Tags;
            }
            return null;
        },
        getNicheTag: function () {
            if (this.vehicle) {
                return this.vehicle.NicheTag;
            }
            return null;
        },
        getDrw: function () {
            if (this.vehicle) {
                return this.vehicle.Drw;
            }
            return null;
        },
        vehicleId: function () {
            return this.$route.query.vehicle_id || '';
        },
        vehicleMake: function () {
            if ('wheels_view' in this.$route.query && !this.$route.query.vehicle_make) {
                return process.env.VUE_APP_BUILDER_DEFAULT_MAKE || '';
            }
            return this.$route.query.vehicle_make || '';
        },
        vehicleModel: function () {
            if ('wheels_view' in this.$route.query && !this.$route.query.vehicle_model) {
                return process.env.VUE_APP_BUILDER_DEFAULT_MODEL || '';
            }
            return this.$route.query.vehicle_model || '';
        },
        vehicleSubmodel: function () {
            if ('wheels_view' in this.$route.query && !this.$route.query.vehicle_submodel) {
                return process.env.VUE_APP_BUILDER_DEFAULT_SUBMODEL || '';
            }
            return this.$route.query.vehicle_submodel || '';
        },
        vehicleBody: function () {
            return this.$route.query.vehicle_body || '';
        },
        vehicleYear: function () {
            if ('wheels_view' in this.$route.query && !this.$route.query.vehicle_year) {
                return process.env.VUE_APP_BUILDER_DEFAULT_YEAR || '';
            }
            return this.$route.query.vehicle_year || '';
        },
        vehicleColor: function () {
            if ('wheels_view' in this.$route.query && !this.vehicleCurrentColor) {
                return process.env.VUE_APP_BUILDER_DEFAULT_COLOR || '';
            }
            return this.vehicleCurrentColor || this.$route.query.vehicle_color || '';
        },
        vehicleInImageWheelDiameters: function () {
            if (this.vehicle && this.vehicle.InImageWheelDiameters) {
                return this.vehicle.InImageWheelDiameters;
            }
            return null;
        },
        vehicleDiameters: function () {
            if (this.vehicle && this.vehicleInImageWheelDiameters) {
                const baseDiameter = this.vehicleInImageWheelDiameters.Front;
                const maxDiameter = this.vehicleInImageWheelDiameters.FrontMax;

                if (baseDiameter < maxDiameter) {
                    const validDiameters = Array(maxDiameter - baseDiameter + 1).fill().map((element, index) => index + baseDiameter);
                    return validDiameters.join(',');
                }
                
                return baseDiameter;
            }
            return null;
        },
        vehicleSmallImageFront: function () {
            try {
                return this.vehicle.Img032['350'] || null;
            } catch (error) {
                return null;
            }
        },
        vehicleSmallImageSide: function () {
            try {
                return this.vehicle.Img001['350'] || null;
            } catch (error) {
                return null;
            }
        },
        vehicleSmallImageBack: function () {
            try {
                return this.vehicle.Img014['350'] || null;
            } catch (error) {
                return null;
            }
        },
        vehicleLargeImageFront: function () {
            try {
                return this.vehicle.Img032['960'] || null;
            } catch (error) {
                return null;
            }
        },
        vehicleLargeImageSide: function () {
            try {
                return this.vehicle.Img001['960'] || null;
            } catch (error) {
                return null;
            }
        },
        vehicleLargeImageBack: function () {
            try {
                return this.vehicle.Img014['960'] || null;
            } catch (error) {
                return null;
            }
        },
        vehicleExtraLargeImageFront: function () {
            try {
                return this.vehicle.Img032['2400'] || null;
            } catch (error) {
                return null;
            }
        },
        vehicleExtraLargeImageSide: function () {
            try {
                return this.vehicle.Img001['2400'] || null;
            } catch (error) {
                return null;
            }
        },
        vehicleExtraLargeImageBack: function () {
            try {
                return this.vehicle.Img014['2400'] || null;
            } catch (error) {
                return null;
            }
        },
        colorPickerIsAllowed: function () {
            const colors = this.getColorsFiltered(this.vehicle.Colors);
            return colors && colors.length > 1;
        },
        zoomVehicleImages: function () {
            if (this.vehicle) {
                return {
                    front: this.getVehicleImageUrl(this.vehicleExtraLargeImageFront),
                    side: this.getVehicleImageUrl(this.vehicleExtraLargeImageSide),
                    back: this.getVehicleImageUrl(this.vehicleExtraLargeImageBack)
                };
            }

            return {
                front: null,
                side: null,
                back: null
            };
        },
        createWheelsRequest: function () {
            let pageNumberFilter = '';
            let request = `/wheels/styles?i-img0001&i-shortColors&i-diameters&i-imgSelectedValues`;

            if (this.wheelVisualizer) {
                request += this.brandId ? `&f-brand=${encodeURIComponent(this.brandId)}`:'';
                pageNumberFilter = this.wheelPageNumber ? `&p-number=${this.wheelPageNumber}`:'';
            }

            if (this.wheelsFilter) {
                request += this.wheelsFilter || '';
            }

            if (this.vehicleDiameters && !request.includes('f-diameters')) {
                request += `&f-diameters=${this.vehicleDiameters}`;
            }

            request += this.getVechileFiltersToWheels;

            if (this.styleId && this.brandId && this.loading) {
                request += this.brandId ? `&f-brand=${this.brandId}`:'';
                return `${request}&p-size=500`;
            }

            return `${request}&p-size=${this.wheelsPagination.pageSize}${pageNumberFilter}`;
        },
        createVehicleRequest: function () {
            let path = '/vehicles';
            let filters = '';
            
            if (this.vehicleId) {
                filters += `&f-id=${this.vehicleId}`;
            }
            if (this.vehicleMake) {
                filters += `&f-make=${encodeURIComponent(this.vehicleMake)}`;
            }
            if (this.vehicleModel) {
                filters += `&f-model=${encodeURIComponent(this.vehicleModel)}`;
            }
            if (this.vehicleSubmodel) {
                filters += `&f-submodel=${encodeURIComponent(this.vehicleSubmodel)}`;
            }
            if (this.vehicleYear) {
                filters += `&f-year=${this.vehicleYear}`;
            }
            if (this.vehicleColor) {
                filters += `&f-colorId=${this.vehicleColor}`;
            }

            return `${path}?i-img001=350,960,2400&i-img014=350,960,2400&i-img032=350,960,2400&i-colors&i-fitment&i-coordinates=1,14,32&p-size=500${filters}`;
        },
        getVechileFiltersToWheels: function () {
            if (!this.wheelVisualizer) {
                let filters = '';

                if (this.getLugCount) {
                    filters += `&f-lugCount=${this.getLugCount}`;
                }
                if (this.getBoltCircle) {
                    filters += `&f-boltCircle=${this.getBoltCircle}`;
                }
                if (this.getWheelApplication) {
                    filters += `&f-tags=${this.getWheelApplication}`;
                }
                if (this.getNicheTag) {
                    filters += `&f-nicheTag=${this.getNicheTag}`;
                }
                if (this.getVehicleTags) {
                    filters += `&f-tags2=${this.getDrw ? 'Dually':(this.getVehicleTags || '')}`;
                }
                return filters;
            }
            return '';
        },
        vehicleType: function () {
            if (!this.vehicle) {
                return null;
            }
            return this.vehicle.Type || null;
        },
        showWheelDetails: function () {
            return (this.openWheelDetails || this.wheelVisualizer) && this.wheelSelected.StyleId && this.wheelsBaseUrl ? true:false;
        }
    },
    methods: {
        loader: async function () {
            if ((this.vehicleMake || this.vehicleId) && !this.vehicle) {
                if (this.vehicleMake === 'Ford' && this.vehicleYear == '2022' && this.vehicleModel === 'F-150' && !this.$route.query.vehicle_submodel) {
                    this.$route.query.vehicle_body = 'Crew Cab Pickup';
                    this.enableAccesories = true;
                } else {
                    this.showWheelsList = true;
                    this.enableAccesories = false;
                }

                const vehicles = await apiRequest(this.createVehicleRequest);
                const vehicleData = this.getVehicleData(vehicles);
                let vehicleDefaultColor = '';
                let vehicleSelection = vehicleData;

                this.vehicleConfigurations = vehicleData.configurations;
                this.vehicleBaseUrl = vehicleData.imgBaseUrl;
                this.vehiclesRequested = vehicles.Vehicles;
                
                if (this.vehicleBody || this.vehicleSubmodel) {
                    const vehiclesFiltered = vehicles.Vehicles.filter(vehicle =>
                        (!this.vehicleBody || (this.vehicleBody && vehicle.Body == this.vehicleBody)) &&
                        (!this.vehicleSubmodel || (this.vehicleSubmodel && vehicle.Submodel == this.vehicleSubmodel))
                    );
                    const vehicleFilteredData = this.getVehicleData({Vehicles: vehiclesFiltered});
                    vehicleSelection = vehicleFilteredData;
                }
                
                this.vehicle = vehicleSelection.vehicle;
                this.vehicleCoordinates = vehicleSelection.coordinates;
                this.vehicleAnglesImgCodes = vehicleSelection.anglesImgCodes;
                vehicleDefaultColor = vehicleSelection.colorDefault;

                if (!this.wheelVisualizer && !this.vehicleCurrentColor) {
                    this.vehicleCurrentColor = `${vehicleDefaultColor}`;
                }
            }

            let wheelSelection = this.wheelSelected;
            if (this.$store.getters.vehicleBuilding.wheel) {
                wheelSelection = this.$store.getters.vehicleBuilding.wheel;
            }

            if (this.styleId) {
                wheelSelection = {
                    StyleId: this.styleId
                };
            }

            if (!this.wheelVisualizer && this.brandId) {
                this.$store.commit('wheelsFilters', {
                    key: 'Brands',
                    val: this.brandId
                });
            }

            if (this.brandId || this.vehicleMake || this.vehicleId) {
                this.wheelsFilter = this.getRequestFiltersFormat();

                let wheels = await apiRequest(this.createWheelsRequest);
                const wheelsData = this.getWheelsData(wheels);
                
                this.wheels = wheelsData.wheels;
                this.wheelsBaseUrl = wheelsData.imgBaseUrl;
                this.wheelsPagination.moreItems = wheels.MoreItems;
                this.wheelsPagination.current = this.wheelsPagination.moreItems ? 1:null;
            }
            
            const wheelIsOnList = this.wheels.find(wheel => wheel.StyleId == wheelSelection.StyleId);
            if (wheelIsOnList) {
                wheelSelection = wheelIsOnList;
            }

            if (!wheelIsOnList && this.wheels.length > 0) {
                const randomIndex = Math.floor((Math.random() * (this.wheels.length - 1)) + 0);
                wheelSelection = this.wheels[randomIndex];
            }

            if (this.wheels.length == 0 && this.wheelVisualizer) {
                wheelSelection = null;
            }

            if (wheelSelection) {
                this.wheelSelectedFromWheelVisualizer = this.$store.getters.vehicleBuilding.wheelSelectedWheelVisualizer;
                this.wheelSelectedFromWheelVisualizerAvailable = wheelIsOnList && this.wheelSelectedFromWheelVisualizer ? true:false;
                this.getWheelSelected(wheelSelection, null, null, false, this.wheelId);
            }
        },
        getWheelsData: function (response) {
            try {
                const wheels = response.WheelStyles.filter(wheel => wheel.Img0001 != '');
                const wheelsReIdentification = wheels.map(wheel => {
                    if (!wheel.StyleId && wheel.StyleId !== 0) {
                        wheel.StyleId = wheel.Id;
                        delete wheel.Id;
                    }
                    return wheel;
                });

                return {
                    wheels: wheelsReIdentification || null,
                    imgBaseUrl: response.ImgUrlBase || null
                };
            } catch (error) {
                return {
                    wheels: null,
                    imgBaseUrl: null
                };
            }
        },
        findWheelSelectedOnList: function (style, filterColor, filterDiameter, wheelId) {
            let wheel = null;
            let wheelColors = {};

            if (this.wheelConfigurations && this.wheelConfigurations.length > 0) {
                let wheelMaxScore = 0;

                this.wheelConfigurations.forEach(element => {
                    if (style.StyleId === element.StyleId && (filterColor || filterDiameter || wheelId)) {
                        let newScore = 0;
                        if (element.Id == wheelId) newScore += 3;
                        if (element.Diameter == filterDiameter) newScore += 2;
                        if (element.ShortColor == filterColor) newScore += 1;

                        if (newScore > wheelMaxScore) {
                            wheel = {...element};
                            wheel['Diameters'] = style.Diameters;
                            wheel['StyleColor'] = filterColor;
                            wheelMaxScore = newScore;
                        }
                    }

                    if (
                        (element.ShortColor && !wheelColors[element.ShortColor] && (!filterDiameter || filterDiameter == element.Diameter)) ||
                        element.Id == wheelId
                    ) {
                        wheelColors[element.ShortColor] = element.Img0001;
                    }
                });

                if (wheel) {
                    wheel['Colors'] = Object.keys(wheelColors).sort().reduce(
                        (obj, key) => {
                            obj[key] = wheelColors[key];
                            return obj;
                        },
                        {}
                    );
                }                
            }

            if (!wheel) {
                wheel = {
                    StyleId: null,
                    StyleColor: null,
                    Diameters: {},
                    Colors: {}
                };
            }

            return {wheel: wheel, wheelColors: wheelColors};
        },
        getWheelSelected: async function (style, color, diameter, manualSelection, wheelId = null, favorite = null) {
            if ( this.wheelSelected.StyleId !== style.StyleId || (color && this.wheelSelected.StyleColor != color) || (diameter && this.wheelSelected.Diameter != diameter) ) {                
                const filterColor = color || style.ImgShortColor || style.StyleColor;
                const filterDiameter = diameter || style.ImgDiameter;
                let result = this.findWheelSelectedOnList(style, filterColor, filterDiameter, wheelId);

                let wheel = null;
                let wheelsList = null;
                
                if (!result.wheel.StyleId) {
                    let request =  `/wheels?i-specs&p-size=500&i-tags&i-cap&i-img0001&i-img0002&i-img0003&i-img1001&i-img0100&i-img0200&i-img0300&f-styleId=${style.StyleId}`;
                    const vehicleFilters = this.getVechileFiltersToWheels.includes('&f-tags2=') ? this.getVechileFiltersToWheels.slice(0, this.getVechileFiltersToWheels.indexOf('&f-tags2=')):this.getVechileFiltersToWheels;
                    const diametersFilters = this.vehicleDiameters ? `&f-diameters=${this.vehicleDiameters}`:'';

                    const response = await apiRequest(this.wheelVisualizer ? request:`${request}${vehicleFilters}${diametersFilters}${this.getRequestFiltersFormat()}`);
                    wheelsList = this.getDrw ? response.Wheels.filter(wheel => wheel['LinkedDuallyWheels']):response.Wheels;

                    this.wheelConfigurations = wheelsList;
                    const result = this.findWheelSelectedOnList(style, filterColor, filterDiameter, wheelId || style.ImgWheelId);

                    if (result.wheel.StyleId) {
                        wheel = result.wheel;
                    } else if (!result.wheel.StyleId && this.wheelConfigurations && this.wheelConfigurations[0]) {
                        wheel = this.wheelConfigurations[0];
                        wheel['Diameters'] = style.Diameters;
                        wheel['StyleColor'] = this.wheelConfigurations[0].ShortColor;
                        wheel['Colors'] = result.wheelColors;
                    } else if (!wheel) {
                        wheel = {
                            StyleId: null,
                            StyleColor: null,
                            Diameters: {},
                            Colors: {}
                        };
                    }
                } else {
                    wheel = result.wheel;
                }
                
                // Removing wheel_id stored in current route to work aorund route view
                if ('wheel_id' in this.$route.query && wheel.Id) {
                    delete this.$route.query.wheel_id;
                }

                this.wheelSelected = wheel;
                this.wheelZoomSelected = this.wheelSelected;
                this.wheelDiameterSelected = this.wheelSelected.Diameter;

                if (this.wheelSelectedFromWheelVisualizer && manualSelection) {
                    this.wheelSelectedFromWheelVisualizer = false;
                }
                
                if (!this.wheelVisualizer) {
                    this.$store.commit('vehicleBuilding', {
                        wheel: wheel,
                        wheelBaseUrl: this.wheelsBaseUrl,
                        wheelDiameterSelected: this.wheelDiameterSelected,
                        wheelSelectedWheelVisualizer: this.wheelVisualizer ? true:false,
                        wheelPage: {
                            number: this.wheelsPagination.current,
                            moreItems: this.wheelsPagination.moreItems
                        }
                    });
                }

                if (favorite) this.wheelFavoriteToggle(this.wheelSelected);
            }
        },
        wheelFavoriteToggle: function (wheel) {
            let wheelFavorites = this.$store.getters.wheelFavorites;
            let wheelId = wheel.Id || wheel.ImgWheelId || null;
            let styleId = wheel.StyleId || null;
            let vehicleId = this.vehicle ? this.vehicle.Id : null;
            let toDelete = false;

            if (vehicleId && wheelFavorites[`${vehicleId}`] && wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`] && wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`][`${wheelId}`]) {
                toDelete = true;
                delete wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`][`${wheelId}`];

                if (!Object.keys(wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`]).length) {
                    delete wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`];
                }

                if (!Object.keys(wheelFavorites[`${vehicleId}`]['styles']).length) {
                    delete wheelFavorites[`${vehicleId}`];
                }
            } else if (this.wheelVisualizer && wheelFavorites['globals'] && wheelFavorites['globals']['styles'][`${styleId}`] && wheelFavorites['globals']['styles'][`${styleId}`][`${wheelId}`]) {
                toDelete = true;
                delete wheelFavorites['globals']['styles'][`${styleId}`][`${wheelId}`];

                if (!Object.keys(wheelFavorites['globals']['styles'][`${styleId}`]).length) {
                    delete wheelFavorites['globals']['styles'][`${styleId}`];
                }

                if (!Object.keys(wheelFavorites['globals']['styles']).length) {
                    delete wheelFavorites['globals'];
                }
            }

            if (!toDelete && vehicleId) {
                if (!wheelFavorites[`${vehicleId}`]) {
                    wheelFavorites[`${vehicleId}`] = {};
                    wheelFavorites[`${vehicleId}`]['name'] = `${this.vehicle.Year} ${this.vehicle.Make} ${this.vehicle.Model} ${this.vehicle.Submodel}`;
                    wheelFavorites[`${vehicleId}`]['styles'] = {};
                    wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`] = {};
                    wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`][`${wheelId}`] = wheel;

                } else {
                    if (!wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`]) {
                        wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`] = {};
                    }
                    wheelFavorites[`${vehicleId}`]['styles'][`${styleId}`][`${wheelId}`] = wheel;
                }

                this.registerFavoriteOnAPI(wheelId);
            }

            if (!toDelete && this.wheelVisualizer) {
                if (!wheelFavorites['globals']) {
                    wheelFavorites['globals'] = {};
                    wheelFavorites['globals']['styles'] = {};
                    wheelFavorites['globals']['styles'][`${styleId}`] = {};
                    wheelFavorites['globals']['styles'][`${styleId}`][`${wheelId}`] = wheel;

                } else {
                    if (!wheelFavorites['globals']['styles'][`${styleId}`]) {
                        wheelFavorites['globals']['styles'][`${styleId}`] = {};
                    }
                    wheelFavorites['globals']['styles'][`${styleId}`][`${wheelId}`] = wheel;
                }

                this.registerFavoriteOnAPI(wheelId);
            }

            this.$store.commit('wheelFavorites', wheelFavorites);
        },
        registerFavoriteOnAPI: async function (id) {
            apiPostRequest('/wheels/favorites', {wheelId: id});
        },
        getVehicleData: function (response) {
            try {
                let vehicleSubmodels = {};
                let vehicleDuallys = {};
                let vehicleBodys = {};

                response.Vehicles.forEach(vehicle => {
                    if (!vehicleSubmodels[vehicle.Submodel]) {
                        vehicleSubmodels[vehicle.Submodel] = [];
                        vehicleSubmodels[vehicle.Submodel].push(vehicle.Id);
                    } else {
                        vehicleSubmodels[vehicle.Submodel].push(vehicle.Id);
                    }
                    
                    if (!vehicleDuallys[vehicle.Drw]) {
                        vehicleDuallys[vehicle.Drw] = [];
                        vehicleDuallys[vehicle.Drw].push(vehicle.Id);
                    } else {
                        vehicleDuallys[vehicle.Drw].push(vehicle.Id);
                    }
                    
                    if (!vehicleBodys[vehicle.Body]) {
                        vehicleBodys[vehicle.Body] = [];
                        vehicleBodys[vehicle.Body].push(vehicle.Id);
                    } else {
                        vehicleBodys[vehicle.Body].push(vehicle.Id);
                    }
                });

                return {
                    vehicle: {...response.Vehicles[0], ColorSelected: this.vehicleCurrentColor},
                    imgBaseUrl: response.ImgUrlBase,
                    colorBaseUrl: response.SwatchUrlBase,
                    colorDefault: response.Vehicles[0].ImgColorId || null,
                    coordinates: {
                        '1': this.getVehicleCoordinates(response, 'Coordinates001'),
                        '14': this.getVehicleCoordinates(response, 'Coordinates014'),
                        '32': this.getVehicleCoordinates(response, 'Coordinates032')
                    },
                    anglesImgCodes: {
                        '1': response.Vehicles[0].WheelAngles001 || null,
                        '14': response.Vehicles[0].WheelAngles014 || null,
                        '32': response.Vehicles[0].WheelAngles032 || null
                    },
                    configurations: {
                        submodels: vehicleSubmodels,
                        duallys: vehicleDuallys,
                        bodys: vehicleBodys
                    }
                };
            } catch (error) {
                return {
                    vehicle: null,
                    imgBaseUrl: null,
                    colorBaseUrl: null,
                    colorDefault: null,
                    coordinates: null,
                    configurations: null
                };
            }
        },
        getVehicleCoordinates: function (response, id) {
            if (response && response.Vehicles) {
                if (response.Vehicles[0]) {
                    return response.Vehicles[0][id] || null;                    
                }
            }
            return null;
        },
        getVehicleImageUrl: function (imagePath) {
            if (imagePath) {
                return `${this.vehicleBaseUrl}${imagePath}`;
            }

            return '';
        },
        getColorsFiltered: function (vehicleColors) {
            if (vehicleColors) {
                let colors = [...vehicleColors];

                if (!colors) {
                    return null;
                }

                return colors.filter(function (color) {
                    if (color) {
                        if (color.Img001 && color.Img014 && color.Img032) {
                            return color;
                        }
                    }
                });
            }

            return null;
        },
        selectRandomWheel: function () {
            const randomIndex = Math.floor((Math.random() * (this.wheels.length - 1)) + 0);
            const wheelSelection = this.wheels[randomIndex];
            this.getWheelSelected(wheelSelection, null, null, true);
        },
        changeVehicleConfigurationSelected: async function (configuration) {
            const drw = this.getDrw;
            const submodel = this.vehicle.Submodel;
            const body = this.vehicle.Body;
            let vehicleSelected = null;

            this.vehicleConfigurationSelectionState(false);

            this.vehiclesRequested.forEach(vehicle => {
                let ranking = 0;

                if (vehicle.Drw === drw) ranking++;
                if (vehicle.Submodel === submodel) ranking++;
                if (vehicle.Body === body) ranking++;

                if (
                    configuration.option === 'submodel' &&
                    vehicle.Submodel === configuration.value &&
                    (!vehicleSelected || ranking > vehicleSelected.ranking)
                ) {
                    vehicleSelected = vehicle;
                    vehicleSelected['ranking'] = ranking;
                } else if (
                    configuration.option === 'body' &&
                    vehicle.Body === configuration.value &&
                    (!vehicleSelected || ranking > vehicleSelected.ranking)
                ) {
                    vehicleSelected = vehicle;
                    vehicleSelected['ranking'] = ranking;
                } else if (
                    configuration.option === 'dually' &&
                    vehicle.Drw.toString() === configuration.value.toString() &&
                    (!vehicleSelected || ranking > vehicleSelected.ranking)
                ) {
                    vehicleSelected = vehicle;
                    vehicleSelected['ranking'] = ranking;
                }
            });

            delete vehicleSelected.ranking;

            this.vehicle = vehicleSelected;
            this.vehicleCoordinates = {
                '1': this.getVehicleCoordinates({Vehicles: [vehicleSelected]}, 'Coordinates001'),
                '14': this.getVehicleCoordinates({Vehicles: [vehicleSelected]}, 'Coordinates014'),
                '32': this.getVehicleCoordinates({Vehicles: [vehicleSelected]}, 'Coordinates032')
            };
            this.vehicleAnglesImgCodes = {
                '1': vehicleSelected.WheelAngles001 || null,
                '14': vehicleSelected.WheelAngles014 || null,
                '32': vehicleSelected.WheelAngles032 || null
            };

            const vehicleHasCurrentColor = vehicleSelected.Colors.filter(color => color.Id == this.vehicleCurrentColor);
            if (
                vehicleHasCurrentColor.length > 0 &&
                vehicleSelected.ImgColorId.toString() != this.vehicleCurrentColor.toString()
            ) {
                const color = this.vehicleCurrentColor;
                this.vehicleCurrentColor = '';

                await this.changeCurrentColor(color);
            } else {
                this.vehicleCurrentColor = vehicleSelected.ImgColorId;
            }

            this.updateFilters = true;
            this.loadingVehicle = true;
            setTimeout(() => {
                this.loadingVehicle = false;
            }, 600);
        },
        previewerImageLoaded: function (val) {
            if (!this.previewerLoaded && val) {
                this.previewerLoaded = val;
            }
        },
        sideSelected: function (val) {
            this.vehicleSelectedSide = val;
        },
        viewSelected: function (val) {
            this.wheelSelectedView = val;
        },
        toggleZoom: function (type) {
            if (type === 'vehicle') {
                this.zoomVehicleImageOn(this.vehicleSelectedSide);
            }

            if (type === 'wheel') {
                this.zoomWheelImageOn(this.wheelSelectedView);
            }
        },
        colorPickerState: function (val) {
            this.openColorPicker = val;
        },
        toggleWheelSpecifications: function (val) {
            this.showWheelSpecifications = val;
            this.hideSpecifications = false;
            window.scrollTo(0, 0);
        },
        openRequestQuote: function (val) {
            this.wheelRequestQuoteSelected = val;
        },
        changeWheelConfiguration: function (val) {
            this.wheelSelected = val;
            this.wheelZoomSelected = val;
            this.wheelDiameterSelected = val.Diameter;
        },
        closeWheelDetails: function () {
            this.openWheelDetails = false;
            this.showWheelSpecifications = false;
        },
        closeRequestQuote: function () {
            this.wheelRequestQuoteSelected = null;
        },
        zoomVehicleState: function (val) {
            this.openZoomVehicle = val;
        },
        zoomWheelState: function (val) {
            this.openZoomWheel = val;
        },
        vehicleConfigurationSelectionState: function (val) {
            this.openVehicleConfigurationSelection = val;
        },
        changeVehicleConfigurations: function (val) {
            this.vehicleConfigurationSelectionState(true);
            this.showOptionVehicleConfiguration = val;
        },
        zoomVehicleImageOn: function (side) {
            this.zoomVehicleState(true);
            this.zoomVehicleSideSelected = side;
        },
        zoomWheelImageOn: function (view) {
            this.zoomWheelState(true);
            this.zoomWheelViewSelected = view;
        },
        filtersAreActive: function (val) {
            if (val) {
                window.scrollTo(0, 0);
            }

            if (this.showFiltersOptions != val) {
                this.showFiltersOptions = val;
                this.hideSpecifications = this.showFiltersOptions ? true:false;

                if (this.showFiltersOptions) {
                    if (this.$store.getters.device.isMobile && this.$store.getters.device.isLandscape) {
                        const element = document.querySelector('.filters-container');
                        element.scrollIntoView({ behavior: 'smooth', block: 'start'});
                    }
                } else {
                    window.scrollTo(0, 0);
                }
            }
        },
        changeCurrentColor: async function (color) {
            if (this.vehicleCurrentColor.toString() !== color.toString()) {
                const bodyTypeFilter = this.vehicle.Body ? `&f-body=${encodeURIComponent(this.vehicle.Body)}`:'';
                const submodelFilter = this.vehicle.Submodel ? `&f-submodel=${encodeURIComponent(this.vehicle.Submodel)}`:'';

                this.vehicleCurrentColor = color;
                this.loading = true;

                let vehicle = await apiRequest(`${this.createVehicleRequest}${submodelFilter}${bodyTypeFilter}`);
                vehicle.Vehicles = vehicle.Vehicles.filter(vehicle => vehicle.Drw === this.vehicle.Drw && vehicle.Bed == this.vehicle.Bed);
                
                vehicle = this.getVehicleData(vehicle);
                this.vehicle = vehicle.vehicle;
                this.vehicleBaseUrl = vehicle.imgBaseUrl;
                this.vehicleCoordinates = vehicle.coordinates;
                this.vehicleAnglesImgCodes = vehicle.anglesImgCodes;

                this.loading = false;
                this.openColorPicker = false;
            }

            this.openColorPicker = false;
        },
        wheelSelect: function (value) {
            if (this.showWheelSpecifications) {
                this.showWheelSpecifications = false;
                this.hideSpecifications = true;
            }
            
            this.getWheelSelected(value.style, value.color, value.diameter, true, null, value.favorite);

            if (this.$store.getters.device.isMobile && this.$store.getters.device.isLandscape) {
                window.scrollTo(0, 0);
            }
        },
        showDetails: function () {
            this.showWheelSpecifications = false;
            this.openWheelDetails = true;
        },
        wheelDetailsBack: function () {
            if (this.wheelVisualizer) {
                if (this.showWheelSpecifications) {
                    this.showWheelSpecifications  = false;
                    this.hideSpecifications = true;
                    return;
                } else {
                    const url = this.$store.getters.clientSettings.brands === 1 ? '/home':'/wheels';
                    return this.$router.push(url);
                }
            }
            return this.hideWheelDetails();
        },
        previousWheelsPage: async function () {
            this.wheelsPagination.loading = true;

            if (this.wheelsPagination.current > 1) {
                const previousPageNumber = this.wheelsPagination.current - 1;
                const request = await apiRequest(`${this.createWheelsRequest}&p-number=${previousPageNumber}`);
                const wheelsData = this.getWheelsData(request);

                this.wheels = wheelsData.wheels;
                this.wheelsBaseUrl = wheelsData.imgBaseUrl;
                this.wheelsPagination.moreItems = request.MoreItems;
                this.wheelsPagination.current = previousPageNumber;
            }
            
            this.wheelsPagination.loading = false;
        },
        nextWheelsPage: async function () {
            this.wheelsPagination.loading = true;

            if (this.wheelsPagination.moreItems) {
                const nextPageNumber = this.wheelsPagination.current + 1;
                const request = await apiRequest(`${this.createWheelsRequest}&p-number=${nextPageNumber}`);
                const wheelsData = this.getWheelsData(request);

                this.wheels = wheelsData.wheels;
                this.wheelsBaseUrl = wheelsData.imgBaseUrl;
                this.wheelsPagination.moreItems = request.MoreItems;
                this.wheelsPagination.current = nextPageNumber;
            }
            
            this.wheelsPagination.loading = false;
        },
        changePageSize: async function (size) {
            if (this.wheelsPagination.pageSize != size) {
                this.wheelsPagination.current = 1;
            }

            this.wheelsPagination.loading = true;
            this.wheelsPagination.pageSize = size;

            if (this.wheelsPagination.moreItems || this.wheelsPagination.current === 1) {
                const request = await apiRequest(`${this.createWheelsRequest}&p-number=${this.wheelsPagination.current}`);
                const wheelsData = this.getWheelsData(request);

                this.wheels = wheelsData.wheels;
                this.wheelsBaseUrl = wheelsData.imgBaseUrl;
                this.wheelsPagination.moreItems = request.MoreItems;
            }
            
            this.wheelsPagination.loading = false;
        },
        hideWheelDetails: function () {
            this.openWheelDetails = false;
            this.showWheelSpecifications = false;
        },
        getFilterValues: function (filter) {
            let values = '';
            if (typeof filter == 'object' && filter) {
                if (filter.length > 0) {
                    values = filter.join();
                }
            } else {
                values = filter;
            }
            return values === '' ? null:values;
        },
        getRequestFiltersFormat: function () {
            const filters = this.$store.getters.wheelsFilters;
            const brand = this.getFilterValues(filters.Brands);
            const diameters = this.getFilterValues(filters.Diameters);
            const shortColors = this.getFilterValues(filters.ShortColors);
            const shortFinishes = this.getFilterValues(filters.ShortFinishes);
            const applications = this.getFilterValues(filters.Applications);
            const segments = this.getFilterValues(filters.Segments);

            const filterBrand = brand ? `&f-brand=${encodeURIComponent(brand)}`:'';
            const filterDiameters = diameters ? `&f-diameters=${diameters}`:'';
            const filterShortColors = shortColors ? `&f-shortColors=${encodeURIComponent(shortColors)}`:'';
            const filterShortFinishes = shortFinishes ? `&f-shortFinishes=${shortFinishes}`:'';
            const tags = applications ? `${applications}${segments ? (','+segments):''}`:(segments||'');
            const filterTags = tags ? `&f-tags2=${encodeURIComponent(tags)}`:'';
            
            return `${filterBrand}${filterDiameters}${filterShortColors}${filterShortFinishes}${filterTags}`;
        },
        changeFilters: async function () {
            this.wheelsFilter = this.getRequestFiltersFormat();
            
            const request = await apiRequest(`${this.createWheelsRequest}`);
            const wheelsData = this.getWheelsData(request);

            this.wheels = wheelsData.wheels;
            this.wheelsBaseUrl = wheelsData.imgBaseUrl;
            this.wheelsPagination.moreItems = request.MoreItems;
            this.wheelsPagination.current = this.wheelsPagination.moreItems ? 1:null;

            if (this.updateFilters) {
                this.updateFilters = false;

                const wheelIsOnList = this.wheels.find(wheel => wheel.StyleId == this.wheelSelected.StyleId);
                if (!wheelIsOnList && this.wheels.length > 0) {
                    const randomIndex = Math.floor((Math.random() * (this.wheels.length - 1)) + 0);
                    this.getWheelSelected(this.wheels[randomIndex], null, null, false);
                }
            }
        },
        resetStoredFilters: function () {
            this.resetFilters = true;
            this.$store.commit('wheelsFilters', {action: 'clear'});
        },
        hideBodyOverflow: function (val) {
            if (val) {
                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            } else {
                document.getElementsByTagName('body')[0].style.overflow = 'auto';
            }
        }
    },
    watch: {
        openColorPicker: function (newVal) {
            this.hideBodyOverflow(newVal);
        },
        openZoomVehicle: function (newVal) {
            this.hideBodyOverflow(newVal);
        },
        openZoomWheel: function (newVal) {
            this.hideBodyOverflow(newVal);
        },
        wheelRequestQuoteSelected: function (newVal) {
            this.hideBodyOverflow(newVal !== null ? true:false);
        },
        openVehicleConfigurationSelection: function (newVal) {
            this.hideBodyOverflow(newVal);
        },
        vehicle: function (newVal) {
            if (newVal && newVal.Id) {
                let garage = this.$store.getters.vehiclesGarage;
                const vehicle = newVal;
                const vehicleId = String(vehicle.Id);
                const idOnGarage = garage.findIndex(el => el.id === vehicleId);

                if (idOnGarage < 0) {
                    const img = this.vehicleBaseUrl ? `${this.vehicleBaseUrl}${(vehicle?.Img032['350'] || vehicle?.Img001['350'] || vehicle?.Img014['350'])}` : '';
                    const garageVehicle = {
                        id: vehicleId,
                        body: vehicle.Body,
                        drw: vehicle.Drw,
                        img: img,
                        make: vehicle.Make,
                        model: vehicle.Model,
                        subModel: vehicle.Submodel,
                        type: vehicle.Type,
                        year: vehicle.Year,
                        lastSeen: new Date()
                    };

                    if (garage.length >= 24) {
                        garage.pop();
                    }

                    garage.push(garageVehicle);
                } else {
                    garage[idOnGarage].lastSeen = new Date();
                }
                
                this.$store.commit('vehiclesGarage', garage);
            }
        }
    },
    beforeMount: async function() {
        if (this.vehicleId || this.styleId) {
            this.resetStoredFilters();
        }

        if (this.$store.getters.vehicleBuilding.wheel.Brand) {
            this.wheelPreSelected = true;

            if (this.wheelVisualizer) {
                if (this.$route.query.wheel_brand == this.$store.getters.vehicleBuilding.wheel.Brand) {
                    this.$route.query.wheel_p_number = this.$store.getters.vehicleBuilding.wheelPage.number;
                }

                this.$store.commit('wheelsFilters', {
                    key: 'Brands',
                    val: null
                });
            }
        }
        
        if (!('baseUrl' in this.$store.getters.vehiclesMakes)) {
            let makes = await apiRequest('/vehicles/makes?f-types=Truck,Van,Car,SUV&i-logos&p-size=500');
            this.$store.commit('vehiclesMakes', {baseUrl: makes.LogoUrlBase || null, makes: makes.Makes || null});
        }

        this.loading = true;
        await this.loader();

        if (this.wheelPreSelected) {
            this.wheelPreSelected = false;

            if (this.wheelVisualizer) {
                if (this.$route.query.wheel_brand == this.$store.getters.vehicleBuilding.wheel.Brand) {
                    this.wheelsPagination.current = this.$store.getters.vehicleBuilding.wheelPage.number;
                }
            }
            
            if (this.wheels.length == 0) {
                this.resetStoredFilters();
                await this.loader();
            }
        }

        if (this.wheelZoom) {
            this.showDetails ();
        }

        this.loadFilters = true;
        this.loading = false;
    }
}
</script>

<style scoped lang="scss">
.options-button {
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 2;
}

.go-back-button {
    position: absolute;
    top: 1rem;
    left: 2rem;
    z-index: 9;
}

.tools {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.visualizer-container {
    z-index: 1;

    &:not(.allow-overflow) {
        max-height: 100vh;
    }
    
    &.allow-overflow {
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &.remove-max-heigth {
        max-height: none;
    }
}

.previewer {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-desktop.png'), url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-desktop.svg');
    
    .loading {
        filter: grayscale(100%) blur(1px);
    }
}

.previewer-disabled {
    filter: opacity(1);
}

.previewer-disabled-message {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;

    & .bg-disabled-message {
        background-color: rgba($color: white, $alpha: 1);
    }
}

.landscape-margin {
    margin-top: 2.5rem !important;
}

.product-card {
    img {
        max-height: 180px;
        max-width: 100%;
    }
}

@media only screen and (max-width: 576px) {
    .previewer {
        background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-mobile.png'), url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-mobile.svg');
    }
}

@media only screen and (max-width: 600px) {
    .product-card {
        img.client-logo {
            max-height: 10vh;
        }
    }
}

@media only screen and (max-width: 768px) {
    .go-back-button {
        position: absolute;
        top: 1rem;
        left: .5rem;
    }

    .options-button {
        right: .5rem;
    }
}
</style>
