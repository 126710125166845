<template>
    <div
        v-if="showForm"
        class="position-relative"
    >
        <Overlay
            center
            @close="close"
        >
            <div class="p-2 my-2 mx-2 mx-md-5">
                <div v-if="configurationOptionSelected === 'all'">
                    <div class="mb-4 text-center border-bottom">
                        <h6 class="fw-medium m-0 py-0 py-2">VEHICLE CONFIGURATION</h6>
                    </div>

                    <div
                        v-if="Object.keys(vehicleConfigurations.submodels).length > 1"
                        :class="[
                            'text-center',
                            'configuration-menu-option text-wrap',
                            'rounded py-2 mt-3 mx-auto fw-regular',
                            'fw-medium border',
                            'd-flex flex-column justify-content-around align-items-center'
                        ]"
                        @click="configurationOptionSelected = 'submodel'"
                    >
                        <img src="https://vvs.autosyncstudio.com/static/icons/car.svg" alt="Submodel" class="w-50" style="opacity: 70%">
                        <small>SUBMODEL</small>
                    </div>

                    <div
                        v-if="Object.keys(getBodysBySubmodel).length > 1"
                        :class="[
                            'text-center',
                            'configuration-menu-option text-wrap',
                            'rounded py-2 mt-3 mx-auto fw-regular',
                            'fw-medium border',
                            'd-flex flex-column justify-content-around align-items-center'
                        ]"
                        @click="configurationOptionSelected = 'body'"
                    >
                        <img :src="getBodyTypeData[vehicle.Body].image || ''" :alt="getBodyTypeData[vehicle.Body].altText || ''" class="w-75 mt-3">
                        <small>CAB</small>
                    </div>
                    
                    <div
                        v-if="Object.keys(getRearAxleBySubmodel).length > 1"
                        :class="[
                            'text-center',
                            'configuration-menu-option text-nowrap',
                            'rounded py-2 mt-3 mx-auto fw-regular',
                            'fw-medium border',
                            'd-flex flex-column justify-content-around align-items-center'
                        ]"
                        @click="configurationOptionSelected = 'dually'"
                    >
                        <img v-if="!vehicle.Drw" src="https://vvs.autosyncstudio.com/static/icons/rear-axle/2WD.png" class="w-50" alt="NotDually">
                        <small v-if="!vehicle.Drw">REAR AXLE</small>
                    
                        <img v-if="vehicle.Drw" src="https://vvs.autosyncstudio.com/static/icons/rear-axle/4WD.png" class="w-50" alt="Dually">
                        <small v-if="vehicle.Drw">REAR AXLE</small>
                    </div>
                </div>

                <!-- OPTION 1 -->
                <div v-if="configurationOptionSelected === 'submodel'">
                    <div class="mb-3 text-center border-bottom">
                        <h6 class="fw-medium m-0 py-0 py-2 mx-3">SUBMODELS</h6>
                    </div>

                    <div
                        v-for="(vehiclesId, submodel) in vehicleConfigurations.submodels"
                        :key="submodel"
                        :class="[
                            'text-center',
                            'submodel-options',
                            'px-3 py-2 fw-regular',
                            vehiclesId.includes(vehicle.Id) && 'text-brand-primary fw-medium selected'
                        ]"
                        @click.stop="changeVehicleConfiguration('submodel', submodel)"
                    >
                        <small>{{submodel}}</small>

                        <div v-if="hasDually(vehiclesId)" class="dually-tag fw-medium text-center w-100">
                            <small>SRW / DRW</small>
                        </div>
                    </div>
                </div>

                <div v-if="configurationOptionSelected === 'body'">
                    <div class="mb-3 text-center border-bottom">
                        <h6 class="fw-medium m-0 py-0 pb-2 mx-5">CAB</h6>
                    </div>

                    <div class="d-flex flex-column flex-md-row justify-content-center align-items-top">
                        <div
                            v-for="(vehiclesId, body) in getBodysBySubmodel"
                            :key="body"
                            :class="[
                                'd-flex flex-column justify-content-center align-items-center',
                                'text-center',
                                'body-options',
                                'rounded py-2 my-3 my-md-0 mx-3 fw-medium',
                                vehiclesId.includes(vehicle.Id) && 'text-brand-primary selected'
                            ]"
                            @click.stop="changeVehicleConfiguration('body', body)"
                        >
                            <img v-if="getBodyTypeData[body]" :src="getBodyTypeData[body].image || ''" :alt="getBodyTypeData[body].altText || ''" class="w-100">
                            <small v-if="getBodyTypeData[body]" class="text-uppercase">{{getBodyTypeData[body].description || ''}}</small>
                            <div v-if="hasDually(vehiclesId)" class="dually-tag fw-medium text-center w-100">
                                <small>SRW / DRW</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="configurationOptionSelected === 'dually'">
                    <div class="mb-3 text-center border-bottom">
                        <h6 class="fw-medium m-0 py-0 pb-2 mx-5">REAR AXLE</h6>
                    </div>

                    <div class="d-flex justify-content-center align-items-top">
                        <div
                            v-for="(vehiclesId, dually) in getRearAxleBySubmodel"
                            :key="dually"
                            :class="[
                                'text-center',
                                'dually-options text-nowrap',
                                'rounded py-2 mx-3 fw-regular',
                                vehiclesId.includes(vehicle.Id) && 'text-brand-primary fw-medium selected'
                            ]"
                            @click.stop="changeVehicleConfiguration('dually', dually)"
                        >
                            <div v-if="dually === 'false'">
                                <img src="https://vvs.autosyncstudio.com/static/icons/rear-axle/2WD.png" alt="NotDually">
                                <br>
                                <small>SRW</small>
                            </div>
                            
                            <div v-else-if="dually === 'true'">
                                <img src="https://vvs.autosyncstudio.com/static/icons/rear-axle/4WD.png" alt="Dually">
                                <br>
                                <small>DRW-Dually</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    </div>
</template>

<script>
import Overlay from '@/components/shared/Overlay.vue';
import vehiclesBodyType from '@/components/builder/mixins/vehiclesBodyType';
import vehiclesBodyTypeBySubmodel from '@/components/builder/mixins/vehiclesBodyTypeBySubmodel';
import vehiclesRearAxleBySubmodel from '@/components/builder/mixins/vehiclesRearAxleBySubmodel';
import hasDually from '@/components/builder/mixins/hasDually';

export default {
    name: 'VehicleConfiguration',
    emits: [
        'close',
        'changeVehicleConfiguration'
    ],
    components: {
        Overlay
    },
    mixins : [
        vehiclesBodyType,
        vehiclesBodyTypeBySubmodel,
        vehiclesRearAxleBySubmodel,
        hasDually
    ],
    data: function () {
        return {
            showForm: false,
            configurationOptionSelected: null
        }
    },
    props: {
        open: {
            type: Boolean,
            required: true
        },
        vehicle: {
            type: Object,
            required: true
        },
        vehicleConfigurations: {
            type: Object,
            required: true
        },
        configurationOption: {
            type: String,
            required: false
        }
    },
    methods: {
        close: function () {
            this.configurationOptionSelected = this.configurationOption;
            this.$emit('close', false);
        },
        changeVehicleConfiguration: function (option, value) {
            this.configurationOptionSelected = this.configurationOption;
            this.$emit('changeVehicleConfiguration', {
                option: option,
                value: value
            });
        }
    },
    watch: {
        open: function (newVal) {
            this.showForm = newVal;
        },
        configurationOption: function (newVal) {
            this.configurationOptionSelected = newVal;
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.dually-tag {
    font-size: 9px;
    line-height: .61803;
    letter-spacing: 1px;
}

.submodel-options {
    &:nth-child(2n){
        @extend .bg-brand-light-gray;
        margin-top: 1px;
    }

    &:hover {
        @extend .cursor-pointer;
        @extend .text-brand-white;
        background-color: var(--client-color) !important;
        box-shadow: 0 0 0 1px var(--client-color) !important;
    }

    &.selected {
        border-left: 2px solid var(--client-color) !important;
    }
}

.body-options {
    @extend .cursor-pointer;
    
    &:hover {
        box-shadow: 0px 0px 0px 1px map-get($theme-color, "muted") !important;
    }

    img {
        min-width: 140px;
        max-width: 140px;
    }

    &.selected {
        border: 1px solid var(--client-color) !important;
    }

    small {
        font-size: 61.803% !important;
    }
}

.dually-options {
    min-width: 90px;
    max-width: 90px;
    @extend .cursor-pointer;

    &:hover {
        box-shadow: 0px 0px 0px 1px map-get($theme-color, "muted") !important;
    }

    &.selected {
        border: 1px solid var(--client-color) !important;
    }
}

.configuration-menu-option {
    min-width: 90px;
    max-width: 90px;
    min-height: 106px;
    max-height: 106px;
    @extend .cursor-pointer;

    &:hover {
        box-shadow: 0px 0px 0px 1px var(--client-color) !important;
        @extend .text-brand-primary
    }
}
</style>
