<template>
    <div v-if="wheel" class="p-2 p-sm-3 border-top border-bottom shadow">
        <div class="container-fluid m-0 p-0 w-100">
            <div class="row justify-content-center align-items-strech h-100 m-0 p-0 py-4 px-md-4">
                <div class="col-12 col-lg-6 col-xxl-8 align-self-start d-flex flex-column flex-lg-row flex-lg-column justify-content-lg-between align-items-center align-items-lg-start m-0 p-0 pe-lg-4">
                    <div class="d-flex flex-column flex-lg-row justify-content-center align-items-center justify-content-md-between align-items-lg-end w-100">
                        <div class="order-2 order-lg-0 mt-3 mt-lg-0">
                            <span class="title-section-spacing fw-bold text-center text-lg-start">CONFIGURATION</span>
                        </div>

                        <div
                            class="d-block d-lg-none title-section-spacing rounded bg-brand-primary text-white px-4 py-2 mb-4 my-lg-0 cursor-pointer toggle-active user-select-none text-nowrap shadow-sm order-0 order-lg-2 action-button"
                            style="letter-spacing: 1px"
                            @click.stop="requestQuote"
                        >
                            <small class="fw-bold">REQUEST A QUOTE</small>
                        </div>

                        <div
                            v-if="showVehicleBanner"
                            class="d-xxl-none bg-brand-white text-center text-brand-primary border border-brand-outline rounded cursor-pointer toggle-active user-select-none text-nowrap px-4 py-2 order-1 order-lg-1 action-button"
                            style="letter-spacing: 1px"
                            @click.stop="viewOnVehicle()"
                        >
                            <small class="title-section-spacing fw-medium">SEE ON YOUR VEHICLE</small>
                        </div>
                    </div>

                    <div class="container-fluid m-0 p-0 px-4 px-md-0">
                        <div class="row m-0 p-0 negative-margin">
                            <div
                                :class="[
                                    wheelsOptions.Finish.length == 0 || wheelsOptions.ShortColor.length == 0 ?
                                    'col-xxl-3':
                                    'col-xxl-6',
                                    'm-0',
                                    'p-0'
                                ]"
                            >
                                <div class="container-fluid m-0 p-0">
                                    <div class="row justify-content-center align-items-center justify-content-sm-start align-items-sm-start m-0 p-0">
                                        <div
                                            v-if="wheelsOptions.Finish.length > 0"
                                            :class="[
                                                'col-12 col-sm-6 col-lg',
                                                'mx-0 mt-1 mt-md-3 p-0 py-2 px-sm-3',
                                                'rounded'
                                            ]"
                                        >
                                            <div class="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start bg-white border p-3">
                                                <div class="mb-1">
                                                    <span class="title-section-spacing fw-bold text-center text-lg-start">Finish: </span>
                                                    <span class="text-muted fw-medium">{{wheel.Finish || 'N/A'}}</span>
                                                </div>
                                                <div class="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-start align-items-sm-start border-top py-1">
                                                    <div
                                                        v-for="(finish, index) in wheelsOptions.Finish"
                                                        :key="index"
                                                        :class="[
                                                            'position-relative',
                                                            'border border-2 rounded',
                                                            'fw-medium text-muted',
                                                            'style-card',
                                                            'mt-2',
                                                            'mx-2 me-sm-3 me-lg-2',
                                                            finish == wheel.Finish ?
                                                            'selected':
                                                            (
                                                                !wheelConfigurations.Finish.includes(finish) ?
                                                                'configuration-deactivate':
                                                                'cursor-pointer toggle-active'
                                                            )
                                                        ]"
                                                        :style="`background-image: url('${getWheelImageUrl(wheel.Colors[wheel.ShortColor])}');`"
                                                        @click.stop="finish != wheel.Finish && wheelConfigurations.Finish.includes(finish) && changeWheelConfiguration('Finish', finish)"
                                                    >
                                                        <div class="style-overlay-card style-overlay-card-background"></div>

                                                        <div class="style-overlay-card d-flex justify-content-center align-items-center">
                                                            <small class="text-uppercase text-center" :style="finish.length > 7 && 'font-size: 70%; font-weight: bold'">{{finish}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="wheelsOptions.ShortColor.length > 0"
                                            :class="[
                                                'col-12 col-sm-6 col-lg',
                                                'mx-0 mt-1 mt-md-3 p-0 py-2',
                                                wheelsOptions.Finish.length > 0 ?
                                                'px-sm-3':
                                                'px-sm-2',
                                                'rounded'
                                            ]"
                                        >
                                            <div class="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start bg-white border p-3">
                                                <div class="mb-1">
                                                    <span class="title-section-spacing fw-bold text-center text-lg-start">Color: </span>
                                                    <span class="text-muted fw-medium">{{wheel.ShortColor || 'N/A'}}</span>
                                                </div>
                                                <div class="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-start align-items-sm-start border-top py-1">
                                                    <div
                                                        v-for="(color, index) in wheelsOptions.ShortColor"
                                                        :key="index"
                                                        :class="[
                                                            'position-relative',
                                                            'border border-2 rounded',
                                                            'fw-medium text-muted',
                                                            'style-card',
                                                            'mt-2',
                                                            'mx-2 me-sm-3 me-lg-2',
                                                            color == wheel.ShortColor ?
                                                            'selected':
                                                            (
                                                                !wheelConfigurations.ShortColor.includes(color) ?
                                                                'configuration-deactivate':
                                                                'cursor-pointer toggle-active'
                                                            )
                                                        ]"
                                                        :style="`background-image: url('${getWheelImageUrl(wheel.Colors[color])}');`"
                                                        @click.stop="color != wheel.ShortColor && wheelConfigurations.ShortColor.includes(color) && changeWheelConfiguration('ShortColor', color)"
                                                    >
                                                        <div class="style-overlay-card style-overlay-card-background"></div>

                                                        <div class="style-overlay-card d-flex justify-content-center align-items-center">
                                                            <small class="text-uppercase text-center" :style="color.length > 7 && 'font-size: 70%; font-weight: bold'">{{color}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                :class="[
                                    wheelsOptions.Finish.length == 0 || wheelsOptions.ShortColor.length == 0 ?
                                    'col-xxl-9':
                                    'col-xxl-6',
                                    'm-0 p-0'
                                ]"
                            >
                                <div class="container-fluid m-0 p-0">
                                    <div class="row justify-content-center align-items-center justify-content-sm-start align-items-sm-start m-0 p-0">
                                        <div
                                            v-if="wheel.Diameters.length > 0"
                                            :class="[
                                                wheelsOptions.Finish.length == 0 || wheelsOptions.ShortColor.length == 0 ?
                                                'col-xxl-3 px-sm-2':
                                                'col-xxl-6 px-sm-3',
                                                'col-12 col-sm-6',
                                                'mx-0 mt-1 mt-md-3',
                                                'p-0 py-2',
                                                'rounded'
                                            ]"
                                        >
                                            <div class="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start bg-white border p-3">
                                                <div class="mb-1">
                                                    <span class="title-section-spacing fw-bold text-center text-lg-start">Diameter: </span>
                                                    <span class="text-muted fw-medium">{{wheel.Diameter}}"</span>
                                                </div>
                                                <div class="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-start align-items-sm-start border-top py-1">
                                                    <div
                                                        v-for="(diameter, index) in wheel.Diameters"
                                                        :key="index"
                                                        :class="[
                                                            'col',
                                                            'my-2',
                                                            'mx-2',
                                                            'border border-2 rounded',
                                                            'p-2',
                                                            'fw-medium text-muted',
                                                            'configuration-card',
                                                            diameter == wheel.Diameter ? 'selected':'cursor-pointer'
                                                        ]"
                                                        @click.stop="diameter != wheel.Diameter && changeWheelConfiguration('Diameter', diameter)"
                                                    >
                                                        <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                                                            <small v-if="String(diameter).length > 3" style="line-height: 1">{{diameter}}"</small>
                                                            <span v-else style="line-height: 1">{{diameter}}"</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="wheelsOptions.Width.length > 0"
                                            :class="[
                                                wheelsOptions.Finish.length == 0 || wheelsOptions.ShortColor.length == 0 ?
                                                'col-xxl-3 px-sm-2':
                                                'col-xxl-6 px-sm-3',
                                                'col-12 col-sm-6',
                                                'mx-0 mt-1 mt-md-3',
                                                'p-0 py-2',
                                                'rounded'
                                            ]"
                                        >
                                            <div class="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start bg-white border p-3">
                                                <div class="mb-1">
                                                    <span class="title-section-spacing fw-bold text-center text-lg-start">Width: </span>
                                                    <span class="text-muted fw-medium">{{wheel.Width}}"</span>
                                                </div>
                                                <div class="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-start align-items-sm-start border-top py-1">
                                                    <div
                                                        v-for="(width, index) in wheelsOptions.Width"
                                                        :key="index"
                                                        :class="[
                                                            'col my-2',
                                                            'mx-2',
                                                            'border border-2 rounded',
                                                            'p-2',
                                                            'fw-medium text-muted',
                                                            'configuration-card',
                                                            width == wheel.Width ? 'selected':
                                                            (
                                                                !wheelConfigurations.Width.includes(width) ?
                                                                'configuration-deactivate':
                                                                'cursor-pointer toggle-active'
                                                            )
                                                        ]"
                                                        @click.stop="width != wheel.Width && wheelConfigurations.Width.includes(width) && changeWheelConfiguration('Width', width)"
                                                    >
                                                        <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                                                            <small v-if="String(width).length > 3" style="line-height: 1">{{width}}"</small>
                                                            <span v-else style="line-height: 1">{{width}}"</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div
                                            v-if="wheelsOptions.LugCount.length > 0"
                                            :class="[
                                                wheelsOptions.Finish.length == 0 || wheelsOptions.ShortColor.length == 0 ?
                                                'col-xxl-3 px-sm-2':
                                                'col-xxl-6 px-sm-3',
                                                'col-12 col-sm-6',
                                                'mx-0 mt-1 mt-md-3',
                                                'p-0 py-2',
                                                'rounded'
                                            ]"
                                        >
                                            <div class="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start bg-white border p-3">
                                                    <div class="mb-1">
                                                        <span class="title-section-spacing fw-bold text-center text-lg-start">Lugs: </span>
                                                        <span class="text-muted fw-medium">{{wheel.LugCount || 'N/A'}}</span>
                                                    </div>
                                                    <div class="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-start align-items-sm-start border-top py-1">
                                                    <div
                                                        v-for="(lugCount, index) in wheelsOptions.LugCount"
                                                        :key="index"
                                                        :class="[
                                                            'col my-2',
                                                            'mx-2',
                                                            'border border-2 rounded',
                                                            'p-2',
                                                            'fw-medium text-muted',
                                                            'configuration-card',
                                                            lugCount == wheel.LugCount ?
                                                            'selected':
                                                            (
                                                                !wheelConfigurations.LugCount.includes(lugCount) ?
                                                                'configuration-deactivate':
                                                                'cursor-pointer toggle-active'
                                                            )
                                                        ]"
                                                        @click.stop="lugCount != wheel.LugCount && wheelConfigurations.LugCount.includes(lugCount) && changeWheelConfiguration('LugCount', lugCount)"
                                                    >
                                                        <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                                                            <small style="line-height: 1">{{lugCount}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div
                                            v-if="wheelsOptions.BoltCircle1.length > 0"
                                            :class="[
                                                wheelsOptions.Finish.length == 0 || wheelsOptions.ShortColor.length == 0 ?
                                                'col-xxl-3 px-sm-2':
                                                'col-xxl-6 px-sm-3',
                                                'col-12 col-sm-6',
                                                'mx-0 mt-1 mt-md-3',
                                                'p-0 py-2',
                                                'rounded'
                                            ]"
                                        >
                                            <div class="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start bg-white border p-3">
                                                    <div class="mb-1">
                                                        <span class="title-section-spacing fw-bold text-center text-lg-start">Bolt Circle: </span>
                                                        <span class="text-muted fw-medium">{{wheel.BoltCircle1 || 'N/A'}}</span>
                                                    </div>
                                                    <div class="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-start align-items-sm-start border-top py-1">
                                                    <div
                                                        v-for="(boltCircle, index) in wheelsOptions.BoltCircle1"
                                                        :key="index"
                                                        :class="[
                                                            'col my-2',
                                                            'mx-2',
                                                            'border border-2 rounded',
                                                            'p-2',
                                                            'fw-medium text-muted',
                                                            'configuration-card',
                                                            boltCircle == wheel.BoltCircle1 ?
                                                            'selected':
                                                            (
                                                                !wheelConfigurations.BoltCircle1.includes(boltCircle) ?
                                                                'configuration-deactivate':
                                                                'cursor-pointer toggle-active'
                                                            )
                                                        ]"
                                                        @click.stop="boltCircle != wheel.BoltCircle1 && wheelConfigurations.BoltCircle1.includes(boltCircle) && changeWheelConfiguration('BoltCircle1', boltCircle)"
                                                    >
                                                        <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                                                            <small style="line-height: 1">{{boltCircle}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="wheelsOptions.Offset.length > 0"
                                            :class="[
                                                wheelsOptions.Finish.length == 0 || wheelsOptions.ShortColor.length == 0 ?
                                                'col-xxl-3 px-sm-2':
                                                'col-xxl-6 px-sm-3',
                                                'col-12 col-sm-6',
                                                'mx-0 mt-1 mt-md-3',
                                                'p-0 py-2',
                                                'rounded'
                                            ]"
                                        >
                                            <div class="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start bg-white border p-3">
                                                    <div class="mb-1">
                                                        <span class="title-section-spacing fw-bold text-center text-lg-start">Offset: </span>
                                                        <span class="text-muted fw-medium">{{wheel.Offset || 'N/A'}}</span>
                                                    </div>
                                                    <div class="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-start align-items-sm-start border-top py-1">
                                                    <div
                                                        v-for="(offset, index) in wheelsOptions.Offset"
                                                        :key="index"
                                                        :class="[
                                                            'col my-2',
                                                            'mx-2',
                                                            'border border-2 rounded',
                                                            'p-2',
                                                            'fw-medium text-muted',
                                                            'configuration-card',
                                                            offset == wheel.Offset ?
                                                            'selected':
                                                            (
                                                                !wheelConfigurations.Offset.includes(offset) ?
                                                                'configuration-deactivate':
                                                                'cursor-pointer toggle-active'
                                                            )
                                                        ]"
                                                        @click.stop="offset != wheel.Offset && wheelConfigurations.Offset.includes(offset) && changeWheelConfiguration('Offset', offset)"
                                                    >
                                                        <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                                                            <small v-if="String(offset).length > 3" style="line-height: 1">{{offset}}"</small>
                                                            <span v-else style="line-height: 1">{{offset}}"</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div
                                            v-if="wheelsOptions.Bore.length > 0"
                                            :class="[
                                                wheelsOptions.Finish.length == 0 || wheelsOptions.ShortColor.length == 0 ?
                                                'col-xxl-3 px-sm-2':
                                                'col-xxl-6 px-sm-3',
                                                'col-12 col-sm-6',
                                                'mx-0 mt-1 mt-md-3',
                                                'p-0 py-2',
                                                'rounded'
                                            ]"
                                        >
                                            <div class="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start bg-white border p-3">
                                                    <div class="mb-1">
                                                        <span class="title-section-spacing fw-bold text-center text-lg-start">Bore: </span>
                                                        <span class="text-muted fw-medium">{{wheel.Bore || 'N/A'}}</span>
                                                    </div>
                                                    <div class="d-flex flex-wrap justify-content-center align-items-center justify-content-sm-start align-items-sm-start border-top py-1">
                                                    <div
                                                        v-for="(bore, index) in wheelsOptions.Bore"
                                                        :key="index"
                                                        :class="[
                                                            'col my-2',
                                                            'mx-2',
                                                            'border border-2 rounded',
                                                            'p-2',
                                                            'fw-medium text-muted',
                                                            'configuration-card',
                                                            bore == wheel.Bore ?
                                                            'selected':
                                                            (
                                                                !wheelConfigurations.Bore.includes(bore) ?
                                                                'configuration-deactivate':
                                                                'cursor-pointer toggle-active'
                                                            )
                                                        ]"
                                                        @click.stop="bore != wheel.Bore && wheelConfigurations.Bore.includes(bore) && changeWheelConfiguration('Bore', bore)"
                                                    >
                                                        <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                                                            <small style="line-height: 1">{{bore}}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-6 col-xxl-4 d-flex flex-column justify-content-start align-items-start m-0 p-0 ps-lg-4 py-4 py-lg-0">
                    <div class="d-flex flex-wrap flex-column flex-md-row justify-content-center justify-content-md-between align-items-center p-0 pb-md-3 w-100">
                        <div class="order-1 order-md-0 mb-3 my-md-0">
                            <span class="title-section-spacing fw-bold text-center text-lg-start">SPECIFICATIONS</span>
                            <div v-if="wheelsOptions.Pn.length > 1" class="text-center">
                                <small
                                    v-if="showPartNumbers"
                                    class="text-brand-primary text-nowrap cursor-pointer toggle-active"
                                    @click.stop="showPartNumbers = false"
                                ><u>Hide part numbers</u></small>
                                <small
                                    v-if="!showPartNumbers"
                                    class="text-brand-primary text-nowrap cursor-pointer toggle-active"
                                    @click.stop="showPartNumbers = true"
                                ><u>Show all part numbers</u></small>
                            </div>
                        </div>

                        <div
                            class="d-none d-lg-block title-section-spacing rounded bg-brand-primary text-white px-4 py-2 my-4 my-md-0 cursor-pointer toggle-active user-select-none text-nowrap shadow-sm order-0 order-md-1"
                            style="letter-spacing: 1px"
                            @click.stop="requestQuote"
                        >
                            <small class="fw-bold">REQUEST A QUOTE</small>
                        </div>
                    </div>

                    <div v-if="showPartNumbers" class="container-fluid m-0 mb-4 p-0 text-muted specs-container">
                        <div v-if="wheelsOptions.Pn && wheelsOptions.Pn.length > 1" class="row align-items-strech h-100 m-0 p-0 bg-white">
                            <div
                                v-for="(pn, key) in wheelsOptions.Pn"
                                :key="key"
                                :class="[
                                    'd-none d-md-block col-4',
                                    'text-center text-nowrap',
                                    'p-0 m-0 p-2',
                                    key % 2 == 0 && 'bg-gray-lighten',
                                    key < 3 && 'border-top border-end border-bottom',
                                    key % 3 == 0 && 'border-start border-bottom border-end',
                                    key > 3 && 'border-bottom border-end'
                                ]"
                            >
                                <small
                                    v-if="wheel.Pn !== pn"
                                    class="text-brand-text cursor-pointer toggle-active text-wrap"
                                    style="word-break: break-all;"
                                    @click.stop="changeWheelConfiguration('Pn', pn)"
                                >{{pn}}</small>
                                <small
                                    v-else
                                    class="text-brand-primary fw-medium text-wrap"
                                    style="word-break: break-all;"
                                    @click.stop="changeWheelConfiguration('Pn', pn)"
                                >{{pn}}</small>
                            </div>

                            <div
                                v-for="(pn, key) in wheelsOptions.Pn"
                                :key="key"
                                :class="[
                                    'd-block d-md-none col-6',
                                    'text-center text-nowrap',
                                    'p-0 m-0 p-2'
                                ]"
                            >
                                <small
                                    v-if="wheel.Pn !== pn"
                                    class="text-brand-text cursor-pointer toggle-active"
                                    @click.stop="changeWheelConfiguration('Pn', pn)"
                                ><u>{{pn}}</u></small>
                                <small
                                    v-else
                                    class="text-brand-primary fw-medium"
                                    @click.stop="changeWheelConfiguration('Pn', pn)"
                                >{{pn}}</small>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Mobile specifications table  -->
                    <div class="d-lg-none container-fluid m-0 p-0 text-muted specs-container">
                        <div class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">P/N:</small>
                            </div>
                            <div class="col-7 p-0">
                                <div class="me-3">
                                    <span class="fw-medium text-brand-text">{{wheel.Pn}}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="wheel.Model" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">MODEL:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Model}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Submodel" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">SUBMODEL:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Submodel}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Offset" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">OFFSET:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Offset}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Diameter" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">DIAMETER:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Diameter}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Width" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">WIDTH:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Width}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.LugCount" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">LUGS:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.LugCount}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.BoltCircle1" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">BOLT CIRCLE:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.BoltCircle1}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.BoltCircle2" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">BOLT CIRCLE 2:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.BoltCircle2}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Bore" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">BORE:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Bore}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Color" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">COLOR:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Color}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.ShortFinish" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">FINISH:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.ShortFinish}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Structure" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">STRUCTURE:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Structure}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Bsm" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">BSM:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Bsm}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Weight" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">WEIGHT:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Weight}} lbs</span>
                            </div>
                        </div>
                        <div v-if="wheel.LoadRating" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">LOAD RATING:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.LoadRating}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Cap" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">CAP P/N:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Cap.Pn || 'N/A'}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.Accent" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">Accent:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.Accent}}</span>
                            </div>
                        </div>
                        <div v-if="wheel.ExposedLugs" class="row m-0 p-0 py-3 px-2 px-sm-5">
                            <div class="col-5 p-0">
                                <small class="fw-regular">Exposed Lugs:</small>
                            </div>
                            <div class="col-7 p-0">
                                <span class="fw-medium text-brand-text">{{wheel.ExposedLugs ? 'Yes':'No'}}</span>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Desktop specifications table  -->
                    <div class="d-none d-lg-block w-100">
                        <div class="border specs-container">
                            <div v-if="wheel.Pn" class="d-flex justify-content-center align-items-stretch item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">P/N:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Pn}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.Model" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">MODEL:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Model}} {{wheel.Submodel || ''}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.Diameter || wheel.Width" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div
                                    v-if="wheel.Diameter && wheel.Width"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-regular">SIZE:</small>
                                </div>
                                <div
                                    v-if="wheel.Diameter && wheel.Width"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Diameter}}" x {{wheel.Width}}"</small>
                                </div>

                                <div
                                    v-if="wheel.Diameter && !wheel.Width"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-regular">DIAMETER:</small>
                                </div>
                                <div
                                    v-if="wheel.Diameter && !wheel.Width"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Diameter}}"</small>
                                </div>

                                <div
                                    v-if="!wheel.Diameter && wheel.Width"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-regular">WIDTH:</small>
                                </div>
                                <div
                                    v-if="!wheel.Diameter && wheel.Width"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Width}}"</small>
                                </div>
                            </div>

                            <div v-if="wheel.Offset" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">OFFSET:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Offset}} mm</small>
                                </div>
                            </div>                            

                            <div v-if="wheel.LugCount || wheel.BoltCircle1" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div
                                    v-if="wheel.LugCount && wheel.BoltCircle1"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-regular">BOLT PATTERN:</small>
                                </div>
                                <div
                                    v-if="wheel.LugCount && wheel.BoltCircle1"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.LugCount}} x {{wheel.BoltCircle1}} {{wheel.BoltCircle2 ? `/ ${wheel.BoltCircle2}`:''}}</small>
                                </div>

                                <div
                                    v-if="wheel.LugCount && !wheel.BoltCircle1"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-regular">LUGS:</small>
                                </div>
                                <div
                                    v-if="wheel.LugCount && !wheel.BoltCircle1"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.LugCount}}</small>
                                </div>

                                <div
                                    v-if="!wheel.LugCount && wheel.BoltCircle1"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-regular">BOLT CIRCLE:</small>
                                </div>
                                <div
                                    v-if="!wheel.LugCount && wheel.BoltCircle1"
                                    class="w-50 d-flex justify-content-start align-items-center"
                                >
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.BoltCircle1}} {{wheel.BoltCircle2 ? `/ ${wheel.BoltCircle2}`:''}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.Bore" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">BORE:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Bore}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.LoadRating" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">LOAD RATING:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.LoadRating}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.Weight" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">WEIGHT:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Weight}} lbs</small>
                                </div>
                            </div>

                            <div v-if="wheel.ShortColor" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">COLOR:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.ShortColor}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.Finish" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">FINISH:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Finish}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.Structure" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">STRUCTURE:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Structure}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.Accent" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">ACCENT:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Accent}}</small>
                                </div>
                            </div>

                            <div v-if="wheel.Cap" class="d-flex justify-content-center align-items-stretch  item py-3 px-3 px-sm-4">
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-regular">CAP P/N:</small>
                                </div>
                                <div class="w-50 d-flex justify-content-start align-items-center">
                                    <small class="fw-medium text-brand-text" style="font-size:84%">{{wheel.Cap.Pn ||'N/A'}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WheelSpecifications',
    emits: [
        'changeWheelConfiguration',
        'changeWheelPartNumber',
        'requestQuote'
    ],
    props: {
        wheel: {
            type: Object,
            required: true
        },
        wheelConfigurations: {
            type: Object,
            required: true
        },
        wheelsOptions: {
            type: Object,
            required: true
        },
        wheelImgUrlBase: {
            type: String,
            required: true
        },
        wheelBrandLogo: String,
        wheelsPagination: Object,
        showVehicleBanner: Boolean
    },
    data: function () {
        return {
            showPartNumbers: false,
            filtering: false
        };
    },
    methods: {
        getWheelImageUrl: function (path) {
            if (this.wheelImgUrlBase) {
                if (path) {
                    return `${this.wheelImgUrlBase}${path}`;
                }
                return '';
            }
            return '';
        },
        changeWheelConfiguration: function (option, value) {
            if (option === 'Pn') {
                this.$emit('changeWheelPartNumber', value);
            } else {
                this.$emit('changeWheelConfiguration', {option: option, value: value});
            }
        },
        requestQuote: function () {
            this.$emit('requestQuote');
        },
        viewOnVehicle: function () {
            this.$store.commit('vehicleBuilding', {
                wheel: this.wheel,
                wheelSelectedWheelVisualizer: true,
                wheelPage: {
                    number: this.wheelsPagination.current,
                    moreItems: this.wheelsPagination.moreItems
                }
            });
            this.$router.push('/vehicles')
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.negative-margin {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
}

.bg-gray-lighten {
    background-color: rgba($color: #ebebeb, $alpha: .4);
}

.border-brand-outline {
    border-color: var(--client-color) !important;
}

.vehicle-banner {
    position: relative;

    img {
        opacity: .45;
    }

    .vehicle-banner-button {
        position: absolute;
        top: 50%;
    }
}

.wheel-configuration-disabled {
    opacity: .6;
}

.specs-container {
    :nth-child(2n+1).item {
        @extend .bg-gray-lighten;
    }

    :nth-child(2n+1).row {
        @extend .bg-gray-lighten;
    }
}

.spec-selectable {
    @extend .text-brand-muted;
    @extend .cursor-pointer;

    &:hover, &:active {
        @extend .text-brand-gray;
    }
}

.title-section-spacing, .wheel-brand-name, .wheel-model {
    letter-spacing: 1px;
}

.wheel-model {
    font-size: 1.4em;
}

.wheel-brand-name {
    font-size: .9em;
}

.configuration-card {
    width: 40px;
    max-width: 40px;
    height: 40px;
    font-size: .9em;
    text-align: center;
}

.style-card {
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 80px * 1.86;
    width: 80px;
    height: 80px;
    filter: contrast(1.1);

    .style-overlay-card {
        position: absolute;
        width: 100%;
        height: 100%;
        color: white;
        letter-spacing: 1px;

        &.style-overlay-card-background {
            background-color: rgba($color: #000000, $alpha: 0.4);
        }
    }
}

.configuration-card, .style-card {
    &.selected {
        color: map-get($theme-color, "text") !important;
        border-color: var(--client-color) !important;
    }

    &.configuration-deactivate {
        opacity: 0.4;
        background-color: #EAEAEA;
    }
}

@media only screen and (max-width: 991px) {
    .action-button {
        width: calc(100% - 3rem);
        text-align: center;
    }
}

@media only screen and (max-width: 576px) {
    .action-button {
        width: calc(100% - 1rem);
        text-align: center;
    }
}
</style>
