<template>
    <div
        v-if="showForm"
        class="position-relative"
    >
        <Overlay
            center
            @close="close"
        >
            <div v-show="showSuccessRequestMessage" class="request-quote-container py-3 px-sm-5">
                <p class="fw-bold text-center" style="font-size: 3em">
                    <Icon icon-name="checkCircledOutline"/>
                </p>
                <h6 class="fw-bold text-center">
                    <span class="d-block border-bottom pb-2">
                        Submission Received
                    </span>
                    <small class="d-block pt-2">
                        A Fitment Specialist will review and respond within the next
                        <br>
                        <b>1-3 business days</b>.
                    </small>
                </h6>
            </div>

            <div v-show="!showSuccessRequestMessage && !showLocations" class="request-quote-container py-3 px-sm-5">
                <div class="pb-3 border-bottom fw-bolder text-center">
                    REQUEST A QUOTE
                </div>
                <div class="container-fuild m-0 p-0">
                    <div class="row justify-content-center justify-content-md-start p-0 m-0">
                        <div class="col-12 p-0 m-0 mx-auto mt-3 text-center bg-brand-light-gray shadow-sm">
                            <div v-if="vehicle" class="d-flex justify-content-between align-items-center p-3">
                                <div><small class="fw-medium">Vehicle:</small></div>
                                <div><small class="ps-3">{{vehicleInfo}} - {{vehicle.LugCount}}x{{vehicle.BoltCircle}}</small></div>
                            </div>
                        </div>
                        <div class="col-12 p-0 m-0 mx-auto mt-2 mb-3 text-center bg-brand-light-gray shadow-sm">
                            <div class="py-3 px-4">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div><small class="fw-medium">Wheel:</small></div>
                                    <div><small class="ps-3">{{wheelInfo}}</small></div>
                                </div>

                                <div class="d-flex justify-content-between align-items-center">
                                    <div><small>{{wheel.Diameter && wheel.Width ? `${wheel.Diameter}"x${wheel.Width}"`:`${wheel.Diameter ? wheel.Diameter:wheel.Width}"`}} - {{wheel.Finish}} {{wheel.ShortColor}}</small></div>
                                    <div class="fw-regular"><small class="fw-regular">P/N: {{wheel.Pn}}</small></div>
                                </div>                                
                            </div>
                        </div>
                        <div class="col-md-6 p-0">
                            <label for="first-name" class="w-100 mt-2 pt-2">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    :class="[
                                        'w-100',
                                        'border rounded',
                                        'p-2 text-muted',
                                        !validateFirstName && 'border-danger'
                                    ]"
                                    placeholder=" "
                                    required
                                    v-model="firstName"
                                >
                                <span 
                                    :class="[
                                        'fw-regular',
                                        !validateFirstName && 'text-danger'
                                    ]"
                                >
                                    <small class="text-danger">* </small>
                                    <small>First Name</small>
                                </span>
                            </label>
                            <div v-show="(errorState && !validateFirstName) || (errorState && !firstName)">
                                <small class="text-danger fw-regular">
                                    First name is required.<br>
                                    Max length 30 characters
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6 p-0">
                            <label for="last-name" class="w-100 mt-4 mt-md-2 pt-2">
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    :class="[
                                        'w-100',
                                        'border rounded',
                                        'p-2 text-muted',
                                        !validateLastName && 'border-danger'
                                    ]"
                                    placeholder=" "
                                    required
                                    v-model="lastName"
                                >
                                <span 
                                    :class="[
                                        'fw-regular',
                                        !validateLastName && 'text-danger'
                                    ]"
                                >
                                    <small class="text-danger">* </small>
                                    <small>Last Name</small>
                                </span>
                            </label>
                            <div v-show="(errorState && !validateLastName) || (errorState && !lastName)" class="ps-md-3">
                                <small class="text-danger fw-regular">
                                    Last name is required.<br>
                                    Max length 30 characters
                                </small>
                            </div>
                        </div>
                        <div class="col-12 p-0">
                            <label for="email" class="w-100 mt-4 pt-2">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    :class="[
                                        'w-100',
                                        'border rounded',
                                        'p-2 text-muted',
                                        !validateEmail && 'border-danger'
                                    ]"
                                    placeholder=" "
                                    required
                                    v-model="email"
                                >
                                <span 
                                    :class="[
                                        'fw-regular',
                                        !validateEmail && 'text-danger'
                                    ]"
                                >
                                    <small class="text-danger">* </small>
                                    <small>Email</small>
                                </span>
                            </label>
                            <div v-show="(errorState && !validateEmail) || (errorState && !email)">
                                <small class="text-danger fw-regular">
                                    Email is required. Max length 50 characters
                                </small>
                            </div>
                        </div>
                        <div class="col-12 p-0">
                            <label for="phone" class="w-100 mt-4 pt-2">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    :class="[
                                        'w-100',
                                        'border rounded',
                                        'p-2 text-muted',
                                        !validatePhone && 'border-danger'
                                    ]"
                                    placeholder=" "
                                    required
                                    v-model="phone"
                                >
                                <span 
                                    :class="[
                                        'fw-regular',
                                        !validatePhone && 'text-danger'
                                    ]"
                                >
                                    <small class="text-danger">* </small>
                                    <small>Phone Number</small>
                                </span>
                            </label>
                            <div v-show="(errorState && !validatePhone) || (errorState && !phone)">
                                <small class="text-danger fw-regular">Phone is required</small>
                            </div>
                        </div>
                        <div class="col-md-6 p-0 me-auto">
                            <label for="quantity" class="w-100 mt-4 pt-2">
                                <input
                                    type="number"
                                    name="quantity"
                                    id="quantity"
                                    :class="[
                                        'w-100',
                                        'border rounded',
                                        'p-2 text-muted',
                                        !validateQuantity && 'border-danger'
                                    ]"
                                    placeholder=" "
                                    min="1"
                                    required
                                    v-model="quantity"
                                    @change="filterLocations"
                                >
                                <span 
                                    :class="[
                                        'fw-regular',
                                        !validateQuantity && 'text-danger'
                                    ]"
                                >
                                    <small class="text-danger">* </small>
                                    <small>Quantity</small>
                                </span>
                            </label>
                            <div v-show="(errorState && !validateQuantity) || (errorState && !quantity)">
                                <small class="text-danger fw-regular">Quantity is required</small>
                            </div>
                        </div>
                        <div class="col-12 p-0">
                            <label for="message" class="w-100 mt-4 pt-2">
                                <textarea
                                    name="message"
                                    id="message"
                                    :class="[
                                        'w-100',
                                        'border rounded',
                                        'p-2 text-muted',
                                        !validateMessage && 'border-danger'
                                    ]"
                                    placeholder=" "
                                    rows="4"
                                    v-model="message"
                                ></textarea>
                                <span 
                                    :class="[
                                        'fw-regular',
                                        !validateMessage && 'text-danger'
                                    ]"
                                >
                                    <small>Message</small>
                                </span>
                            </label>
                            <div v-show="(errorState && !validateMessage) || (errorState && !message)">
                                <small class="text-danger fw-regular">Message can just contain commas as special character and should be less than 280 characters</small>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="locationSelected" class="row justify-content-center justify-content-md-start p-0 m-0 mt-3">
                        <div class="col-12 p-0 m-0 mx-auto bg-brand-body-secondary shadow-sm">
                            <div
                                class="px-3 py-3 rounded"
                            >
                                <div class="d-flex justify-content-between align-items-start mb-2">
                                    <div><h6 class="m-0">{{locationSelected.Name}}</h6></div>
                                    <div><a href="tel:{{locationSelected.Phone}}"><small class="text-brand-primary fw-regular">{{locationSelected.Phone}}</small></a></div>
                                </div>

                                <span><small>{{locationSelected.Country}}, {{locationSelected.State}}, {{locationSelected.City}}, {{locationSelected.Zip}}</small></span>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div><small>{{locationSelected.Address}}</small></div>
                                    <div><small><u class="text-brand-primary fw-regular cursor-pointer toggle-active" @click.stop="showLocations = true;">Change location</u></small></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center justify-content-md-start p-0 m-0">
                        <div class="col-md-6 p-0">
                            <label for="send" class="w-100 mt-3 pt-2">
                                <div
                                    name="send"
                                    id="send"
                                    :class="[
                                        'w-100 border rounded p-2',
                                        'text-center fw-medium',
                                        'user-select-none cursor-pointer',
                                        (!validateSend || processingState || successState) && 'bg-brand-light-gray',
                                        validateSend ? 'bg-brand-primary text-white toggle-active':'bg-brand-muted',
                                    ]"
                                    :disable="!validateSend"
                                    @click.stop="sendQuoteRequest"
                                >
                                    <div v-if="processingState" class="d-flex justify-content-center align-items-center py-1">
                                        <DotsFlashingLoader/>
                                    </div>
                                    <small v-if="!processingState && !successState">SEND</small>
                                    <small v-if="successState">SENT</small>
                                </div>
                            </label>
                        </div>
                        <div v-if="showErrorRequestMessage" class="col-6 p-0 text-center pt-3">
                            <small class="fw-regular text-danger">Something happens with your request, plese try again...</small>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="!mapClientLocation && showLocations && !showSuccessRequestMessage" class="request-quote-container client-location py-3 px-sm-5">
                <div class="pb-3 border-bottom fw-bolder text-center">
                    YOUR LOCATION
                </div>
                <div class="container-fuild m-0 p-0">
                    
                    <div class="row justify-content-center justify-content-md-between p-0 m-0">
                        <div class="col-12 p-0 m-0">
                            <div class="my-4">
                                <input type="text" class="w-100 mt-2 border" autocomplete="off" v-model="clientLocation" placeholder="Address / ZIP Code / Postal Code" @keyup.enter="setClientLocation">
                            </div>
                            <div
                                class="w-100 border rounded p-2 text-center fw-medium user-select-none cursor-pointer bg-brand-primary text-white toggle-active"
                                @click.stop="setClientLocation"
                            >
                                <small>NEXT</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="mapClientLocation && showLocations && !showSuccessRequestMessage" class="request-quote-container locations-container py-3 px-sm-5">
                <div class="pb-3 border-bottom fw-bolder text-center">
                    SELECT LOCATION
                </div>
                <div class="container-fuild m-0 p-0">
                    <div class="row justify-content-center justify-content-md-between p-0 m-0">
                        <div class="col-12 p-0 m-0 d-flex justify-content-end align-items-center">
                            <div class="text-brand-primary cursor-pointer toggle-active user-select-none" @click.stop="mapClientLocation = null; clientLocation = null;"><u><small>Change my location</small></u></div>
                        </div>
                        <div
                            class="col-lg-4 col-md-6 p-0 m-0 pe-md-3"
                        >
                            <div class="p-3 my-3 bg-brand-light-gray shadow-sm rounded">
                                <div class="mb-2">
                                    <input type="text" class="w-100 mt-2 border" v-model="locationQuery" placeholder="Filter locations" @input="findLocations">
                                </div>

                                <div class="locations-cards-container py-2">
                                    <div
                                        v-for="(location, key) in locations"
                                        :key="key"
                                        :class="[
                                            'px-3 py-3 my-1 rounded cursor-pointer',
                                            centerLocationOn === location.Name ? 'location-card-selected' : 'location-card',
                                            key + 1 < locations.length && 'border-bottom'
                                        ]"
                                        @click.stop="centerLocationOn = location.Name"
                                    >
                                        <div><h6>{{location.Name}}</h6></div>
                                        <div><small>{{location.Address}}</small></div>
                                        <div><small>{{location.City}}, {{location.State}}, {{location.Zip}}, {{location.Country}}</small></div>
                                        <div class="d-flex justify-content-between align-items-end">
                                            <div><a :href="`tel:${location.Phone}`"><small class="text-end text-brand-primary fw-regular"><u>{{location.Phone}}</u></small></a></div>
                                            <div><small><u class="text-brand-primary fw-regular cursor-pointer toggle-active" @click.stop="changeLocationSelected(location)">Select location</u></small></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-6 p-0 m-0 ps-md-3">
                            <div class="p-3 my-3 bg-brand-light-gray shadow-sm rounded">
                                <Locations
                                    :clientLocation="mapClientLocation"
                                    :center-on="centerLocationOn"
                                    @updateLocations="updateLocations"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    </div>
</template>

<script>
import Locations from "./Locations.vue";
import DotsFlashingLoader from "@/components/shared/DotsFlashingLoader.vue";
import Overlay from '@/components/shared/Overlay.vue';
import apiPostRequest from "@/utils/apiPostRequest";
import Icon from '@/components/shared/Icon.vue';

export default {
    name: "RequestQuote",
    components: {
        Locations,
        Overlay,
        DotsFlashingLoader,
        Icon
    },
    emits: ["close"],
    data: function () {
        return {
            showForm: false,
            mapClientLocation: null,
            clientLocation: null,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            quantity: null,
            message: null,
            showErrorFirstName: false,
            showErrorLastName: false,
            showErrorEmail: false,
            showErrorPhone: false,
            showErrorQuantity: false,
            showErrorMessage: false,
            errorState: false,
            processingState: false,
            successState: false,
            showSuccessRequestMessage: false,
            showErrorRequestMessage: false,
            locations: [],
            showLocations: true,
            locationQuery: null,
            locationSelected: null,
            centerLocationOn: null
        }
    },
    props: {
        open: Boolean,
        vehicle: {
            type: Object,
            required: false
        },
        wheel: {
            type: Object,
            required: true
        }
    },
    computed: {
        validateFirstName: function () {
            if (!this.showErrorFirstName) {
                return true;
            }

            if (this.firstName) {
                const length = this.firstName.length;
                if (length > 0 && length <= 30) {
                    return true;
                }
            }
            return false;
        },
        validateLastName: function () {
            if (!this.showErrorLastName) {
                return true;
            }

            if (this.lastName) {
                const length = this.lastName.length;
                if (length > 0 && length <= 30) {
                    return true;
                }
            }
            return false;
        },
        validateEmail: function () {
            if (!this.showErrorEmail) {
                return true;
            }

            if (this.email) {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const length = this.email.length;
                if (length > 0 && length <= 50 && regex.test(this.email)) {
                    return true;
                }
            }
            return false;
        },
        validatePhone: function () {
            if (!this.showErrorPhone) {
                return true;
            }

            if (this.phone) {
                const regex = /[\d]/;
                const length = this.phone.length;
                if (length >= 10 && length <= 20 && regex.test(this.phone)) {
                    return true;
                }
            }
            return false;
        },
        validateQuantity: function () {
            if ( (this.quantity && this.quantity > 0) || !this.showErrorQuantity ) {
                return true;
            }
            return false;
        },
        validateMessage: function () {
           if (!this.showErrorMessage) {
                return true;
            }

            if (this.message) {
                const regex = /^[^`~#$^&*+={}[\]|\\:;<>฿]*$/;
                const length = this.message.length;
                if (length >= 0 && length <= 500 && regex.test(this.message)) {
                    return true;
                }
            }
            return false;
        },
        validateSend: function () {
            if (
                this.validateFirstName &&
                this.validateLastName &&
                this.validateEmail &&
                this.validatePhone &&
                this.validateQuantity &&
                this.validateMessage
            ) {
                return true;
            }
            return false;
        },
        vehicleInfo: function () {
            if (this.vehicle) {
                return `${this.vehicle.Year} ${this.vehicle.Make} ${this.vehicle.Model} ${this.vehicle.Submodel}`;
            }

            return null;
        },
        wheelInfo: function () {
            if (this.wheel) {
                const submodel = this.wheel.Submodel ? this.wheel.Submodel:'';
                return `${this.wheel.Brand} ${this.wheel.Model} ${submodel} ${this.wheel.Diameter}"`;
            }

            return null;
        }
    },
    methods: {
        close: function () {
            this.showForm = false;
            this.showSuccessRequestMessage = false;
            this.$emit('close');
        },
        sendQuoteRequest: async function () {
            if (
                !this.firstName ||
                !this.lastName ||
                !this.email ||
                !this.phone ||
                !this.quantity
            ) {
                this.errorState = true;
            }

            if (!this.errorState && this.validateSend) {
                this.showErrorRequestMessage = false;
                this.processingState = true;
                this.errorState = false;
                this.successState = false;

                const vehicle = this.vehicle ? this.vehicle.Id:null;
                const data = {
                    locationId: this.locationSelected?.Id,
                    customer: {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        phone: this.phone,
                        email: this.email,
                        message: this.message
                    },
                    vehicleId: vehicle,
                    products: [
                        {type: "wheel", id: this.wheel.Id, qty: parseInt(this.quantity)},
                    ]
                };

                const response = await apiPostRequest('/request-for-quote', data);
                if (response && response.status == "OK") {
                    this.successState = true;
                    this.showSuccessRequestMessage = true;

                    setTimeout( () => {
                        this.resetValues();
                    }, 2000);
                } else {
                    this.showErrorRequestMessage = true;
                    this.successState = false;
                    this.errorState = true;
                }

                this.errorState = false;
                this.processingState = false;
            } else {
                this.errorState = true;
            }
        },
        resetValues: function () {
            this.successState = false;
            this.errorState = false;
            this.showErrorFirstName = false;
            this.showErrorLastName = false;
            this.showErrorEmail = false;
            this.showErrorPhone = false;
            this.showErrorQuantity = false;
            this.showErrorMessage = false;
            this.errorState = false;
            this.showErrorRequestMessage = false;

            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.phone = null;
            this.quantity = 4;
            this.message = null;

            this.mapClientLocation = null;
            this.clientLocation = null;
            
            if (!this.locations || !this.locations.length) {
                this.showLocations = false;
            } else {
                this.showLocations = true;
            }

            this.locationQuery = null;
            this.locationSelected = null;
            this.centerLocationOn = null;
            this.locations = this.$store.getters.clientSettings.locations;
        },
        updateLocations: function (val) {
            if (!val || !val.length) {
                this.showLocations = false;
            }

            this.locations = val;
        },
        changeLocationSelected: function (location) {
            this.locationSelected = location;
            this.locationQuery = null;
            this.locations = this.$store.getters.clientSettings.locations;
            this.showLocations = false;
        },
        setClientLocation: function () {
            this.mapClientLocation = this.clientLocation;
        },
        findLocations: function () {
            if (this.locationQuery.length >= 2) {
                this.locations = [];
                this.locationQuery = this.locationQuery.toLowerCase();
                
                this.$store.getters.clientSettings.locations.forEach(location => {
                    if (
                        location.Name.toLowerCase().includes(this.locationQuery) ||
                        location.Country.toLowerCase().includes(this.locationQuery) ||
                        location.State.toLowerCase().includes(this.locationQuery) ||
                        location.City.toLowerCase().includes(this.locationQuery) ||
                        location.Address.toLowerCase().includes(this.locationQuery) ||
                        location.Zip.toLowerCase().includes(this.locationQuery) ||
                        location.Phone.toLowerCase().includes(this.locationQuery)
                    ) {
                        this.locations.push(location);
                    }
                });
            } else {
                this.locations = this.$store.getters.clientSettings.locations;
            }
        }
    },
    watch: {
        open: function (newVal) {
            this.resetValues();
            this.showForm = newVal;
        },
        firstName: function (newVal, oldVal) {
            if (!oldVal && newVal) {
                this.showErrorFirstName = true;
            }
        },
        lastName: function (newVal, oldVal) {
            if (!oldVal && newVal) {
                this.showErrorLastName = true;
            }
        },
        email: function (newVal, oldVal) {
            if (!oldVal && newVal) {
                this.showErrorEmail = true;
            }
        },
        phone: function (newVal, oldVal) {
            if (!oldVal && newVal) {
                this.showErrorPhone = true;
            }
        },
        quantity: function (newVal, oldVal) {
            if (!oldVal && newVal) {
                this.showErrorQuantity = true;
            }
        },
        message: function (newVal, oldVal) {
            if (!oldVal && newVal) {
                this.showErrorMessage = true;
            }
        }
    },
    beforeMount: function () {
        this.locations = this.$store.getters.clientSettings.locations;
    }
}
</script>

<style scoped lang="scss">
.request-quote-container {
    max-width: 550px;

    &.locations-container, &.client-location {
        input {
            border: none;
            border-radius: 44px;
            height: 44px;
            font-size: 90%;
            color: #2F2F2F;
            width: 100%;
            padding-left: 1.5em;
            padding-right: 1.5em;

            &::placeholder {
                font-style: italic;
                color: #a2a2a2;
            }
        }
    }
    &.locations-container {
        max-width: 80vw;
        width: 80vw;

        .locations-cards-container {
            max-height: 61.803vh;
            overflow-y: auto;

            .location-card:hover {
                background-color: rgba($color: #000000, $alpha: .1);
            }

            .location-card-selected {
                background-color: var(--client-color);
                &, & .text-brand-primary {
                    color: white;
                }
            }
        }
    }
}

label {
    position: relative;

    span {
        position: absolute;
        top: 50%;
        left: 1rem;
        transition: top ease .3s, left ease .3s;
        -o-transition: top ease .3s, left ease .3s;
        -moz-transition: top ease .3s, left ease .3s;
        -webkit-transition: top ease .3s, left ease .3s;

        &, small {
            line-height: .5;
        }
    }

    textarea ~ span {
        top: 1.5rem;
        outline: none;
    }
    
    input {
        &:focus, &:valid, &:not(:placeholder-shown) {
            & ~ span {
                top: -0.5rem;
                left: .25rem;
            }
        }
    }
    
    textarea {
        &:focus, &:not(:placeholder-shown) {
            & ~ span {
                top: -0.5rem;
                left: .25rem;
            }
        }
    }
}

.col-md-6:nth-child(2n + 1) {
    label {
        padding-right: 1rem;
    }
}

.col-md-6:nth-child(2n) {
    label {
        padding-left: 1rem;

        span {
            left: 2rem;
        }
    }
    
    input {
        &:focus, &:valid, &:not(:placeholder-shown) {
            & ~ span {
                left: 1rem;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .col-md-6:nth-child(2n + 1) {
        label {
            padding-right: 0;
        }
    }

    .col-md-6:nth-child(2n) {
        label {
            padding-left: 0;

            span {
                left: 1rem;
            }
        }
        
        input {
            &:focus, &:valid, &:not(:placeholder-shown) {
                & ~ span {
                    left: .25rem;
                }
            }
        }
    }
}
</style>
