var vehiclesBodyType = {
    computed: {
        getBodyTypeData: function () {            
            return {
                'Sedan': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/sedan.png',
                    altText: 'Sedan',
                    description: 'Sedan'
                },
                'Hatchback': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/hatchback.png',
                    altText: 'Hatchback',
                    description: 'Hatchback'
                },
                'Coupe': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/coupe.png',
                    altText: 'Coupe',
                    description: 'Coupe'
                },
                'Sport Utility': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/suv.png',
                    altText: 'SUV',
                    description: 'SUV'
                },
                'Convertible': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/convertible.png',
                    altText: 'Convertible',
                    description: 'Convertible'
                },
                'Wagon': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/wagon.png',
                    altText: 'Wagon',
                    description: 'Wagon'
                },
                'Hearse': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/hearse.png',
                    altText: 'Hearse',
                    description: 'Hearse'
                },
                'Limousine': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/limousine.png',
                    altText: 'Limousine',
                    description: 'Limousine'
                },
                'Extended Cargo Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/extended-cargo-van.png',
                    altText: 'ExtendedCargoVan',
                    description: 'Extended'
                },
                'Extended Passenger Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/extended-passenger-van.png',
                    altText: 'ExtendedPassengerVan',
                    description: 'Extended'
                },
                'Standard Cargo Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/standard-cargo-van.png',
                    altText: 'Standard Cargo Van',
                    description: 'Standard'
                },
                'Standard Passenger Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/standard-passenger-van.png',
                    altText: 'StandardPassengerVan',
                    description: 'Standard'
                },
                'Cab & Chassis': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/cab-and-chassis.png',
                    altText: 'CabAndChassis',
                    description: 'Cab & Chassis'
                },
                'Cutaway Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/cutaway-van.png',
                    altText: 'CutawayVan',
                    description: 'Cutaway'
                },
                'Mini Passenger Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/mini-passenger-van.png',
                    altText: 'MiniPassengerVan',
                    description: 'Mini Passenger'
                },
                'Stripped Chassis': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/stripped-chassis.png',
                    altText: 'StrippedChassis',
                    description: 'Stripped Chassis'
                },
                'Mini Cargo Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/mini-cargo-van.png',
                    altText: 'MiniCargoVan',
                    description: 'Mini Cargo'
                },
                'Van Camper': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/van-camper.png',
                    altText: 'VanCamper',
                    description: 'Camper'
                },
                'Targa': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/targa.png',
                    altText: 'Targa',
                    description: 'Targa'
                },
                'Straight Truck - Low Crew Cab': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/straight-truck-low-crew-cab.png',
                    altText: 'StraightTruckLowCrewCab',
                    description: 'Straight'
                },
                'Straight Truck - Low Tilt': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/straight-truck-low-tilt.png',
                    altText: 'StraightTruckLowTilt',
                    description: 'Straight L Tilt'
                },
                'Crew Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/crew-van.png',
                    altText: 'CrewVan',
                    description: 'Crew'
                },
                'Extended Crew Van': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/extended-crew-van.png',
                    altText: 'ExtendedCrewVan',
                    description: 'Crew Ext'
                },
                'Standard Cab Pickup': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/standard-cab-pickup.png',
                    altText: 'Standard',
                    description: 'Standard'
                },
                'Crew Cab Pickup': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/crew-cab-pickup.png',
                    altText: 'Crew',
                    description: 'Crew'
                },
                'Extended Cab Pickup': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/extended-cab-pickup.png',
                    altText: 'Extended',
                    description: 'Extended'
                },
                'Extended Crew Cab Pickup': {
                    image: 'https://vvs.autosyncstudio.com/static/icons/cabs/extended-crew-cab-pickup.png',
                    altText: 'ExtendedCrewCabPickup',
                    description: 'Crew Ext'
                }
            }
      },
      conflicting: function () {
        // eslint-disable-next-line
        console.log('From mixin vehiclesBodyType')
      }
    }
}

export default vehiclesBodyType;
