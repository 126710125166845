import colorBrightness from "@/utils/colorBrightness";

const colorMetalGradient = function (color) {
    if (color === 'FFFFFF' || color === 'FFF') {
        return [
            colorBrightness(color, -8),
            colorBrightness(color, -4),
            colorBrightness(color, 0),
            colorBrightness(color, -4),
            colorBrightness(color, 0)
        ];
    }

    return [
        colorBrightness(color, 0),
        colorBrightness(color, 24),
        colorBrightness(color, 48),
        colorBrightness(color, 24),
        colorBrightness(color, 12)
    ];
}

export default colorMetalGradient;
