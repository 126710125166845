<template>
    <div v-if="hasFilters" class="user-select-none">
        <!-- Desktop bar -->
        <div class="bg-brand-body position-relative">
            <div class="shadow-sm d-none d-lg-block desktop">
                <div class="d-flex justify-content-start align-items-center m-0 mx-3 mx-sm-4 py-3 px-sm-4">
                    <div class="d-flex justify-content-start align-items-center m-0 p-0 pe-3">
                        <div v-if="Object.keys(filters.Brands).length > 0" class="pe-3 pe-xl-5 me-3 me-xl-5 border-end border-2">
                            <div class="filters-tags fs-span cursor-pointer" @click="toggleFiltersOptions('brands')">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium">
                                    <span>BRAND: </span>
                                    <span class="filter-option-selected-desktop ms-2 fw-regular">{{filtersSelections.Brands || 'ALL'}}</span>
                                    <CloseButton v-if="filtersSelections.Brands" class="ms-3" @click="clearFilter('Brands')"/>
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.keys(filters.Diameters).length > 0" class="pe-3 pe-xl-5 me-3 me-xl-5 border-end border-2">
                            <div class="filters-tags fs-span cursor-pointer" @click="toggleFiltersOptions('diameters')">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium">
                                    <span>SIZE: </span>
                                    <span class="filter-option-selected-desktop ms-2 fw-regular">{{filtersSelections.Diameters ?  `${filtersSelections.Diameters}"`: 'ALL'}}</span>
                                    <CloseButton v-if="filtersSelections.Diameters" class="ms-3" @click="clearFilter('Diameters')"/>
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.keys(filters.ShortColors).length > 0" class="pe-3 pe-xl-5 me-3 me-xl-5 border-end border-2">
                            <div class="filters-tags fs-span cursor-pointer" @click="toggleFiltersOptions('colors')">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium">
                                    <span>COLOR: </span>
                                    <span class="filter-option-selected-desktop ms-2 fw-regular">{{filtersSelections.ShortColors || 'ALL'}}</span>
                                    <CloseButton v-if="filtersSelections.ShortColors" class="ms-3" @click="clearFilter('ShortColors')"/>
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.keys(filters.ShortFinishes).length > 0" class="border-2">
                            <div class="filters-tags fs-span cursor-pointer" @click="toggleFiltersOptions('finishes')">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium">
                                    <span>FINISH: </span>
                                    <span class="filter-option-selected-desktop ms-2 fw-regular">{{filtersSelections.ShortFinishes || 'ALL'}}</span>
                                    <CloseButton v-if="filtersSelections.ShortFinishes" class="ms-3" @click="clearFilter('ShortFinishes')"/>
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.keys(filters.Applications).length > 0" class="ps-3 ps-xl-5 ms-3 ms-xl-5 border-start border-2">
                            <div class="filters-tags fs-span cursor-pointer" @click="toggleFiltersOptions('applications')">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium">
                                    <span>APPLICATION: </span>
                                    <span class="filter-option-selected-desktop ms-2 fw-regular">{{filtersSelections.Applications || 'ALL'}}</span>
                                    <CloseButton v-if="filtersSelections.Applications" class="ms-3" @click="clearFilter('Applications')"/>
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.keys(filters.Segments).length > 1" class="ps-3 ps-xl-5 ms-3 ms-xl-5 border-start border-2">
                            <div class="filters-tags fs-span cursor-pointer" @click="toggleFiltersOptions('segments')">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium">
                                    <span>SEGMENT: </span>
                                    <span class="filter-option-selected-desktop ms-2 fw-regular">{{filtersSelections.Segments || 'ALL'}}</span>
                                    <CloseButton v-if="filtersSelections.Segments" class="ms-3" @click="clearFilter('Segments')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <ClearFiltersButton v-if="showOption.clear" class="ms-auto filters-tags fw-regular" @clearFilters="clearFilters"/>
                </div>
            </div>
            
            <!-- Mobile bar -->
            <div class="shadow-sm d-block d-lg-none">
                <div class="d-flex justify-content-start align-items-center fw-regular filters-tags cursor-pointer">
                    <div v-if="!showOption.selection" @click="toggleOptions" class="d-flex justify-content-center aling-items-center px-2 py-2 px-sm-4">
                        <small>FILTER BY</small>
                        <small v-show="showOption.options" class="mx-2 icon-size">
                            <Icon icon-name="navArrowUp"/>
                        </small>
                        <small v-show="!showOption.options" class="mx-2 icon-size">
                            <Icon icon-name="navArrowDown"/>
                        </small>
                    </div>
                    <div v-if="showOption.selection" class="flex-fill px-3 py-2 px-sm-4">
                        <div
                            v-for="(option, key) in filters"
                            :key="key"
                        >
                            <div v-show="showOption[key]" @click="toggleSelections">
                                <div class="d-flex justify-content-start align-items-center detail-option-selected">
                                    <Icon icon-name="arrowLeft" class="icon-size"/>
                                    <small v-if="key == 'Brands'" class="ms-3">BRAND: </small>
                                    <small v-if="key == 'Diameters'" class="ms-3">SIZE: </small>
                                    <small v-if="key == 'ShortColors'" class="ms-3">COLOR: </small>
                                    <small v-if="key == 'ShortFinishes'" class="ms-3">FINISH: </small>
                                    <small v-if="key == 'Applications'" class="ms-3">APPLICATION: </small>
                                    <small v-if="key == 'Segments'" class="ms-3">SEGMENT: </small>
                                    <small class="ms-2">{{filtersSelections[key] || 'ALL'}}</small>
                                    <small>{{key === 'Diameters' && filtersSelections[key] ? '"':''}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <ClearFiltersButton v-if="showOption.clear && !showOption.selection" class="mx-2 mx-sm-4 my-2 ms-auto filters-tags" @clearFilters="clearFilters"/>
                </div>
            </div>

            <!-- Mobile filters options -->
            <div
                v-show="showOption.options"
                class="text-white bg-brand-gray px-3 shadow options-container"
            >
                <div class="d-block d-lg-none">
                    <div
                        v-if="Object.keys(filters.Brands).length > 0"
                        class="d-flex justify-content-between align-items-center options-filter filters-tags text-uppercase py-3 cursor-pointer"
                        @click="toggleFiltersOptions('brands', 'mobile')"
                    >
                        <small>BRAND:</small>
                        <span class="d-flex justify-content-end align-items-center">
                            <small class="filter-option-selected-mobile">{{filtersSelections.Brands || 'ALL'}}</small>
                            <CloseButton v-if="filtersSelections.Brands" class="ms-3" @click="clearFilter('Brands')"/>
                        </span>
                    </div>
                    <div
                        v-if="Object.keys(filters.Diameters).length > 0"
                        class="d-flex justify-content-between align-items-center options-filter filters-tags text-uppercase py-3 cursor-pointer"
                        @click="toggleFiltersOptions('diameters', 'mobile')"
                    >
                        <small>SIZE:</small>
                        <span class="d-flex justify-content-end align-items-center">
                            <small class="filter-option-selected-mobile">{{filtersSelections.Diameters ?  `${filtersSelections.Diameters}"`: 'ALL'}}</small>
                            <CloseButton v-if="filtersSelections.Diameters" class="ms-3" @click="clearFilter('Diameters')"/>
                        </span>
                    </div>
                    <div
                        v-if="Object.keys(filters.ShortColors).length > 0"
                        class="d-flex justify-content-between align-items-center options-filter filters-tags text-uppercase py-3 cursor-pointer"
                        @click="toggleFiltersOptions('colors', 'mobile')"
                    >
                        <small>COLOR:</small>
                        <span class="d-flex justify-content-end align-items-center">
                            <small class="filter-option-selected-mobile">{{filtersSelections.ShortColors || 'ALL'}}</small>
                            <CloseButton v-if="filtersSelections.ShortColors" class="ms-3" @click="clearFilter('ShortColors')"/>
                        </span>
                    </div>
                    <div
                        v-if="Object.keys(filters.ShortFinishes).length > 0"
                        class="d-flex justify-content-between align-items-center options-filter filters-tags text-uppercase py-3 cursor-pointer"
                        @click="toggleFiltersOptions('finishes', 'mobile')"
                    >
                        <small>FINISH:</small>
                        <span class="d-flex justify-content-end align-items-center">
                            <small class="filter-option-selected-mobile">{{filtersSelections.ShortFinishes || 'ALL'}}</small>
                            <CloseButton v-if="filtersSelections.ShortFinishes" class="ms-3" @click="clearFilter('ShortFinishes')"/>
                        </span>
                    </div>
                    <div
                        v-if="Object.keys(filters.Applications).length > 0"
                        class="d-flex justify-content-between align-items-center options-filter filters-tags text-uppercase py-3 cursor-pointer"
                        @click="toggleFiltersOptions('applications', 'mobile')"
                    >
                        <small>APPLICATION:</small>
                        <span class="d-flex justify-content-end align-items-center">
                            <small class="filter-option-selected-mobile">{{filtersSelections.Applications || 'ALL'}}</small>
                            <CloseButton v-if="filtersSelections.Applications" class="ms-3" @click="clearFilter('Applications')"/>
                        </span>
                    </div>
                    <div
                        v-if="Object.keys(filters.Segments).length > 1"
                        class="d-flex justify-content-between align-items-center options-filter filters-tags text-uppercase py-3 cursor-pointer"
                        @click="toggleFiltersOptions('segments', 'mobile')"
                    >
                        <small>SEGMENT:</small>
                        <span class="d-flex justify-content-end align-items-center">
                            <small class="filter-option-selected-mobile">{{filtersSelections.Segments || 'ALL'}}</small>
                            <CloseButton v-if="filtersSelections.Segments" class="ms-3" @click="clearFilter('Segments')"/>
                        </span>
                    </div>
                </div>
            </div>
            
            <!-- Filters selection -->
            <div v-show="showOption.selection" class="border-bottom shadow-sm p-2 px-sm-4 py-sm-2">
                <div v-show="!loading">
                    <div
                        v-for="(option, key) of filters"
                        :key="key"
                        class="container-fluid m-0 p-0"
                    >
                        <div v-show="showOption[key]" class="row justify-content-start align-items-strech m-0 p-0">
                            <div
                                v-for="(value, index)  of filters[key]"
                                :key="index"
                                :class="[
                                    'col-6 col-sm-4 col-md-3 col-xxl-2 p-0',
                                    key !== 'Brands' && 'p-1 p-sm-2',
                                    Object.keys(filters[key]).length > 2 && 'more-than-2',
                                    Object.keys(filters[key]).length > 3 && 'more-than-3',
                                    Object.keys(filters[key]).length > 6 && 'more-than-6'
                                ]"
                            >
                                <BrandCard
                                    v-if="key == 'Brands'"
                                    :brand-name="index"
                                    :brand-logo="getWheelBrandLogosUrl(brandsLogos[index])"
                                    :count="value"
                                    :selected="getWheelsFiltersStored().Brands === index || (index == brand && !getWheelsFiltersStored().Brands)"
                                    @click="addOrRemoveSelection(key, index)"
                                />

                                <div 
                                    v-else
                                    :class="[
                                        'position-relative',
                                        'd-flex h-100 justify-content-center align-items-center py-3 px-4 fw-medium',
                                        'cursor-pointer border rounded filter-option',
                                        filterIsSelected(key, index) && 'selected'
                                    ]"
                                    @click="addOrRemoveSelection(key, index)"
                                >
                                    <span class="fs-span">
                                        {{index}}{{key === 'Diameters' ? '"':''}}
                                    </span>
                                    <span class="fs-count">
                                        <small class="ms-1 fw-regular text-brand-muted">{{value ? `(${value})`:''}}</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="loading" class="text-center">
                    <small class="fw-medium">Loading filters...</small>
                </div>
            </div>
        </div>
    </div>

    <div v-else>
        <div class="bg-brand-body position-relative">
            <div class="shadow-sm d-none d-lg-block desktop">
                <div class="d-flex justify-content-start align-items-center m-0 mx-3 mx-sm-4 py-3 px-sm-4">
                    <div class="d-flex justify-content-start align-items-center m-0 p-0 pe-3">
                        <div class="pe-3 pe-xl-5 me-3 me-xl-5">
                            <div class="filters-tags fs-span cursor-pointer">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium text-white">
                                    <span>LOADING...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shadow-sm d-block d-lg-none">
                <div class="d-flex justify-content-start align-items-center fw-regular filters-tags cursor-pointer">
                    <div class="d-flex justify-content-center aling-items-center px-2 py-2 px-sm-4 text-white">
                        <small>LOADING...</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import BrandCard from "@/components/wheels/BrandCard.vue";
import ClearFiltersButton from "@/components/builder/ClearFiltersButton.vue";
import CloseButton from "@/components/shared/CloseButton.vue";
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'Filters',
    emits: [
        'changeFilters',
        'active'
    ],
    components: {
        BrandCard,
        ClearFiltersButton,
        CloseButton,
        Icon
    },
    data: function () {
        return {
            showOption: {
                options: false,
                selection: false,
                clear: false,
                Brands: false,
                Diameters: false,
                ShortColors: false,
                ShortFinishes: false,
                Applications: false,
                Segments: false
            },
            brandLogosUrlBase: null,
            brandsLogos: {},
            originalFilters: {},
            filters: {
                Brands: {},
                Diameters: {},
                ShortColors: {},
                ShortFinishes: {},
                Applications: {},
                Segments: {}
            },
            filtersSelections: {
                Brands: null,
                Diameters: null,
                ShortColors: null,
                ShortFinishes: null,
                Applications: null,
                Segments: null
            },
            applications: [
                'Car',
                'Truck',
                'SUV',
                'Van',
                'Trailer',
                'Dually',
                'UTV/ATV/Golf'
            ],
            loading: true
        }
    },
    props: {
        application: String,
        tags: Array,
        brand: String,
        lugCount: Number,
        boltCircle: Number,
        showApplications: Boolean,
        resetFilters: Boolean,
        isDually: Boolean,
        updateFilters: Boolean,
        vehicleDiameters: [String, Number]
    },
    computed: {
        hasFilters: function () {
            return Object.keys(this.filters.Brands).length > 0 || Object.keys(this.filters.Diameters).length > 0 ||
                Object.keys(this.filters.ShortColors).length > 0 || Object.keys(this.filters.ShortFinishes).length > 0 ||
                Object.keys(this.filters.Applications).length > 0 || Object.keys(this.filters.Segments).length > 0;
        }
    },
    methods:{
        clearFilter: function (filterName) {
            this.$store.commit('wheelsFilters', {
                key: filterName,
                val: null
            });
            this.changeFilters();
        },
        getWheelBrandLogosUrl: function (logoPath) {
            if (this.brandLogosUrlBase && logoPath) {
                return `${this.brandLogosUrlBase}${logoPath}`;  
            }
            return '';
        },
        getRequestFilter: function (filter, value) {
            if (typeof value == 'object' && value) {
                if (value.length) {
                    return `&${filter}=${encodeURIComponent(value.join())}`;
                }
            } else if (value) {
                return `&${filter}=${encodeURIComponent(value)}`;
            }
            return '';
        },
        getFiltersForRequest: function () {
            const application = this.getRequestFilter('f-tags', this.application);
            const tags = this.getRequestFilter('f-tags2', this.tags);
            const brand = this.getRequestFilter('f-brand', !this.application ? this.brand:'');
            const lugCount = this.getRequestFilter('f-lugCount', this.lugCount);
            const boltCircle = this.getRequestFilter('f-boltCircle', this.boltCircle);
            const vehicleTags = tags ? (this.isDually ? (tags+',Dually'):tags):(this.isDually ? 'f-tags2=Dually':'');
            return `${brand}${application}${vehicleTags}${lugCount}${boltCircle}`;
        },
        getWheelsFilters: async function () {
            let moreItems = true;
            let filtersResponse = [];
            let page = 1;

            while (moreItems) {
                const request = '/wheels/filters/combinations?i-brand&i-brandLogo&i-diameter&i-shortColor&i-shortFinish&i-tags&i-tags2&i-wheelStyleCount&p-size=500';
                const requestFilters = this.getFiltersForRequest();
                const diametersFilter = this.vehicleDiameters ? `&f-diameters=${this.vehicleDiameters}`:'';
                const filters = await apiRequest(`${request}${requestFilters}${diametersFilter}&p-number=${page}`);
                
                if (filters) {
                    this.brandLogosUrlBase = filters.BrandLogosUrlBase;
                    filters.FilterCombinations.forEach( element => {
                        filtersResponse.push(element);
                    });
                }

                if (!filters.MoreItems) {
                    moreItems = false;
                } else {
                    page++;
                }
            }
            this.originalFilters = filtersResponse.length > 0 ? filtersResponse:[];
        },
        getWheelsFiltersStored: function () {
            return this.$store.getters.wheelsFilters;
        },
        filterIsSelected: function (key, val) {
            if (this.getWheelsFiltersStored()[key] == val) {
                return true;
            }

            return false;
        },
        fillSelectionFilters: function () {
            const filterBy = {
                Brands: this.getWheelsFiltersStored().Brands,
                Diameters: this.getWheelsFiltersStored().Diameters,
                ShortColors: this.getWheelsFiltersStored().ShortColors,
                ShortFinishes: this.getWheelsFiltersStored().ShortFinishes,
                Applications: !this.application ? this.getWheelsFiltersStored().Applications:null,
                Segments: this.getWheelsFiltersStored().Segments
            };
            this.filters = {
                Brands: {},
                Diameters: {},
                ShortColors: {},
                ShortFinishes: {},
                Applications: {},
                Segments: {}
            };

            const fillFilterOption = ( (key, value, count) => {
                if (value) {
                    if (!this.filters[key][value]) {
                        this.filters[key][value] = count;
                    } else {
                        this.filters[key][value] += count;
                    }
                }
            });

            this.originalFilters.forEach(element => {
                if (!this.brandsLogos[element.Brand]) {
                    this.brandsLogos[element.Brand] = element.BrandLogo;
                }

                if (
                    this.application &&
                    (!filterBy.Diameters || filterBy.Diameters == element.Diameter) &&
                    (!filterBy.ShortColors || filterBy.ShortColors == element.ShortColor) &&
                    (!filterBy.ShortFinishes || filterBy.ShortFinishes == element.ShortFinish) &&
                    ( !filterBy.Applications || (this.applications.includes(filterBy.Applications)  && element.Tags.includes(filterBy.Applications)) ) &&
                    ( !filterBy.Segments || (!this.applications.includes(filterBy.Segments)  && element.Tags.includes(filterBy.Segments)) )
                ) {
                    fillFilterOption('Brands', element.Brand, element.WheelStyleCount);
                }

                if (
                    (!filterBy.Brands || filterBy.Brands == element.Brand) &&
                    (!filterBy.ShortColors || filterBy.ShortColors == element.ShortColor) &&
                    (!filterBy.ShortFinishes || filterBy.ShortFinishes == element.ShortFinish) &&
                    ( !filterBy.Applications || (this.applications.includes(filterBy.Applications)  && element.Tags.includes(filterBy.Applications)) ) &&
                    ( !filterBy.Segments || (!this.applications.includes(filterBy.Segments)  && element.Tags.includes(filterBy.Segments)) )
                ) {
                    fillFilterOption('Diameters', element.Diameter, element.WheelStyleCount);
                }

                if (
                    (!filterBy.Brands || filterBy.Brands == element.Brand) &&
                    (!filterBy.Diameters || filterBy.Diameters == element.Diameter) &&
                    (!filterBy.ShortFinishes || filterBy.ShortFinishes == element.ShortFinish) &&
                    ( !filterBy.Applications || (this.applications.includes(filterBy.Applications)  && element.Tags.includes(filterBy.Applications)) ) &&
                    ( !filterBy.Segments || (!this.applications.includes(filterBy.Segments)  && element.Tags.includes(filterBy.Segments)) )
                ) {
                    fillFilterOption('ShortColors', element.ShortColor, element.WheelStyleCount);
                }

                if (
                    (!filterBy.Brands || filterBy.Brands == element.Brand) &&
                    (!filterBy.Diameters || filterBy.Diameters == element.Diameter) &&
                    (!filterBy.ShortColors || filterBy.ShortColors == element.ShortColor) &&
                    ( !filterBy.Applications || (this.applications.includes(filterBy.Applications)  && element.Tags.includes(filterBy.Applications)) ) &&
                    ( !filterBy.Segments || (!this.applications.includes(filterBy.Segments)  && element.Tags.includes(filterBy.Segments)) )
                ) {
                    fillFilterOption('ShortFinishes', element.ShortFinish, element.WheelStyleCount);
                }

                if (
                    !this.application &&
                    (!filterBy.Brands || filterBy.Brands == element.Brand) &&
                    (!filterBy.Diameters || filterBy.Diameters == element.Diameter) &&
                    (!filterBy.ShortColors || filterBy.ShortColors == element.ShortColor) &&
                    (!filterBy.ShortFinishes || filterBy.ShortFinishes == element.ShortFinish) &&
                    ( !filterBy.Segments || (!this.applications.includes(filterBy.Segments)  && element.Tags.includes(filterBy.Segments)) )
                ) {
                    element.Tags.forEach(tag => {
                        if (this.applications.includes(tag)) {
                            fillFilterOption('Applications', tag, element.WheelStyleCount);
                        }
                    });
                }

                if (
                    (!filterBy.Brands || filterBy.Brands == element.Brand) &&
                    (!filterBy.Diameters || filterBy.Diameters == element.Diameter) &&
                    (!filterBy.ShortColors || filterBy.ShortColors == element.ShortColor) &&
                    (!filterBy.ShortFinishes || filterBy.ShortFinishes == element.ShortFinish) &&
                    ( !filterBy.Applications || (this.applications.includes(filterBy.Applications)  && element.Tags.includes(filterBy.Applications)) )
                ) {
                    element.Tags.forEach(tag => {
                        if (this.tags) {
                            if (!this.applications.includes(tag) && this.tags.includes(tag)) {
                                fillFilterOption('Segments', tag, element.WheelStyleCount);
                            }
                        }
                    });
                }
            });

            this.$store.commit('wheelsBrands', this.brandsLogos);
            this.$store.commit('wheelsBrandsUrlBase', this.brandLogosUrlBase);
        },
        clearFilters: function () {
            this.$store.commit('wheelsFilters', {action: 'clear'});
            this.changeFilters();
        },
        toggleOptions: function () {
            this.showOption.options = !this.showOption.options;
            if (!this.showOption.options) {
                this.hideAllFilters();
            }
            this.$emit('active', this.showOption.options);
        },
        toggleSelections: function () {
            this.showOption.options = true;
            this.showOption.selection = false;
        },
        toggleFiltersOptions: function (type, device) {
            switch (type) {
                case 'brands':
                    this.showOption.Diameters = false;
                    this.showOption.ShortColors = false;
                    this.showOption.ShortFinishes = false;
                    this.showOption.Applications = false;
                    this.showOption.Segments = false;

                    this.showOption.Brands = device === 'mobile' ? true:!this.showOption.Brands;
                    break;
                case 'diameters':
                    this.showOption.Brands = false;
                    this.showOption.ShortColors = false;
                    this.showOption.ShortFinishes = false;
                    this.showOption.Applications = false;
                    this.showOption.Segments = false;

                    this.showOption.Diameters = device === 'mobile' ? true:!this.showOption.Diameters;
                    break;
                case 'colors':
                    this.showOption.Brands = false;
                    this.showOption.Diameters = false;
                    this.showOption.Applications = false;
                    this.showOption.ShortFinishes = false;
                    this.showOption.Segments = false;

                    this.showOption.ShortColors = device === 'mobile' ? true:!this.showOption.ShortColors;
                    break;
                case 'finishes':
                    this.showOption.Brands = false;
                    this.showOption.Diameters = false;
                    this.showOption.Applications = false;
                    this.showOption.Segments = false;
                    this.showOption.ShortColors = false;

                    this.showOption.ShortFinishes = device === 'mobile' ? true:!this.showOption.ShortFinishes;
                    break;
                case 'applications':
                    this.showOption.Brands = false;
                    this.showOption.Diameters = false;
                    this.showOption.ShortColors = false;
                    this.showOption.ShortFinishes = false;
                    this.showOption.Segments = false;

                    this.showOption.Applications = device === 'mobile' ? true:!this.showOption.Applications;
                    break;
                case 'segments':
                    this.showOption.Brands = false;
                    this.showOption.Diameters = false;
                    this.showOption.ShortColors = false;
                    this.showOption.ShortFinishes = false;
                    this.showOption.Applications = false;

                    this.showOption.Segments = device === 'mobile' ? true:!this.showOption.Segments;
                    break;
            
                default:
                    break;
            }

            if (device === 'mobile') {
                this.showOption.options = false;
                if(!this.showOption.options){
                    this.showOption.selection = true;
                } else {
                    this.showOption.selection = false;
                }
            }

            if (
                this.showOption.Brands || this.showOption.Diameters ||
                this.showOption.ShortFinishes || this.showOption.ShortColors ||
                this.showOption.Applications || this.showOption.Segments
            ) {
                this.showOption.selection = true;
                this.$emit('active', true);
            } else {
                this.showOption.selection = false;
                this.$emit('active', false);
            }
        },
        hideAllFilters: function () {
            this.showOption.selection = false;
            this.showOption.options = false;
            this.showOption.Brands = false;
            this.showOption.Diameters = false;
            this.showOption.ShortColors = false;
            this.showOption.ShortFinishes = false;
            this.showOption.Applications = false;
            this.showOption.Segments = false;
        },
        addOrRemoveSelection: function (type, value) {
            const filtersStored = this.$store.getters.wheelsFilters;
            switch (type) {
                case 'Brands':
                    if (filtersStored.Brands !== value) {
                        this.$store.commit('wheelsFilters', {
                            key: 'Brands',
                            val: value
                        });
                    } else {
                        this.$store.commit('wheelsFilters', {
                            key: 'Brands',
                            val: null
                        });
                    }
                    this.changeFilters();
                    break;
                case 'Diameters':
                    if (filtersStored.Diameters !== value) {
                        this.$store.commit('wheelsFilters', {
                            key: 'Diameters',
                            val: value
                        });
                    } else {
                        this.$store.commit('wheelsFilters', {
                            key: 'Diameters',
                            val: null
                        });
                    }
                    this.changeFilters();
                    break;
                case 'ShortColors':
                    if (filtersStored.ShortColors !== value) {
                        this.$store.commit('wheelsFilters', {
                            key: 'ShortColors',
                            val: value
                        });
                    } else {
                        this.$store.commit('wheelsFilters', {
                            key: 'ShortColors',
                            val: null
                        });
                    }
                    this.changeFilters();
                    break;
                case 'ShortFinishes':
                    if (filtersStored.ShortFinishes !== value) {
                        this.$store.commit('wheelsFilters', {
                            key: 'ShortFinishes',
                            val: value
                        });
                    } else {
                        this.$store.commit('wheelsFilters', {
                            key: 'ShortFinishes',
                            val: null
                        });
                    }
                    this.changeFilters();
                    break;
                case 'Applications':
                    if (filtersStored.Applications !== value) {
                        this.$store.commit('wheelsFilters', {
                            key: 'Applications',
                            val: value
                        });
                    } else {
                        this.$store.commit('wheelsFilters', {
                            key: 'Applications',
                            val: null
                        });
                    }
                    this.changeFilters();
                    break;
                case 'Segments':
                    if (filtersStored.Segments !== value) {
                        this.$store.commit('wheelsFilters', {
                            key: 'Segments',
                            val: value
                        });
                    } else {
                        this.$store.commit('wheelsFilters', {
                            key: 'Segments',
                            val: null
                        });
                    }
                    this.changeFilters();
                    break;
            
                default:
                    break;
            }
        },
        checkFiltersStatus: function () {
            const filters = this.getWheelsFiltersStored();

            if (
                !filters.Brands && !filters.Diameters &&
                !filters.ShortFinishes && !filters.ShortColors && !filters.Segments &&
                (!filters.Applications || this.application)
            ) {
                this.filtersSelections = {
                    Brands: null,
                    Diameters: null,
                    ShortColors: null,
                    ShortFinishes: null,
                    Applications: null,
                    Segments: null
                };
                this.showOption.clear = false;
            } else {
                this.filtersSelections = {
                    Brands: filters.Brands || null,
                    Diameters: filters.Diameters || null,
                    ShortColors: filters.ShortColors || null,
                    ShortFinishes: filters.ShortFinishes || null,
                    Applications: filters.Applications || null,
                    Segments: filters.Segments || null
                };
                this.showOption.clear = true;
            }
        },
        changeFilters: function () {
            this.checkFiltersStatus();
            this.fillSelectionFilters();
            this.hideAllFilters();
            this.$emit('changeFilters');
            this.$emit('active', false);
        },
        loadFilters: async function () {
            this.loading = true;
            if (this.brand && this.application) {
                if (!this.resetFilters && this.application) {
                    this.$store.commit('wheelsFilters', {
                        key: 'Brands',
                        val: this.brand
                    });
                    this.filtersSelections = {Brands: this.brand}
                    this.showOption.clear = true;
                } else {
                    this.showOption.clear = false;
                }
            }

            await this.getWheelsFilters();
            this.fillSelectionFilters();
            this.checkFiltersStatus();
            this.loading = false;
        }
    },
    watch: {
        updateFilters: function (newVal) {
            if (newVal) {
                this.loadFilters();
                this.$emit('changeFilters');
                this.$emit('active', false);
            }
        }
    },
    created: function () {
        window.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target) && (this.showOption.selection || this.showOption.options)) {
                this.hideAllFilters();
                this.$emit('active', false);
            }
        })
    },
    beforeMount: async function () {
        await this.loadFilters();
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.icon-size {
    font-size: 20px;
}

.filters-tags {
    &:hover.desktop {
        color: #000000 !important;
    }
}

.filter-option {
    &:hover {
        @extend .bg-brand-light-gray;
    }

    &.selected {
        border-color: var(--client-color) !important;
        @extend .bg-brand-light-gray;
    }
}

.fs-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
}

.fs-span {
    font-size: .9rem;
}

.options-container {
    .options-filter {
        border-bottom: 1px solid rgba($color: white, $alpha: .2);;
    }
}

.filter-option-selected-desktop,
.filter-option-selected-mobile,
.detail-option-selected {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.filter-option-selected-desktop,
.filter-option-selected-mobile {
    text-align: right;
}

.filter-option-selected-desktop {
    max-width: 155px;
}

.filter-option-selected-mobile {
    width: 155px;
}

.detail-option-selected {
    width: 200px;
}

.grid-filters{
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;

    p {
        font-size: 10px;
        line-height: 1;
    }

    span.brand-name {
        font-size: 10px;
    }
}

@media only screen and (max-width: 576px) {
    .grid-filters {
        border-bottom: none;
        border-right: none;

        &:nth-child(1) {
            border-top: none;
        }
    }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
    .icon-size {
        font-size: 18px;
    }

    .filters-tags {
        font-size: .88rem;
        
        &:active {
            transform: scale(.995);
        }
    }

    .grid-filters {
        &:nth-child(1), &:nth-child(2) {
            border-top: none;
        }

        &:nth-child(1).more-than-2, &:nth-child(2).more-than-2 {
            border-top: none;
            border-bottom: 1px solid #dee2e6;
        }

        &:nth-child(3).more-than-2, &:nth-child(4).more-than-2 {
            border-top: none;
        }
        
        &:nth-child(2n) {
            border-right: none;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1399px) {
    .filter-option-selected-desktop {
        width: 40px;
    }

    .fs-span {
        font-size: .85rem;
    }

    .grid-filters {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            border-top: none;
        }

        &:nth-child(1).more-than-3, &:nth-child(2).more-than-3, &:nth-child(3).more-than-3 {
            border-top: none;
            border-bottom: 1px solid #dee2e6;
        }

        &:nth-child(4).more-than-3, &:nth-child(5).more-than-3, &:nth-child(6).more-than-3 {
            border-top: none;
        }
        
        &:nth-child(3n) {
            border-right: none;
        }
    }
}

@media only screen and (min-width: 1400px) {
    .grid-filters {
        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
            border-top: none;
        }
        
        &:nth-child(1).more-than-6, &:nth-child(2).more-than-6, &:nth-child(3).more-than-6,
        &:nth-child(4).more-than-6, &:nth-child(5).more-than-6, &:nth-child(6).more-than-6 {
            border-top: none;
            border-bottom: 1px solid #dee2e6;
        }

        &:nth-child(7).more-than-6, &:nth-child(8).more-than-6, &:nth-child(9).more-than-6,
        &:nth-child(10).more-than-6, &:nth-child(11).more-than-6, &:nth-child(12).more-than-6 {
            border-top: none;
        }
        
        &:nth-child(6n) {
            border-right: none;
        }
    }
}
</style>
