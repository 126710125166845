<template>
    <div v-if="renderable" class="container-fluid m-0 p-0 pb-4">
        <div class="row m-0 justify-content-between align-items-center">
            <div v-show="showPagination" class="col-12 col-md-4 p-0 m-0 order-0 order-md-1">
                <div class="d-flex justify-content-center align-items-center my-4">
                    <div>
                        <div
                            :class="[
                                'mx-3 fw-medium',
                                itemsPagination.current > 1 ? 'text-brand-primary cursor-pointer toggle-active':'text-brand-muted',
                                itemsPagination.loading && 'text-brand-muted'
                            ]"
                            @click="previousPage"
                        >
                            <small class="text-uppercase">&lt;&lt;&lt; prev</small>
                        </div>
                    </div>
                    <div 
                        :class="[
                            'mx-3 text-brand-primary fw-medium',
                            itemsPagination.loading && 'text-brand-muted'
                        ]"
                    >
                        <small class="text-uppercase">|</small>
                    </div>
                    <div>
                        <div
                            :class="[
                                'mx-3 fw-medium',
                                itemsPagination.moreItems ? 'text-brand-primary cursor-pointer toggle-active':'text-brand-muted',
                                itemsPagination.loading && 'text-brand-muted'
                            ]"
                            @click="nextPage"
                        >
                            <small class="text-uppercase">next &gt;&gt;&gt;</small>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="showPagination" class="col-12 col-md-4 p-0 order-2 py-2 px-3 text-center text-md-end position-relative cursor-pointer">
                <div v-if="showSizes" class="d-inline position-absolute border rounded" style="bottom: 100%">
                    <div
                        v-for="(size, key) in pageSizes"
                        :key="key"
                        class="p-2 page-size-selector"
                        @click="selectSize(size)"
                    >
                        <small>{{size}}</small>
                    </div>
                </div>
                <div class="d-inline border rounded p-2" @click="showSizeOptions">
                    <small>{{pageSize}}</small>
                </div>
            </div>

            <div v-show="showPagination" class="col-12 col-md-4 py-2 px-3 order-1 order-md-0 text-center text-md-start">
                <small class="text-muted">Page. {{itemsPagination.current}}</small>
            </div>

            <ScrollButton v-if="scroll"/>
        </div>
    </div>
</template>

<script>
import ScrollButton from "@/components/shared/ScrollButton.vue";

export default {
    name: 'Pagination',
    components: {
        ScrollButton
    },
    emits: [
        'nextPage',
        'previousPage',
        'changePageSize'
    ],
    data: function () {
        return {
            pageSizes: [24, 48, 96],
            pageSize: 0,
            renderable: true,
            showSizes: false
        }
    },
    props: {
        itemsPagination: Object,
        showPagination: Boolean,
        scroll: Boolean
    },
    methods: {
        previousPage: function () {
            this.showSizes = false;
            this.$emit('previousPage');
        },
        nextPage: function () {
            this.showSizes = false;
            this.$emit('nextPage');
        },
        showSizeOptions: function() {
            this.showSizes = !this.showSizes;

            if (this.showSizes) {
                window.addEventListener('click', (e) => {
                    if (!this.$el.contains(e.target)){
                        this.showSizes = false;
                    }
                })
            }
        },
        selectSize: function (size) {
            this.showSizes = false;
            this.pageSize = size;
            this.$emit('changePageSize', size);
        }
    },
    beforeMount: function () {
        if (this.itemsPagination.pageSize) {
            this.pageSize = this.itemsPagination.pageSize;
        }

        if (
            this.itemsPagination &&
            this.itemsPagination.current !== undefined &&
            this.itemsPagination.moreItems !== undefined &&
            this.itemsPagination.loading !== undefined
        ) {
            this.renderable = true;
        } else {
            this.renderable = false;
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.page-size-selector {
    @extend .bg-brand-body-secondary;

    &:hover {
        @extend .bg-brand-light-gray;
    }
}
</style>
