import colorMetalGradient from "@/utils/colorMetalGradient";
import colorBrightness from "@/utils/colorBrightness";

const colorBrushed = function (primary, secondary) {
    if (primary === 'Custom') {
        return `background: linear-gradient(135deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8);`;
    }

    if (!secondary) {
        const gradient = colorMetalGradient(primary);
        return `background: linear-gradient(135deg, ${gradient[0]} 0%, ${gradient[1]} 47%, ${gradient[2]} 53%, ${gradient[3]} 59%, ${gradient[3]} 100%);`;
    }

    const gradient1 = colorMetalGradient(primary);
    return `background: linear-gradient(135deg, ${gradient1[0]} 0%, ${gradient1[1]} 28.54%, ${gradient1[2]} 34.54%, ${gradient1[3]} 40.54%, ${gradient1[3]} 61.8033%, ${colorBrightness(secondary, 0)} 61.8033%, ${colorBrightness(secondary, 0)} 67.3%, ${colorBrightness(secondary, -25)} 100%);`;
}

export default colorBrushed;
