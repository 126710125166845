var hasDually = {
    methods: {
        hasDually: function (vehiclesId) {
            if (!this.vehicleConfigurations) {
                return false;
            }

            if (!vehiclesId || !this.vehicleConfigurations['duallys']) {
                return false;
            }

            if (!this.vehicleConfigurations['duallys']['true']) {
                return false;
            }

            let hasDually = false;
            vehiclesId.forEach(id => {
                if (this.vehicleConfigurations['duallys']['true'].includes(id)) {
                    hasDually = true;
                }
            });
            return hasDually;
        }
    }
}

export default hasDually;
