<template>
    <div
        class="bg-brand-text text-brand-white toggle-active cursor-pointer shadow"
        @click="clearFilters"
    >
        <small class="py-1 px-3 text-nowrap">CLEAR ALL</small>
    </div>
</template>

<script>
export default {
    name: 'ClearFiltersButton',
    emits: ['clearFilters'],
    methods: {
        clearFilters: function () {
            this.$emit('clearFilters')
        }
    }
}
</script>
