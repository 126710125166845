<template>
    <div
        :class="[
            'position-relative',
            !showFavorites && 'd-none'
        ]"
    >
        <Overlay
            center
            @close="close"
        >
            <div class="text-center">
                <div class="d-flex justify-content-start align-items-center mb-3 border-bottom py-2 mx-2 mx-sm-4 mx-lg-5">
                    <div>
                        <h6 class="fw-medium my-0 text-truncate" style="max-width: 50vw">{{vehicle ? `${vehicle.Year} ${vehicle.Make.toUpperCase()} ${vehicle.Model.toUpperCase()}` : 'FAVORITES'}}</h6>
                    </div>
                    <div v-if="!clearFavoritesAuthorization && !clearFavoritesSectionAuthorization" class="ms-4">
                        <IconButton v-if="existsFavorites" trash @activated="clearFavoritesAuthorization = true"/>
                    </div>
                </div>

                <div class="px-sm-3 px-xxl-2 favorites-container">
                    <div
                        :class="[
                            'favorites-clear-confirmation',
                            'rounded',
                            (clearFavoritesAuthorization || clearFavoritesSectionAuthorization) && 'show-confirmation'
                        ]"
                        @click.stop="clearFavoritesAuthorization = false; clearFavoritesSectionAuthorization = false;"
                    >
                        <div class="favorites-clear-confirmation-container d-flex flex-column justify-content-center align-items-center w-100">
                            <div class="mb-2 text-center">
                                <h5 v-if="clearFavoritesAuthorization" class="text-white">Are you sure to clear this favorites list?</h5>
                                <h5 v-if="clearFavoritesSectionAuthorization" class="text-white">Are you sure to clear favorites list for Products?</h5>
                            </div>
                            <div class="d-flex justify-content-center align-items-center w-75 user-select-none">
                                <div
                                    class="bg-brand-primary text-white px-4 py-2 rounded shadow-sm fw-regular cursor-pointer toggle-active"
                                    @click.stop="clearFavorites"
                                >CLEAR</div>
                                <div
                                    class="border border-color-brand-primary bg-brand-white text-brand-primary fw-regular px-3 py-2 rounded shadow-sm ms-4 cursor-pointer toggle-active"
                                    @click.stop="clearFavoritesAuthorization = false; clearFavoritesSectionAuthorization = false;"
                                >CANCEL</div>
                            </div>
                        </div>
                    </div>

                    <div v-if="existsFavorites">
                        <div v-if="wheelFavorites.isGlobal">
                            <div
                                v-if="wheelFavorites.styles && wheelFavorites.styles['globals']"
                                class="container-fuild w-100 m-0 p-0 my-2"
                            >
                                <div
                                    v-if="Object.keys(wheelFavorites.styles).length > 1"
                                    class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0"
                                >
                                    <div class="d-flex justify-content-start align-items-center">
                                        <div class="me-4">
                                            <IconButton v-if="existsFavorites" trash @activated="clearFavoritesSectionId = 'globals'; clearFavoritesSectionAuthorization = true;"/>
                                        </div>

                                        <div class="text-start">
                                            <span class="fw-medium">Only product</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0">
                                    <div
                                        v-for="(wheels, styleId) in wheelFavorites.styles['globals'].styles"
                                        :key="styleId"
                                        class="col-6 col-md-4 col-lg-3 col-xl-2 p-0 m-0"
                                    >
                                        <WheelCard
                                            v-for="wheel in wheels"
                                            :key="wheel.StyleId"
                                            :wheel="wheel"
                                            :wheel-base-url="wheelBaseUrl"
                                            :wheel-selected="wheelSelected"
                                            full-size
                                            favorite-card
                                            @wheelSelect="wheelSelect(wheel)"
                                            @wheelFavoriteToggle="wheelFavoriteToggle(wheel, 'globals')"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                v-for="(obj, key) in wheelFavorites.styles"
                                :key="key"
                                class="container-fuild w-100 m-0 p-0 mt-4"
                            >
                                <div v-if="key !== 'globals'" class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0">
                                    <div class="col-12 fw-medium text-start px-2 px-xxl-3">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div class="me-4">
                                                <IconButton v-if="existsFavorites" trash @activated="clearFavoritesSectionId = key; clearFavoritesSectionAuthorization = true"/>
                                            </div>

                                            <div class="text-start">
                                                <span v-if="obj.name" class="fw-medium">{{obj.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="key !== 'globals'" class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0">
                                    <div
                                        v-for="(wheels, styleId) in obj.styles"
                                        :key="styleId"
                                        class="col-6 col-md-4 col-lg-3 col-xl-2 m-0 p-0"
                                    >
                                        <WheelCard
                                            v-for="wheel in wheels"
                                            :key="wheel.StyleId"
                                            :wheel="wheel"
                                            :wheel-base-url="wheelBaseUrl"
                                            :wheel-selected="wheelSelected"
                                            full-size
                                            favorite-card
                                            @wheelSelect="wheelSelect(wheel)"
                                            @wheelFavoriteToggle="wheelFavoriteToggle(wheel, key)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!wheelFavorites.isGlobal" class="container-fuild w-100 m-0 p-0">
                            <div class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0">
                                <WheelCard
                                    v-for="wheel in wheelFavorites.styles"
                                    :key="wheel.StyleId"
                                    :wheel="wheel"
                                    :wheel-base-url="wheelBaseUrl"
                                    :wheel-selected="wheelSelected"
                                    favorite-card
                                    @wheelSelect="wheelSelect(wheel)"
                                    @wheelFavoriteToggle="wheelFavoriteToggle(wheel, vehicleId)"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-else class="d-flex justify-content-center align-items-center p-0 px-lg-4 m-0">
                        <div class="p-5">
                            <h6>Click on <b>Star icon</b> to add a wheel to favorites</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    </div>
</template>

<script>
import Overlay from '@/components/shared/Overlay.vue';
import WheelCard from "@/components/wheels/WheelCard.vue";
import IconButton from '@/components/shared/IconButton.vue';

export default {
    name: "Favorites",
    components: {
        Overlay,
        WheelCard,
        IconButton
    },
    emits: [
        "close"
    ],
    data: function () {
        return {
            showFavorites: false,
            clearFavoritesSectionId: null,
            clearFavoritesAuthorization: false,
            clearFavoritesSectionAuthorization: false
        };
    },
    props: {
        open: Boolean,
        wheelBaseUrl: {
            type: String,
            required: true
        },
        wheelSelected: {
            type: Object,
            required: true
        },
        vehicle: {
            type: Object,
            required: false
        }
    },
    watch: {
        open: function (newVal) {
            this.showFavorites = newVal;
        }
    },
    computed: {
        wheelFavorites: function () {
            let storedFavorites = this.$store.getters.wheelFavorites;
            let favorites = {};
            let wheelFavorites = {
                isGlobal: false,
                styles: []
            };
            let vehicleId = this.vehicle ? this.vehicle.Id : null;

            if (vehicleId) {
                favorites = storedFavorites[`${vehicleId}`] ? storedFavorites[`${vehicleId}`].styles : {};
            } else {
                wheelFavorites.isGlobal = true;
                wheelFavorites.styles = storedFavorites;
            }
            
            if (!wheelFavorites.isGlobal) {
                for (const styleId in favorites) {
                    for (const wheelId in favorites[styleId]) {
                        wheelFavorites.styles.push(favorites[styleId][wheelId]);
                    }
                }
            }

            return wheelFavorites;
        },
        existsFavorites: function () {
            if (this.wheelFavorites.isGlobal) {
                return Object.keys(this.wheelFavorites.styles).length > 0;
            }
            return this.wheelFavorites.styles.length > 0;
        }
    },
    methods: {
        close: function () {
            this.showFavorites = false;
            this.clearFavoritesAuthorization = false;
            this.clearFavoritesSectionAuthorization = false;
            this.clearFavoritesSectionId = null;
            this.$emit('close', false);
        },
        wheelSelect: function (wheel) {
            const vehicleColorId = this.vehicle ?
            (this.vehicle.ColorSelected ? this.vehicle.ColorSelected:this.vehicle.ImgColorId) : null;
            let path = this.vehicle ?
                `/builder?vehicle_id=${this.vehicle.Id}${vehicleColorId ? '&vehicle_color=' + vehicleColorId:''}&wheel_brand=${wheel.Brand.replace(/\s/g, '%20')}&wheel_style_id=${wheel.StyleId}&wheel_id=${wheel.Id}`:
                `/builder?wheels_view&wheel_brand=${wheel.Brand}&wheel_style_id=${wheel.StyleId}&wheel_id=${wheel.Id}`;
            this.$router.push(path);
            this.close();
        },
        wheelFavoriteToggle: function (wheel, key) {
            let wheelFavorites = this.$store.getters.wheelFavorites;
            let wheelId = wheel.Id || wheel.ImgWheelId || null;
            let styleId = wheel.StyleId || null;

            if (key && wheelFavorites[`${key}`] && wheelFavorites[`${key}`]['styles'][`${styleId}`] && wheelFavorites[`${key}`]['styles'][`${styleId}`][`${wheelId}`]) {
                delete wheelFavorites[`${key}`]['styles'][`${styleId}`][`${wheelId}`];

                if (!Object.keys(wheelFavorites[`${key}`]['styles'][`${styleId}`]).length) {
                    delete wheelFavorites[`${key}`]['styles'][`${styleId}`];
                }

                if (!Object.keys(wheelFavorites[`${key}`]['styles']).length) {
                    delete wheelFavorites[`${key}`];
                }
            }

            this.$store.commit('wheelFavorites', wheelFavorites);
        },
        clearFavorites: function () {
            let storedFavorites = this.$store.getters.wheelFavorites;

            if (this.clearFavoritesAuthorization) {
                if (this.vehicle && this.vehicle.Id) {

                    if (storedFavorites && storedFavorites[this.vehicle.Id]) {
                        delete storedFavorites[this.vehicle.Id];
                    }

                    this.$store.commit('wheelFavorites', storedFavorites);
                } else {
                    this.$store.commit('wheelFavorites', {});
                }
            } else if (this.clearFavoritesSectionAuthorization) {
                if (storedFavorites && storedFavorites[this.clearFavoritesSectionId]) {
                    delete storedFavorites[this.clearFavoritesSectionId];
                }

                this.$store.commit('wheelFavorites', storedFavorites);
            }

            this.close();
        }
    }
}
</script>

<style lang="scss">
.favorites-container {
    position: relative;
    min-width: 92vw;

    .favorites-clear-confirmation {
        background-color: rgba($color: #000000, $alpha: .86);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: -999;
        transition: opacity .3s ease;
        -o-transition: opacity .3s ease;
        -moz-transition: opacity .3s ease;
        -webkit-transition: opacity .3s ease;

        &.show-confirmation {
            opacity: 1;
            z-index: 1;
        }

        .favorites-clear-confirmation-container {
            height: 100%;
            max-height: 86vh;
        }
    }
}
</style>
