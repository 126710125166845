var vehiclesRearAxleBySubmodel = {
    computed: {
        getRearAxleBySubmodel: function () {
            if (!this.vehicleConfigurations) {
                return {}
            }

            const submodelVehicles = this.vehicleConfigurations.submodels[this.vehicle.Submodel] || [];
            const bodyTypeVehicles = this.vehicleConfigurations.bodys[this.vehicle.Body] || [];
            const rearAxleTypes = this.vehicleConfigurations.duallys;
            let rearAxleBySubmodel = {};
            
            submodelVehicles.forEach(vehicleId => {
                for (const axle in rearAxleTypes) {
                    if (rearAxleTypes[axle].includes(vehicleId) && bodyTypeVehicles.includes(vehicleId)) {
                        if (!rearAxleBySubmodel[axle]) {
                            rearAxleBySubmodel[axle] = [vehicleId];
                        } else {
                            rearAxleBySubmodel[axle].push(vehicleId);
                        }
                    } 
                }
            });

            return rearAxleBySubmodel;
        },
        conflicting: function () {
            // eslint-disable-next-line
            console.log('From mixin vehiclesRearAxleBySubmodel')
        }
    }
}

export default vehiclesRearAxleBySubmodel;
