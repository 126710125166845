<template>
    <div
        v-if="image"
        class="position-relative"
    >
        <Overlay
            v-if="showZoomImage"
            center
            remove-padding
            hide-overflow
            :white-button="useMobileView()"
            :open="showZoomImage"
            @close="close"
        >
            <div
                v-if="wheelImages && zoomIsAvailable"
                :class="[
                    'zoom-container zoom-background',
                    useMobileView() && 'zoom-mobile'
                ]"
                style="width: 86vw;"
                @touchstart="touchStart"
            >
                <div
                    v-if="useMobileView()"
                    class="h-100 w-100"
                >
                    <div
                        v-if="$store.getters.device.isMobile && $store.getters.device.isLandscape && getAspectRatio() > 1.3"
                        class="zoom-content position-relative d-flex justify-content-center align-items-center w-100 h-100"
                    >
                        <div
                            class="zoom-wheel-focus"
                            :style="getVehicleZoomStyle()"
                        >
                            <VehicleImage
                                v-if="wheelImages"
                                :vehicle-large-image="image"
                                :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
                                :view-side="selectedSide"
                                :wheel-images="wheelImages"
                                :wheel-diameter="wheelDiameter"
                                :coordinates="vehicleCoordinates"
                                :angles-img-codes="vehicleAnglesImgCodes"
                                selected
                            />
                        </div>

                        <div
                            :class="[
                                'carrousel-arrows-container',
                                useMobileView() && !$store.getters.device.isLandscape && 'rotate'
                            ]"
                        >
                            <ImagesCarrouselController
                                v-if="getVehicleImages.one || getVehicleImages.three"
                                arrows
                                :images="getVehicleImages"
                                :default-selected-option="currentSelectedOption"
                                :move-to-next="moveToNext"
                                :move-to-previous="moveToPrevious"
                                allow-circular-selection
                                @disallowOption="disallowOption"
                                @selected-option="selectedOption"
                            />
                        </div>

                        <div v-if="getClientLogo" class="client-logo-container small user-select-none ps-3 py-2 w-100">
                            <div class="client-logo h-100 w-50">
                                <img :src="getClientLogo" alt="" class="h-100">
                            </div>
                        </div>

                        <ShareButton
                            class="share-container me-4 py-3"
                            :vehicle="vehicle"
                            :wheel="wheel"
                            big
                            focus-on-vehicle
                        />

                        <div class="info-container small w-100 d-flex justify-content-between align-items-center">
                            <div v-if="getWheelThumbnail" class="d-flex justify-content-end align-items-center ps-3 rounded">
                                <div class="wheel-thumbnail p-1">
                                    <img :src="getWheelThumbnail" alt="" class="w-100">
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-start">
                                    <div>
                                        <small v-if="wheel.Brand" class="px-1 ps-3 fw-medium">{{wheel.Brand}}</small>
                                        <small v-if="wheel.Model" class="fw-medium">|</small>
                                        <small v-if="wheel.Model" class="px-1 fw-medium">{{wheel.Model}}</small>
                                        <small v-if="wheel.Submodel" class="px-1 fw-medium">{{wheel.Submodel}}</small>
                                    </div>

                                    <div v-if="wheel.Pn" class="px-1 ps-3 text-muted fw-medium py-1" style="font-size: 80%">
                                        <small>P/N: </small>
                                        <small>{{wheel.Pn}}</small>
                                    </div>

                                    <div class="px-1 ps-3 text-muted fw-regular" style="font-size: 80%">
                                        <ul class="d-flex justify-content-between align-items-center m-0 p-0">
                                            <li style="list-style-type:none;">
                                                <small>{{wheel.Diameter}}"x{{wheel.Width}}"</small>
                                            </li>
                                            <li class="ms-3">
                                                <small v-if="wheel.Finish">{{wheel.Finish}}</small>
                                                <small v-if="wheel.ShortColor && wheel.Finish != wheel.ShortColor" :class="wheel.Finish && wheel.ShortColor && 'ms-1'">{{wheel.ShortColor}}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-if="getVehicleMakeLogo" class="d-flex justify-content-end align-items-center px-3 rounded">
                                <div class="d-flex flex-column justify-content-center align-items-end">
                                    <div>
                                        <small v-if="vehicle.Year" class="px-1 fw-medium">{{vehicle.Year}}</small>
                                        <small v-if="vehicle.Make" class="px-1 fw-medium">{{vehicle.Make}}</small>
                                        <small v-if="vehicle.Model" class="fw-medium">|</small>
                                        <small v-if="vehicle.Model" class="px-1 fw-medium">{{vehicle.Model}}</small>
                                        <small class="px-1 fw-medium">{{vehicle.Submodel}}</small>
                                    </div>

                                    <div class="px-1 ps-4 text-muted fw-regular" style="font-size: 80%">
                                        <ul class="d-flex justify-content-between align-items-center m-0 p-0">
                                            <li style="list-style-type: none">
                                                <small>B/P: {{vehicle.LugCount}}x{{vehicle.BoltCircle}}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div class="py-2 px-3">
                                    <img :src="getVehicleMakeLogo" alt="" class="vehicle-make-logo">
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div
                        v-else
                        class="position-relative d-flex justify-content-center align-items-center w-100 h-100"
                    >
                        <div
                            class="zoom-wheel-focus"
                            :style="getVehicleZoomStyle()"
                        >
                            <VehicleImage
                                v-if="wheelImages"
                                :vehicle-large-image="image"
                                :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
                                :view-side="selectedSide"
                                :wheel-images="wheelImages"
                                :wheel-diameter="wheelDiameter"
                                :coordinates="vehicleCoordinates"
                                :angles-img-codes="vehicleAnglesImgCodes"
                                selected
                            />
                        </div>

                        <div class="carrousel-arrows-container">
                            <ImagesCarrouselController
                                v-if="getVehicleImages.one || getVehicleImages.three"
                                arrows
                                :images="getVehicleImages"
                                :default-selected-option="currentSelectedOption"
                                :move-to-next="moveToNext"
                                :move-to-previous="moveToPrevious"
                                allow-circular-selection
                                @disallowOption="disallowOption"
                                @selected-option="selectedOption"
                            />
                        </div>

                        <div v-if="getClientLogo" class="client-logo-container mobile user-select-none ps-3 py-2 w-100">
                            <div class="client-logo h-100 w-50">
                                <img :src="getClientLogo" alt="" class="h-100">
                            </div>
                        </div>

                        <ShareButton
                            class="share-container me-3"
                            :vehicle="vehicle"
                            :wheel="wheel"
                            big
                            focus-on-vehicle
                        />

                        <div class="info-container mobile w-100 d-flex flex-column justify-content-between align-items-start px-2 py-1">                            
                            <div v-if="getVehicleMakeLogo" class="d-flex w-100 justify-content-end align-items-center rounded">
                                <div class="d-flex w-100 justify-content-between align-items-center">
                                    <div class="py-2 px-3">
                                        <img :src="getVehicleMakeLogo" alt="" class="vehicle-make-logo">
                                    </div>

                                    <div class="d-flex flex-column justify-content-center align-items-end">
                                        <div class="text-end">
                                            <small v-if="vehicle.Year" class="px-1 fw-medium">{{vehicle.Year}}</small>
                                            <small v-if="vehicle.Make" class="px-1 fw-medium">{{vehicle.Make}}</small>
                                            <small v-if="vehicle.Model" class="fw-medium">|</small>
                                            <small v-if="vehicle.Model" class="px-1 fw-medium">{{vehicle.Model}}</small>
                                            <small class="px-1 fw-medium">{{vehicle.Submodel}}</small>
                                        </div>

                                        <div class="px-1 ps-4 py-1 text-muted text-end fw-regular" style="font-size: 80%">
                                            <ul class="d-flex justify-content-between align-items-center m-0 p-0">
                                                <li style="list-style-type: none">
                                                    <small>B/P: {{vehicle.LugCount}}x{{vehicle.BoltCircle}}</small>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-if="getWheelThumbnail" class="d-flex flex-fill w-100 justify-content-between align-items-center rounded py-2">
                                <div class="wheel-thumbnail p-1">
                                    <img :src="getWheelThumbnail" alt="" class="w-100">
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-end">
                                    <div class="text-end text-nowrap">
                                        <small v-if="wheel.Brand" class="px-1 ps-4 fw-medium">{{wheel.Brand}}</small>
                                        <small v-if="wheel.Model" class="fw-medium">|</small>
                                        <small v-if="wheel.Model" class="px-1 fw-medium">{{wheel.Model}}</small>
                                        <small v-if="wheel.Submodel" class="px-1 fw-medium">{{wheel.Submodel}}</small>
                                    </div>

                                    <div v-if="wheel.Pn" class="px-1 ps-4 text-muted text-end fw-medium py-1" style="font-size: 80%">
                                        <small>P/N: </small>
                                        <small>{{wheel.Pn}}</small>
                                    </div>

                                    <div class="px-1 ps-4 text-muted text-end fw-regular" style="font-size: 80%">
                                        <ul class="d-flex justify-content-between align-items-center m-0 p-0">
                                            <li style="list-style-type:none;">
                                                <small>{{wheel.Diameter}}"x{{wheel.Width}}"</small>
                                            </li>
                                            <li class="ms-4">
                                                <small v-if="wheel.Finish">{{wheel.Finish}}</small>
                                                <small v-if="wheel.ShortColor && wheel.Finish != wheel.ShortColor" :class="wheel.Finish && wheel.ShortColor && 'ms-1'">{{wheel.ShortColor}}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="zoom-content position-relative d-flex justify-content-center align-items-start w-100 h-100"
                >
                    <div
                        class="zoom-wheel-focus"
                        style="bottom: 30px"
                    >
                        <VehicleImage
                            v-if="wheelImages"
                            :view-side="selectedSide"
                            :wheel-images="wheelImages"
                            :wheel-diameter="wheelDiameter"
                            :vehicle-large-image="image"
                            :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
                            :coordinates="vehicleCoordinates"
                            :angles-img-codes="vehicleAnglesImgCodes"
                            selected
                            zoom
                        />
                    </div>

                    <div class="carrousel-arrows-container">
                        <ImagesCarrouselController
                            v-if="getVehicleImages.one || getVehicleImages.three"
                            arrows
                            :images="getVehicleImages"
                            :default-selected-option="currentSelectedOption"
                            :move-to-next="moveToNext"
                            :move-to-previous="moveToPrevious"
                            allow-circular-selection
                            @disallowOption="disallowOption"
                            @selected-option="selectedOption"
                        />
                    </div>

                    <div v-if="getClientLogo" class="client-logo-container user-select-none ms-4 mt-4 ms-xxl-5 mt-xxl-5">
                        <div class="client-logo h-100 w-50">
                            <img :src="getClientLogo" alt="" class="h-100">
                        </div>
                    </div>

                    <ShareButton
                        class="share-container me-4 py-3"
                        :vehicle="vehicle"
                        :wheel="wheel"
                        big
                        focus-on-vehicle
                    />

                    <div class="info-container w-100 d-flex justify-content-between align-items-center">
                        <div v-if="getWheelThumbnail" class="d-flex justify-content-end align-items-center px-4 rounded">
                            <div class="wheel-thumbnail p-2">
                                <img :src="getWheelThumbnail" alt="" class="w-100">
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-start">
                                <div>
                                    <small v-if="wheel.Brand" class="px-1 ps-4 fw-medium">{{wheel.Brand}}</small>
                                    <small v-if="wheel.Model" class="fw-medium">|</small>
                                    <small v-if="wheel.Model" class="px-1 fw-medium">{{wheel.Model}}</small>
                                    <small v-if="wheel.Submodel" class="px-1 fw-medium">{{wheel.Submodel}}</small>
                                </div>

                                <div v-if="wheel.Pn" class="px-1 ps-4 text-muted fw-medium py-1" style="font-size: 80%">
                                    <small>P/N: </small>
                                    <small>{{wheel.Pn}}</small>
                                </div>

                                <div class="px-1 ps-4 text-muted fw-regular" style="font-size: 80%">
                                    <ul class="d-flex justify-content-between align-items-center m-0 p-0">
                                        <li style="list-style-type:none;">
                                            <small>{{wheel.Diameter}}"x{{wheel.Width}}"</small>
                                        </li>
                                        <li class="ms-4">
                                            <small v-if="wheel.Finish">{{wheel.Finish}}</small>
                                            <small v-if="wheel.ShortColor && wheel.Finish != wheel.ShortColor" :class="wheel.Finish && wheel.ShortColor && 'ms-1'">{{wheel.ShortColor}}</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="getVehicleMakeLogo" class="d-flex justify-content-end align-items-center px-4 rounded">
                            <div class="d-flex flex-column justify-content-center align-items-end">
                                <div>
                                    <small v-if="vehicle.Year" class="px-1 fw-medium">{{vehicle.Year}}</small>
                                    <small v-if="vehicle.Make" class="px-1 fw-medium">{{vehicle.Make}}</small>
                                    <small v-if="vehicle.Model" class="fw-medium">|</small>
                                    <small v-if="vehicle.Model" class="px-1 fw-medium">{{vehicle.Model}}</small>
                                    <small class="px-1 fw-medium">{{vehicle.Submodel}}</small>
                                </div>

                                <div class="px-1 ps-4 text-muted fw-regular" style="font-size: 80%">
                                    <ul class="d-flex justify-content-between align-items-center m-0 p-0">
                                        <li style="list-style-type: none">
                                            <small>B/P: {{vehicle.LugCount}}x{{vehicle.BoltCircle}}</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div class="py-2 px-3">
                                <img :src="getVehicleMakeLogo" alt="" class="vehicle-make-logo">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="w-100 d-flex justify-content-center">
                <picture class="text-center bg-brand-body-secondary">
                    <source media="(max-width:576px)" srcset="https://vvs.autosyncstudio.com/static/not-available-350.png">
                    <img
                        src="https://vvs.autosyncstudio.com/static/not-available-960.png"
                        alt=""
                        class="user-select-none w-75"
                        @click="viewSelected"
                    >
                </picture>
            </div>
        </Overlay>
    </div>
</template>

<script>
import Overlay from '@/components/shared/Overlay.vue';
import VehicleImage from '@/components/builder/VehicleImage.vue';
import ImagesCarrouselController from '@/components/shared/ImagesCarrouselController.vue';
import ShareButton from '@/components/shared/ShareButton.vue';

export default {
    name: "ZoomVehicleImages",
    components: {
        Overlay,
        VehicleImage,
        ImagesCarrouselController,
        ShareButton
    },
    emits: ["close"],
    data: function () {
        return {
            image: '',
            imageDescription: '',
            showZoomImage: false,
            wheelImages: null,
            selectedSide: null,
            currentSelectedOption: null,
            moveToNext: false,
            moveToPrevious: false,
            disallowOptionPrevious: false,
            disallowOptionNext: false
        };
    },
    props: {
        open: Boolean,
        rotateOnMobile: Boolean,
        vehicle: Object,
        vehicleSide: String,
        vehicleImages: Object,
        vehicleCoordinates: Object,
        vehicleAnglesImgCodes: Object,
        vehicleImageWheelDiameter: Object,
        wheel: Object,
        wheelDiameter : Number,
        wheelImgUrlBase: {
            type: String,
            required: true
        }
    },
    computed: {
        zoomIsAvailable: function () {
            return this.getVehicleImages[this.getVehicleViewKeys[this.vehicleSide]] ? true: false;
        },
        getAngleCode: function () {
            return {back: 14, front: 32, side: 1};
        },
        getVehicleViewKeys: function () {
            return {
                back: 'one',
                front: 'two',
                side: 'three',
                one: 'back',
                two: 'front',
                three: 'side'
            }
        },
        getVehicleImages: function () {
            return {
                one: this.getWheelValidation('back') ? this.vehicleImages.back:null,
                two: this.getWheelValidation('front') ? this.vehicleImages.front:null,
                three: this.getWheelValidation('side') ? this.vehicleImages.side:null
            };
        },
        getVehicleMakeLogo: function () {
            if (this.vehicle && this.vehicle.Make) {
                const makes = this.$store.getters.vehiclesMakes.makes;
                const makeBaseUrl = this.$store.getters.vehiclesMakes.baseUrl;
                const makeId = this.vehicle.Make;
                const filterMake = (make) => make.Make === makeId;
                const make = makes.filter(filterMake);

                if (make && make.length > 0) {
                    return `${makeBaseUrl}${make[0].Logo}`;
                }
                return '';
            }
            return '';
        },
        getWheelThumbnail: function () {
            if (this.wheel && this.wheelImgUrlBase) {
                return this.wheel.Img0001 ? `${this.wheelImgUrlBase}${this.wheel.Img0001}`:'';
            }
            return '';
        },
        getClientLogo: function () {
            if (this.$store.getters.clientSettings.logo) {
                return this.$store.getters.clientSettings.logo;
            }
            return null;
        }
    },
    methods: {
        useMobileView: function () {
            return window.innerWidth < 900;
        },
        getAspectRatio: function () {
            return screen.width / screen.height;
        },
        getVehicleZoomStyle: function () {
            const angles = this.getAngleCode;

            if (this.vehicleCoordinates) {
                if (this.vehicleCoordinates[angles[this.selectedSide]]) {
                    const screenWidth = window.innerWidth;
                    const screenHeight = window.innerHeight;
                    const vehicleImageHeigth= 1250;
                    const vehicleImageWidth= 2400;

                    const wheelPosition = this.vehicleCoordinates[angles[this.selectedSide]];
                    const wheelWidth = (wheelPosition[0][2] - wheelPosition[0][0]) * 100;
                    const wheelHeight = (wheelPosition[0][3] - wheelPosition[0][1]) * 100;

                    const containerHeight = screenHeight * 0.76 - 0.01;
                    const containerWidth = screenWidth * 0.86 - 0.01;
                    
                    const wheelAspectRatio = this.$store.getters.device.isLandscape && this.getAspectRatio() > 1.3 ? 0.4:0.5;

                    const imageRatio = vehicleImageWidth/containerWidth * wheelAspectRatio;
                    const imageWidth = imageRatio * containerWidth;
                    const imageHeight = imageWidth * vehicleImageHeigth/vehicleImageWidth;
                    
                    const leftPosition = -(imageWidth * wheelPosition[0][0]) + containerWidth/2 - (imageWidth*(wheelWidth/100)/2);
                    const topPosition = -(imageHeight * wheelPosition[0][1]) + containerHeight/2 - (imageHeight*(wheelHeight/100))/1.6;

                    return `width: ${imageWidth}px;left: ${leftPosition}px;top: ${topPosition}px;`;
                }
            }

            return '';
        },
        close: function () {
            this.showZoomImage = false;
            this.$emit('close', false);
        },
        getWheelImages: function (wheel) {
            if (wheel) {
                return {
                    imgBaseUrl: this.wheelImgUrlBase || null,
                    images: wheel || null
                };
            }
            return {};
        },
        getWheelValidation: function (side) {
            return this.vehicleAnglesImgCodes[this.getAngleCode[side]].filter(angle => this.wheelImages.images[`Img${angle}`] ? true:false).length >= 2;
        },
        selectedOption: function (val) {
            this.image = this.getVehicleImages[val];
            this.selectedSide = this.getVehicleViewKeys[val];
            this.currentSelectedOption = val;
            this.moveToNext = false;
            this.moveToPrevious = false;
        },
        disallowOption: function (val) {
            this.disallowOptionPrevious = val.previous;
            this.disallowOptionNext = val.next;
        },
        touchStart: function (touchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            const posYStart = touchEvent.changedTouches[0].clientY;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart, posYStart), {once: true});
        },
        touchEnd: function (touchEvent, posXStart, posYStart) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            const posYEnd = touchEvent.changedTouches[0].clientY;

            if (posXStart < posXEnd && Math.abs(posXStart - posXEnd) > 20 && Math.abs(posYStart - posYEnd) < 20) {
                if (this.disallowOptionPrevious) {
                    this.moveToNext = true; // swipe left
                } else {
                    this.moveToPrevious = true; // swipe right
                }
            } else if (posXStart > posXEnd) {
                if (this.disallowOptionNext) {
                    this.moveToPrevious = true; // swipe right
                } else {
                    this.moveToNext = true; // swipe left
                }
            }
        }
    },
    watch: {
        open: function (newVal) {
            this.showZoomImage = newVal;
            this.currentSelectedOption = this.getVehicleViewKeys[this.vehicleSide];
        },
        wheel: async function (newVal) {
            this.wheelImages = this.getWheelImages(newVal);
        },
        vehicleSide: function (newVal) {
            if (newVal == 'front' || newVal == 'side' || newVal == 'back') {
                this.image = this.vehicleImages[newVal];
                this.selectedSide = newVal;
                this.currentSelectedOption = this.getVehicleViewKeys[newVal];
            }
        }
    },
    beforeMount: function () {
        this.currentSelectedOption = this.getVehicleViewKeys[this.vehicleSide];
        this.imageDescription = `${this.vehicle.Year} ${this.vehicle.Make}, ${this.vehicle.Model}, ${this.vehicle.Submodel}`;
    }
}
</script>

<style scoped lang="scss">
.zoom-container {
    position: relative;
    width: 96vw;
    height: 86vh;
    max-height: calc(96vw * 1250/2400);
    max-width: 96vw;
    cursor: auto;

    .carrousel-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 0;

        &.rotate {
            top: unset;
            left: -100px;
            transform-origin: 50% 50%;
            transform: rotate(90deg);
        }
    }

    .carrousel-arrows-container {
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 100%;
        padding: 0 20px;

        &.rotate {
            width: 74vh;
            top: unset;
            left: -45%;
            transform-origin: 50% 50%;
            transform: rotate(90deg);
        }
    }

    .client-logo-container {
        position: absolute;
        padding: 0 0;
        top: 0;
        left: 0;
        height: 80px;

        &.small,
        &.mobile {
            background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(157,157,157,.8) 70%, rgba(194,194,194,.6) 86%, rgba(224,224,224,.1) 94%, rgba(241,241,241,0) 100%);
        }
        
        &.small {
            height: 40px;
        }

        &.mobile {
            height: 60px;
        }
    }

    .share-container {
        position: absolute;
        padding: 0 0;
        bottom: 90px;
        right: 0;
        height: 80px;
    }

    .info-container {
        position: absolute;
        padding: 0 0;
        bottom: 0%;
        left: 0;
        height: 90px;
        background-color: rgba(white, .6803);

        .wheel-thumbnail {
            max-height: 90px;
            max-width: 90px;
        }

        .vehicle-make-logo {
            max-height: 65px;
            max-width: 65px;
        }

        &.rotate {
            top: unset;
            left: -100px;
            transform-origin: 50% 50%;
            transform: rotate(90deg);
        }

        &.small {
            height: 40px;
            font-size: 60%;
            line-height: 1;

            .wheel-thumbnail {
                max-height: 40px;
                max-width: 40px;
            }

            .vehicle-make-logo {
                max-height: 40px;
                max-width: 40px;
            }
        }

        &.mobile {
            height: 110px;
            font-size: 82%;
            line-height: 1.15;

            .wheel-thumbnail {
                max-height: 50px;
                max-width: 50px;
            }

            .vehicle-make-logo {
                max-height: 45px;
                max-width: 45px;
            }
        }
    }

    &.zoom-mobile {
        height: 76vh;
        width: calc(76vh * 1250/2400);
        max-height: 76vh;
    }

    .zoom-wheel-focus {
        position: absolute;
        z-index: 0;
    }

    .zoom-content {
        max-height: 86vh;
    }

    .overflow-container {
        max-height: 86vh;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &.zoom-mobile {
        &,
        .zoom-content {
            max-height: 86vh;
        }
    }
}

.zoom-background {
    background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-desktop.png'), url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-desktop.svg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 576px) {
    .zoom-container.zoom-background {
        background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-mobile.png'), url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-mobile.svg');
    }
}
</style>
