const colorTransform = function (color) {
    const specialColors = {
        Gold: '#B08D57',
        Chrome: '#DBE2E9',
        Bronze: '#A97142',
        Polished: '#F6F6F6',
        DarkChrome: '#B6B7B8'
    };

    color = color.replace(/\s/g, "");

    if (color in specialColors) {
        return specialColors[color];
    }

    let ctx = document.createElement("canvas").getContext("2d");
	ctx.fillStyle = color;
	return ctx.fillStyle;
}

export default colorTransform;