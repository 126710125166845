<template>
    <div
        v-if="wheelSelected"
        :class="[
            'wheel-visualizer-container shadow-sm',
            $store.getters.device.isLandscape ? 'landscape':''
        ]"
    >
        <div
            :class="[
                'wheel-brand',
                'my-2 mx-2 mx-sm-2 mx-md-4 ps-md-2',
                !vehicle && wheelSelected && 'd-xl-none'
            ]"
        >
            <img :src="getBrandLogo" :alt="wheelSelected.Brand">
            <div class="d-none d-sm-inline">
                <small class="fw-medium px-3">{{wheelSelected.Brand}}</small>
                <small class="fw-medium">|</small>
                <small class="fw-medium px-3">{{wheelSelected.Model}}</small>
            </div>
        </div>

        <div 
            :class="[
                'd-flex d-sm-block',
                'specifications-button',
                'my-1 my-sm-0 mx-2 mx-lg-4 ps-md-4 ps-lg-2',
                !vehicle && wheelSelected && 'd-xl-none'
            ]"
        >
            <div
                class="d-flex justify-content-center align-items-center rounded px-3 py-1 py-sm-2 mt-sm-3 bg-brand-primary text-white cursor-pointer toggle-active user-select-none"
                @click.stop="toggleSpecifications"
            >
                <small v-if="!vehicle" class="me-2 me-sm-3 fw-medium" style="letter-spacing: 1px">
                    <small class="d-sm-none">INFO / BUY</small>
                    <span class="d-none d-sm-inline">INFO / BUY</span>
                </small>
                <small v-else class="me-2 me-sm-3 fw-medium" style="letter-spacing: 1px">
                    <small class="d-sm-none">SPECIFICATIONS</small>
                    <span class="d-none d-sm-inline">SPECIFICATIONS</span>
                </small>
                <Icon v-if="showSpecifications" icon-name="navArrowUp"/>
                <Icon v-else icon-name="navArrowDown"/>
            </div>
        </div>

        
        <div v-if="getVehicleMakeLogo" class="d-none d-xl-flex justify-content-end align-items-center vehicle-info my-2 mx-2 mx-sm-4">
            <small v-if="vehicleYear" class="px-1 fw-medium">{{vehicleYear}}</small>
            <small v-if="vehicleMake" class="ps-1 pe-3 fw-medium">{{vehicleMake}}</small>
            <small v-if="vehicleModel" class="fw-medium">|</small>
            <small v-if="vehicleModel" class="px-3 fw-medium">{{vehicleModel}}</small>
            <small class="fw-medium">{{vehicleSubmodel}}</small>
            <img :src="getVehicleMakeLogo" alt="" class="vehicle-make-logo ms-3">
        </div>
       

        <div class="container-fluid h-100 m-0 p-0">
            <div class="row justify-content-center align-items-strech h-100 mx-2 mx-md-4" style="z-index: -1">
                <div 
                    :class="[
                        'col-12',
                        vehicle ? 'col-lg-7':'col-lg-8',
                        'h-100',
                        'd-flex justify-content-center justify-content-md-between align-items-center',
                        'px-sm-5 px-xl-4',
                        'wheel-view'
                    ]"
                >
                    <div
                        :class="[
                            'd-flex justify-content-center position-relative',
                            faceSelected ? 'align-items-end':'align-items-center',
                            faceSelected ? 'wheel-view-selected':'wheel-view-unselected',
                            getViewOrder.face, 
                        ]"
                    >
                        <img
                            v-if="getUrlImg0003"
                            alt=""
                            class="user-select-none"
                            :src="getUrlImg0003"
                            @click="viewSelected('face', true)"
                            @touchstart="touchStart"
                        >
                    </div>
                    <div
                        :class="[
                            'd-flex justify-content-center position-relative',
                            standarSelected ? 'align-items-end':'align-items-center',
                            standarSelected ? 'wheel-view-selected':'wheel-view-unselected',
                            getViewOrder.standar, 
                        ]"
                    >
                        <img
                            v-if="getUrlImg0001"
                            alt=""
                            class="user-select-none"
                            :src="getUrlImg0001"
                            @click="viewSelected('standar', true)"
                            @touchstart="touchStart"
                        >
                    </div>
                    <div
                        :class="[
                            'd-flex justify-content-center position-relative',
                            beautySelected ? 'align-items-end':'align-items-center',
                            beautySelected ? 'wheel-view-selected':'wheel-view-unselected',
                            getViewOrder.beauty, 
                        ]"
                    >
                        <img
                            v-if="getUrlImg0002"
                            alt=""
                            class="user-select-none"
                            :src="getUrlImg0002"
                            @click="viewSelected('beauty', true)"
                            @touchstart="touchStart"
                        >
                    </div>

                    <div
                        v-if="getUrlImg0002 || getUrlImg0003"
                        class="d-flex carrousel-arrow-controller ps-md-2"
                        style="left: 0"
                    >
                        <span 
                            class="carrousel-arrow-option toggle-active cursor-pointer user-select-none p-1 rounded"
                            @click.stop="moveView('previous')"
                        >
                            <Icon icon-name="navArrowLeft"/>
                        </span>
                    </div>
                    
                    <div
                        v-if="getUrlImg0002 || getUrlImg0003"
                        class="d-flex carrousel-arrow-controller pe-md-2"
                        style="right: 0"
                    >
                        <span
                            class="carrousel-arrow-option toggle-active cursor-pointer user-select-none p-1 rounded"
                            @click.stop="moveView('next')"
                        >
                            <Icon icon-name="navArrowRight"/>
                        </span>
                    </div>                
                </div>
                
                <div
                    v-if="vehicleLargeImage && vehicleCoordinates && wheelImages"
                    class="d-none d-xl-block col-12 col-lg-5 position-relative"
                >
                    <div
                        :class="[
                            'd-flex justify-content-center align-items-center',
                            'vehicle-view'
                        ]"
                    >
                        <VehicleImageSmothSwap
                            selected
                            view-side="front"
                            :vehicle-large-image="vehicleLargeImage"
                            :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
                            :coordinates="vehicleCoordinates"
                            :angles-img-codes="vehicleAnglesImgCodes"
                            :wheel-diameter="wheelDiameter"
                            :wheel-images="wheelImages"
                            clicklable
                            @view-selected="close"
                        />
                    </div>
                </div>
                <div
                    v-else
                    class="d-none d-xl-block col-12 col-lg-4 position-relative"
                >
                    <div class="d-flex flex-column justify-content-center align-items-start h-100 ps-5 wheel-short-specs">
                        <div class="mb-3 pb-3 border-bottom w-50">
                            <img :src="getBrandLogo" :alt="wheelSelected.Brand" class="wheel-brand-logo">
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-start">
                            <div class="line-height-small">
                                <h1 class="fw-bold m-0 p-0" style="font-size: 2.5em">{{wheelSelected.Model}}</h1>
                            </div>
                            <div class="fw-regular text-brand-muted line-height-small" style="font-size: 86%">
                                <small v-if="wheelSelected.Color">{{wheelSelected.Color}}</small> <small v-if="wheelSelected.Finish && wheelSelected.Color != wheelSelected.Finish">{{wheelSelected.Finish}}</small>
                                <small v-if="(wheelSelected.Color || wheelSelected.Finish) && wheelSelected.Accent"> / </small> <small v-if="wheelSelected.Accent">{{wheelSelected.Accent}}</small>
                            </div>
                        </div>
                        <div
                            v-if="wheelSelected.Diameters"
                            :class="[
                                wheelSelected.Diameters.length > 5 ? 'mt-1 mt-xxl-3 pt-1 pt-xxl-2':'mt-3 pt-2 w-100'
                            ]"
                        >
                            <div class="d-flex flex-wrap justify-content-start align-items-center p-0 m-0">
                                <div
                                    v-for="(diameter, index) in wheelSelected.Diameters"
                                    :key="index"
                                    :class="[
                                        'me-3',
                                        'border border-2 rounded',
                                        'p-2 my-1',
                                        'fw-medium',
                                        'diameter-card',
                                        diameter == wheelSelected.Diameter && 'selected'
                                    ]"
                                >
                                    {{diameter}}"
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div
                                class="d-none d-xxl-block mb-3 text-center text-brand-primary border border-color-brand-primary rounded cursor-pointer toggle-active user-select-none text-nowrap px-4 py-1"
                                style="letter-spacing: 1px"
                                @click.stop="viewOnVehicle()"
                            >
                                <small class="title-section-spacing fw-medium">SEE ON YOUR VEHICLE</small>
                            </div>

                            <div
                                class="rounded px-3 py-2 bg-brand-primary text-white cursor-pointer toggle-active user-select-none"
                                @click.stop="toggleSpecifications"
                            >
                                <div class="d-flex justify-content-center align-items-center">
                                    <small class="d-none d-sm-inline me-3 fw-medium" style="letter-spacing: 1px">INFO / BUY</small>
                                    <Icon v-if="showSpecifications" icon-name="navArrowUp"/>
                                    <Icon v-else icon-name="navArrowDown"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <slot/>
    </div>
    
    <div ref="specifications">
        <WheelSpecifications
            v-if="showSpecifications"
            :show-vehicle-banner="!vehicle"
            :wheel="wheelSelected"
            :wheel-img-url-base="wheelImgUrlBase"
            :wheel-brand-logo="getBrandLogo"
            :wheel-configurations="wheelConfigurationsFiltered"
            :wheels-options="wheelsOptions"
            :wheels-pagination="wheelsPagination"
            @change-wheel-configuration="changeWheelConfiguration"
            @change-wheel-part-number="changeWheelPartNumber"
            @request-quote="requestQuote"
        />
    </div>
</template>

<script>
import VehicleImageSmothSwap from '@/components/builder/VehicleImageSmothSwap.vue';
import WheelSpecifications from '@/components/builder/WheelSpecifications.vue';
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'WheelDetails',
    components: {
        VehicleImageSmothSwap,
        WheelSpecifications,
        Icon
    },
    emits: [
        'showSpecifications',
        'requestQuote',
        'zoomImageOn',
        'changeWheelConfiguration',
        'close',
        'viewSelected'
    ],
    data: function () {
        return {
            vehicleYear: null,
            vehicleMake: null,
            vehicleModel: null,
            vehicleSubmodel: null,
            showSpecifications: false,
            beautySelected: false,
            faceSelected: false,
            standarSelected: true,
            disallowOptionPrevious: false,
            disallowOptionNext: false,
            wheelSelected: null,
            wheelConfigurationsFiltered: null,
            wheelsOptions: null,
            wheelImages: null,
            showMessageCopy: false
        }
    },
    props: {
        wheel: {
            type: Object,
            required: true
        },
        wheelConfigurations: {
            type: Object,
            required: true
        },
        wheelImgUrlBase: {
            type: String,
            required: true
        },
        wheelsPagination: {
            type: Object,
            required: true
        },
        vehicleLargeImage: {
            type: String,
            required: false
        },
        vehicle: {
            type: Object,
            required: false
        },
        vehicleCoordinates: {
            type: Object,
            required: false
        },
        vehicleAnglesImgCodes: {
            type: Object,
            required: false
        },
        vehicleImageWheelDiameter : {
            type: Object,
            required: false
        },
        wheelDiameter : {
            type:  Number,
            required: false
        },
        hideSpecifications: {
            type: Boolean,
            required: false
        },
        startWithSpecifications: {
            type: Boolean,
            required: false
        },
        showExtraFilters: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        getBrandLogo: function () {
            if (this.wheelSelected) {
                return `${this.$store.getters.wheelsBrandsUrlBase}${this.$store.getters.wheelsBrands[this.wheelSelected.Brand]}`;
            }
            return '';
        },
        getUrlImg0001: function () {
            if (this.wheelImgUrlBase && this.wheelSelected.Img0001) {
                return `${this.wheelImgUrlBase}${this.wheelSelected.Img0001}`;
            }
            return '';
        },
        getUrlImg0002: function () {
            if (this.wheelImgUrlBase && this.wheelSelected.Img0002) {
                return `${this.wheelImgUrlBase}${this.wheelSelected.Img0002}`;
            }
            return '';
        },
        getUrlImg0003: function () {
            if (this.wheelImgUrlBase && this.wheelSelected.Img0003) {
                return `${this.wheelImgUrlBase}${this.wheelSelected.Img0003}`;
            }
            return '';
        },
        getViewOrder: function () {
            if (this.beautySelected) {
                return {
                    face: 'order-0',
                    standar: 'order-2',
                    beauty: 'order-1'
                };
            }

            if (this.standarSelected) {
                return {
                    face: 'order-2',
                    standar: 'order-1',
                    beauty: 'order-0'
                };
            }
            
            return {
                face: 'order-1',
                standar: 'order-0',
                beauty: 'order-2'
            };
        },
        getSelectedView: function () {
            if (this.faceSelected) {
                return 'face';
            }

            if (this.standarSelected) {
                return 'standar';
            }

            if (this.beautySelected) {
                return 'beauty';
            }

            return null;
        },
        getVehicleMakeLogo: function () {
            if (this.vehicleMake) {
                const makes = this.$store.getters.vehiclesMakes.makes;
                const makeBaseUrl = this.$store.getters.vehiclesMakes.baseUrl;
                const makeId = this.vehicleMake;
                const filterMake = (make) => make.Make === makeId;
                const make = makes.filter(filterMake);

                if (make && make.length > 0) {
                    return `${makeBaseUrl}${make[0].Logo}`;
                }
                return '';
            }
            return '';
        }
    },
    methods: {
        viewSelected: function(view, click) {
            let emit = false;
            if (view === 'standar' && this.standarSelected) {
                emit = true;
            }
            if (view === 'face' && this.faceSelected) {
                emit = true;
            }
            if (view === 'beauty' && this.beautySelected) {
                emit = true;
            }

            if (emit && click) {
                this.$emit('zoomImageOn', view);
            }

            switch (view) {
                case 'standar':
                    this.standarSelected = true;
                    this.faceSelected = false;
                    this.beautySelected = false;
                    break;
                    
                case 'face':
                    this.standarSelected = false;
                    this.faceSelected = true;
                    this.beautySelected = false;
                    break;
                    
                case 'beauty':
                    this.standarSelected = false;
                    this.faceSelected = false;
                    this.beautySelected = true;
                    break;
            
                default:
                    break;
            }
                        
            this.getCarrouselOptionState();
            this.$emit('viewSelected', view);
        },
        moveView: function (side) {
            if (this.disallowOptionPrevious && side === 'previous') {
                side = 'next';
            }

            if (this.disallowOptionNext && side === 'next') {
                side = 'previous';
            }
            
            switch (side) {
                case 'previous':
                    if (this.standarSelected) {
                        this.viewSelected('beauty');
                    } else if (this.faceSelected) {
                        this.viewSelected('standar');
                    } else if (this.beautySelected) {
                        this.viewSelected('face');
                    }
                    break;

                case 'next':
                    if (this.standarSelected) {
                        this.viewSelected('face');
                    } else if (this.faceSelected) {
                        this.viewSelected('beauty');
                    } else if (this.beautySelected) {
                        this.viewSelected('standar');
                    }
                    break;
            
                default:
                    break;
            }
        },
        getOptionPreviousState: function () {
            let val = true;
            if (this.getSelectedView == 'standar' && this.getUrlImg0002) {
                val = false;
            }
            if (this.getSelectedView == 'beauty' && this.getUrlImg0003) {
                val = false;
            }
            if (this.getSelectedView == 'face' && this.getUrlImg0001) {
                val = false;
            }
            this.disallowOptionPrevious = val;
        },
        getOptionNextState: function () {
            let val = true;

            if (this.getSelectedView === 'standar' && this.getUrlImg0003) {
                val = false;
            }
            if (this.getSelectedView === 'beauty' && this.getUrlImg0001) {
                val = false;
            }
            if (this.getSelectedView === 'face' && this.getUrlImg0002) {
                val = false;
            }
            this.disallowOptionNext = val;
        },
        getCarrouselOptionState: function () {
            this.getOptionPreviousState();
            this.getOptionNextState();
        },
        selectedViewByImagesLoaded: function () {
            if (this.getSelectedView === 'standar' && this.getUrlImg0001) {
                this.viewSelected('standar');
            } else if (this.getSelectedView === 'face' && this.getUrlImg0003) {
                this.viewSelected('face');
            } else if (this.getSelectedView === 'beauty' && this.getUrlImg0002) {
                this.viewSelected('beauty');
            } else{
                if (this.getUrlImg0001 && this.getUrlImg0001 != '') {
                    this.viewSelected('standar');
                } else if (this.getUrlImg0003 &&  this.getUrlImg0003 != '') {
                    this.viewSelected('face');
                } else if (this.getUrlImg0002 &&  this.getUrlImg0002 != '') {
                    this.viewSelected('beauty');
                }
            }
        },
        touchStart: function (touchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
        },
        touchEnd: function (touchEvent, posXStart) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            if (posXStart < posXEnd && !this.disallowOptionPrevious) {
                this.moveView('previous'); // swipe right
            } else if (posXStart > posXEnd && !this.disallowOptionNext) {
                this.moveView('next'); // swipe left
            }
        },
        getWheelsOptions: function () {
            let configurations = {
                'Pn': [],
                'Diameter': [],
                'Width': [],
                'Offset': [],
                'Bore': [],
                'ExposedLugs': [],
                'ShortColor': [],
                'Finish': [],
                'LugCount': [],
                'BoltCircle1': []
            };

            this.wheelConfigurations.forEach(wheel => {
                for (const key in configurations) {
                    if (Object.hasOwnProperty.call(wheel, key) && Object.keys(configurations).includes(key)) {
                        if (!['LugCount', 'BoltCircle1'].includes(key) || this.showExtraFilters) {
                            const val = wheel[key];
                            this.evaluateConfiguration(configurations, key, val);
                        }
                    }
                }
            });

            for (const key in configurations) {
                configurations[key].sort(function(a, b) {
                    return a - b;
                });
            }

            return configurations;
        },
        getWheelConfigurations: function () {
            let configurations = {
                'Pn': [],
                'Diameter': [],
                'Width': [],
                'Offset': [],
                'Bore': [],
                'ExposedLugs': [],
                'ShortColor': [],
                'Finish': []
            };

            if (this.showExtraFilters) {
                configurations['LugCount'] = [];
                configurations['BoltCircle1'] = [];
            }

            this.wheelConfigurations.forEach(wheel => {
                for (const key in configurations) {
                    if (Object.hasOwnProperty.call(wheel, key) && Object.keys(configurations).includes(key)) {
                        const val = wheel[key];
                        if (key === 'Pn' || key === 'Diameter') {
                            this.evaluateConfiguration(configurations, key, val);
                        } else {
                            if (key === 'Width') {
                                if (this.wheelSelected.Diameter == wheel.Diameter) {
                                    this.evaluateConfiguration(configurations, key, val);
                                }
                            }

                            if (key === 'LugCount' && this.showExtraFilters) {
                                if (
                                    this.wheelSelected.Diameter == wheel.Diameter &&
                                    this.wheelSelected.Width == wheel.Width
                                ) {
                                    this.evaluateConfiguration(configurations, key, val);   
                                }
                            }

                            if (key === 'BoltCircle1' && this.showExtraFilters) {
                                if (
                                    this.wheelSelected.Diameter == wheel.Diameter &&
                                    this.wheelSelected.Width == wheel.Width &&
                                    this.wheelSelected.LugCount == wheel.LugCount
                                ) {
                                    this.evaluateConfiguration(configurations, key, val);   
                                }
                            }

                            if (key === 'Offset') {
                                if (
                                    this.wheelSelected.Diameter == wheel.Diameter &&
                                    this.wheelSelected.Width == wheel.Width &&
                                    (this.wheelSelected.LugCount == wheel.LugCount || !this.showExtraFilters) &&
                                    (this.wheelSelected.BoltCircle1 == wheel.BoltCircle1 || !this.showExtraFilters)
                                ) {
                                    this.evaluateConfiguration(configurations, key, val);   
                                }
                            }

                            if (key === 'Bore') {
                                if (
                                    this.wheelSelected.Diameter == wheel.Diameter &&
                                    this.wheelSelected.Width == wheel.Width &&
                                    this.wheelSelected.Offset == wheel.Offset &&
                                    (this.wheelSelected.LugCount == wheel.LugCount || !this.showExtraFilters) &&
                                    (this.wheelSelected.BoltCircle1 == wheel.BoltCircle1 || !this.showExtraFilters)
                                ) {
                                    this.evaluateConfiguration(configurations, key, val);
                                }
                            }

                            if (key === 'ExposedLugs') {
                                if (
                                    this.wheelSelected.Diameter == wheel.Diameter &&
                                    this.wheelSelected.Width == wheel.Width &&
                                    this.wheelSelected.Offset == wheel.Offset &&
                                    (this.wheelSelected.LugCount == wheel.LugCount || !this.showExtraFilters) &&
                                    (this.wheelSelected.BoltCircle1 == wheel.BoltCircle1 || !this.showExtraFilters) &&
                                    this.wheelSelected.Bore == wheel.Bore
                                ) {
                                    this.evaluateConfiguration(configurations, key, val);
                                }
                            }

                            if (key === 'ShortColor') {
                                if (
                                    this.wheelSelected.Diameter == wheel.Diameter &&
                                    this.wheelSelected.Width == wheel.Width &&
                                    this.wheelSelected.Offset == wheel.Offset &&
                                    (this.wheelSelected.LugCount == wheel.LugCount || !this.showExtraFilters) &&
                                    (this.wheelSelected.BoltCircle1 == wheel.BoltCircle1 || !this.showExtraFilters) &&
                                    this.wheelSelected.Bore == wheel.Bore &&
                                    this.wheelSelected.ExposedLugs == wheel.ExposedLugs
                                ) {
                                    this.evaluateConfiguration(configurations, key, val);
                                }
                            }

                            if (key === 'Finish') {
                                if (
                                    this.wheelSelected.Diameter == wheel.Diameter &&
                                    this.wheelSelected.Width == wheel.Width &&
                                    this.wheelSelected.Offset == wheel.Offset &&
                                    (this.wheelSelected.LugCount == wheel.LugCount || !this.showExtraFilters) &&
                                    (this.wheelSelected.BoltCircle1 == wheel.BoltCircle1 || !this.showExtraFilters) &&
                                    this.wheelSelected.Bore == wheel.Bore &&
                                    this.wheelSelected.ExposedLugs == wheel.ExposedLugs &&
                                    this.wheelSelected.ShortColor == wheel.ShortColor
                                ) {
                                    this.evaluateConfiguration(configurations, key, val);
                                }
                            }
                        }
                    }
                }
            });
            return configurations;
        },
        evaluateConfiguration: function (obj, key, val) {
            const item = !val ? 'N/A':val;
            if (item !== 'N/A' && !obj[key].includes(item)) obj[key].push(item);
        },
        changeWheelPartNumber: function (val) {
            let wheelTmp = null;
            this.wheelConfigurations.forEach(wheel => {
                if (wheel['Pn'] === val && !wheelTmp) {
                    wheelTmp = wheel;
                }
            });

            this.wheelSelected = {...this.wheelSelected, ...wheelTmp};
            this.wheelsOptions = this.getWheelsOptions();
            this.wheelConfigurationsFiltered = this.getWheelConfigurations();
            this.wheelImages = this.getWheelImages(this.wheelSelected);
            this.selectedViewByImagesLoaded();
            this.$emit('changeWheelConfiguration', this.wheelSelected);
        },
        changeWheelConfiguration: function (val) {
            let score = 0;
            let wheelTmp = null;
            const califications = {
                Diameter: this.showExtraFilters ? 9:7,
                Width: this.showExtraFilters ? 8:6,
                LugCount: 7,
                BoltCircle1: 6,
                Offset: 5,
                Bore: 4,
                ExposedLugs: 3,
                ShortColor: 2,
                Finish: 1,
            };
            
            this.wheelConfigurations.forEach(wheel => {
                let wheelScore = 0;

                for (const calification in califications) {
                    if (wheel[calification]) {
                        if (val.option === calification) {
                            wheelScore += val.value == wheel[calification] ? califications[calification]:0;
                        } else {
                            wheelScore += this.wheelSelected[calification] == wheel[calification] ? califications[calification]:0;
                        }
                    }
                }

                if (!wheelTmp) {
                    score = wheelScore;
                    wheelTmp = wheel;
                } else if (wheelScore > score && (wheelTmp[val.option] != val.value || wheel[val.option] == val.value)) {
                    score = wheelScore;
                    wheelTmp = wheel;
                } else if(wheelTmp[val.option] != val.value && wheel[val.option] == val.value) {
                    score = wheelScore;
                    wheelTmp = wheel;
                }
            });

            if (wheelTmp) {
                this.wheelSelected = {...this.wheelSelected, ...wheelTmp};
                this.wheelsOptions = this.getWheelsOptions();
                this.wheelConfigurationsFiltered = this.getWheelConfigurations();
                this.wheelImages = this.getWheelImages(this.wheelSelected);
                this.selectedViewByImagesLoaded();
                
                this.$emit('changeWheelConfiguration', this.wheelSelected);
            }
        },
        getWheelImages: function (wheel) {
            if (wheel) {
                return {
                    imgBaseUrl: this.wheelImgUrlBase || null,
                    images: wheel || null
                };
            }
            return {};
        },
        viewOnVehicle: function () {
            this.$store.commit('vehicleBuilding', {
                wheel: this.wheel,
                wheelSelectedWheelVisualizer: true,
                wheelPage: {
                    number: this.wheelsPagination.current,
                    moreItems: this.wheelsPagination.moreItems
                }
            });
            this.$router.push('/vehicles')
        },
        requestQuote: function () {
            this.$emit('requestQuote', this.wheelSelected);
        },
        toggleSpecifications: function () {
            this.showSpecifications = !this.showSpecifications;
            this.$emit('showSpecifications', this.showSpecifications);
        },
        close: function () {
            this.$emit('close');
        }
    },
    watch: {
        wheel: function (newVal) {
            if (newVal) {
                this.wheelSelected = newVal;
                this.wheelsOptions = this.getWheelsOptions();
                this.wheelConfigurationsFiltered = this.getWheelConfigurations();
                this.wheelImages = this.getWheelImages(newVal);
                this.selectedViewByImagesLoaded();
            }
        },
        hideSpecifications: function () {
            this.showSpecifications = false;
            this.$emit('showSpecifications', this.showSpecifications);
        }
    },
    beforeMount: function () {
        if (this.vehicle) {
            this.vehicleYear = this.vehicle.Year;
            this.vehicleMake = this.vehicle.Make;
            this.vehicleModel = this.vehicle.Model;
            this.vehicleSubmodel = this.vehicle.Submodel;
        }

        this.wheelSelected = this.wheel;
        this.wheelsOptions = this.getWheelsOptions();
        this.wheelConfigurationsFiltered = this.getWheelConfigurations();
        this.wheelImages = this.getWheelImages(this.wheel);
        this.selectedViewByImagesLoaded();
        
        this.$emit('showSpecifications', this.showSpecifications);

        if (this.startWithSpecifications && !this.showSpecifications) {
            this.toggleSpecifications();
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/exportable.scss";

.wheel-visualizer-container {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-desktop.png');
    position: relative;
    height: calc(100vw * (493/1920));
    max-height: 493px;
    width: 100%;
    z-index: 0;

    .wheel-brand {
        position: absolute;
        bottom: 0;
        left: 0;

        img {
            max-width: 80px;
            max-height: 3rem;
        }
    }

    .wheel-brand-logo {
        max-height: 40px;
    }

    .wheel-short-specs {
        span {
            font-size: 92%;
        }
    }

    .specifications-button {
        position: absolute;
        bottom: 4rem;
        left: 0;
        z-index: 1;
    }

    .vehicle-info {
        .vehicle-make-logo {
            max-height: 38px;
            max-width: 38px;
        }

        position: absolute;
        bottom: 0;
        right: .5rem;
    }

    .wheel-view {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-desktop.svg');
        position: relative;

        animation: fade-in-image;
        animation-delay: .3s;
        animation-duration: .3s;
        animation-fill-mode: forwards;
        -webkit-animation: fade-in-image;
        -webkit-animation-delay: .3s;
        -webkit-animation-duration: .3s;
        -webkit-animation-fill-mode: forwards;
        display: none;
        opacity: 0;

        &#{-selected}, &#{-unselected} {
            transition: .3s;

            img {
                cursor: pointer;
            }
        }

        &#{-selected} {
            opacity: 1;
            width: 50%;

            img {
                width: 70%;
            }
        }

        &#{-unselected} {
            opacity: .61803;
            width: 25%;

            img {
                width: 70%;
            }
        }
    }

    .vehicle-view {
        position: absolute;
        top: 0;
        left: 20%;
        width: 130%;
    }

    .line-height-small {
        line-height: 1.2;
    }

    .diameter-card {
        min-width: 40px;
        height: 40px;
        font-size: .9em;
        text-align: center;
        
        &.selected {
            border-color: map-get($theme-color, "text") !important;
            color: map-get($theme-color, "text") !important;
        }
    }

    .carrousel {
        &#{-arrow-controller} {
            display: block;
            position: absolute;
            z-index: 4;
        }

        &#{-arrow-option} {
            background-color: rgba($color: white, $alpha: .3);
            color: rgba($color: black, $alpha: .5);
            font-size: 1.803rem;

            &:hover {
                color: rgba($color: black, $alpha: .61803);
            }

            &.carrousel-arrow-option-disabled {
                background-color: rgba($color: white, $alpha: .05);
                color: rgba($color: black, $alpha: .1);
                cursor: auto;
            }
        }
    }
}

@media only screen and (min-width: 577px) and (max-width: 1199px) {
    .wheel-visualizer-container {
        height: calc(100vw * (320/376));

        .wheel-view {
            &#{-selected} {
                width: 60%;

                img {
                    width: 80%;
                }
            }

            &#{-unselected} {
                width: 20%;

                img {
                    width: 80%;
                }
            }
        }
    
        &.landscape {
            height: 100vh;

            .wheel-view {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                &#{-selected} {
                    width: calc(76.394% * 0.86);
                }

                &#{-unselected} {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .wheel-visualizer-container {
        height: calc(100vw * (320/376));
        background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-mobile.png');
    
        .wheel-view {
            background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-mobile.svg');

            &#{-selected} {
                width: 86%;
                margin-top: .5em;
                
                img {
                    width: 75%;
                }
            }

            &#{-unselected} {
                display: none !important;
            }
        }
    
        &.landscape {
            height: 100vh;

            .wheel-view {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                &#{-selected} {
                    width: calc(76.394% * 0.86);
                }

                &#{-unselected} {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .wheel-brand {
            img {
                max-width: 60px;
                max-height: 2.5rem;
            }
        }

        .specifications-button {
            position: absolute;
            bottom: .5rem;
            left: auto;
            right: 0;
        }
    }
}
</style>
