import axios from "axios";
import store from '../store';

var request = (async (path, data) => {
  let request = path.includes('?') ? path+'&':path+'?';
  data = data || {};

  return await axios.post(`${process.env.VUE_APP_ROOT_API}${request}key=${store.getters.key}`, data)
  .then( function(response){
    return {
      status: true,
      data: response.data
    }
  })
  .catch( function(error){
    // eslint-disable-next-line
    console.log(error);
    return {
      status: false,
      code: error.response ? error.response.status:408
    }
  });
});

var apiRequest = (async (path, data) => {
  if (path) {
    const response = await request(path, data);

    if (response.status) {
      return response.data;
    } else {
      setTimeout( async () => {
        const responseReTry = await request(path, data);
        
        if (responseReTry.status) {
          return response.data;
        } else {
          return {
            status: "NOT OK"
          };
        }
      }, 1000);
    }
  } else {
    // eslint-disable-next-line
    console.error('Request needs a valid path');
  }
});

export default apiRequest;
