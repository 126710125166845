<template>
    <div
        v-if="colors"
        :class="[
            'position-relative',
            !showColorPicker && 'd-none'
        ]"
    >
        <Overlay
            v-if="showColorPicker"
            center
            @close="close"
        >
            <div class="text-center">
                <h6 class="mb-3 fw-medium border-bottom py-2 mx-5">SELECT COLOR</h6>
                <div
                    :class="[
                        'd-flex flex-wrap justify-content-between align-items-stretch px-3 color-picker-options-container',
                        colors.length > 5 ? 'justify-content-md-start':'justify-content-md-center'
                    ]"
                >
                    <div
                        v-for="color in colors"
                        :key="color.Id"
                        :class="[
                            'color-picker-option',
                            'my-3 mx-0 mx-md-3',
                            'text-start',
                            colorCurrent != color.Id && 'toggle-active'
                        ]"
                        @click="colorCurrent != color.Id && changeCurrentColor(color.Id)"
                    >
                        <div
                            :class="[
                                'p-2',
                                colorCurrent == color.Id ? 'selected border border-1 rounded bg-brand-body-secondary':'cursor-pointer'
                            ]"
                        >
                            <div
                                v-if="!color.Rgb2"
                                class="color-picker-option-sample mx-auto border border-1 rounded shadow-sm w-100"
                                :style="getColorBrushed(color.Rgb1)"
                            ></div>
                            <div
                                v-else
                                class="color-picker-option-sample mx-auto border border-1 rounded shadow-sm w-100"
                                :style="getColorBrushed(color.Rgb1, color.Rgb2)"
                            ></div>
                            <div class="color-picker-option-name-container position-relative">
                                <div class="color-picker-option-name w-100">
                                    <p class="fw-medium my-1 user-select-none">{{color.Name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    </div>
</template>

<script>
import Overlay from '@/components/shared/Overlay.vue';
import colorBrushed from '@/utils/colorBrushed.js';

export default {
    name: "ColorPicker",
    components: {
        Overlay
    },
    emits: ['changeCurrentColor', "close"],
    data: function () {
        return {
            showColorPicker: false
        };
    },
    props: {
        colors: Object,
        colorCurrent: String,
        open: Boolean
    },
    watch: {
        open: function (newVal) {
            this.showColorPicker = newVal;
        }
    },
    methods: {
        close: function () {
            this.showColorPicker = false;
            this.$emit('close', false);
        },
        changeCurrentColor: function(color) {
            this.showColorPicker = false;
            this.$emit('changeCurrentColor', color);
        },
        getColorBrushed: function (primary, secondary) {
            return colorBrushed(primary, secondary);
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/exportable.scss";

.color-picker {
    &#{-options-container} {
        max-width: 602px;
    }

    &#{-option} {
        width: 45%;
        min-width: 90px;
        max-width: 110px;

        .selected.border {
            border-color: var(--client-color) !important;
        }
    }

    &#{-option-sample} {
        min-width: 60px;
        max-width: 110px;
        height: 67.9837px;
    }

    &#{-option-name-container}, &#{-option-name} {
        height: 17px;
        max-height: 48px;
        min-width: 90px;
        max-width: 110px;

        p {
            font-size: 9px;
            line-height: 1.3;
        }
    }

    &#{-option-name} {
        position: absolute;
        top: 0;
        left: 0;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

@media only screen and (max-width: 768px) {
    .color-picker {
        &#{-options-container} {
            max-width: 300px;
        }
    }
}
</style>
