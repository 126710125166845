<template>
    <div
        v-if="image"
        class="position-relative"
    >
        <Overlay
            v-if="showZoomImage"
            center
            remove-padding
            @close="close"
        >
            <div class="zoom-container-wheels">
                <div class="wheel-image-containter p-3 mx-auto">
                    <img
                        alt=""
                        :class="[
                            'user-select-none',
                            'w-100',
                            !loaded && 'filter-blur'
                        ]"
                        :src="image"
                        @load="wheelImageLoaded"
                        @touchstart="touchStart"
                    >
                </div>

                <div class="carrousel-arrows-container px-3">
                    <ImagesCarrouselController
                        v-if="getWheelImages.one || getWheelImages.three"
                        arrows
                        :images="getWheelImages"
                        :default-selected-option="currentSelectedOption"
                        :move-to-next="moveToNext"
                        :move-to-previous="moveToPrevious"
                        allow-circular-selection
                        @disallowOption="disallowOption"
                        @selected-option="selectedOption"
                    />
                </div>

                <div
                    v-if="getClientLogo"
                    :class="[
                        'client-logo-container',
                        $store.getters.device.isMobile && 'mobile',
                        'user-select-none',
                        'ms-3 ms-sm-4 mt-3',
                        'w-50'
                    ]"
                >
                    <div class="client-logo h-100 w-50">
                        <img :src="getClientLogo" alt="" class="h-100">
                    </div>
                </div>

                <div
                    class="info-container bg-brand-light-gray w-100 d-flex flex-column justify-content-between align-items-start px-2 py-2"
                    :style="getWindowWidth() > 450 && 'line-height: 1.314159265'"
                >                    
                    <div v-if="getBrandLogo" class="d-flex w-100 justify-content-between align-items-center rounded py-2">
                        <div
                            :class="[
                                'wheel-brand-logo',
                                getWindowWidth() < 450 ? 'small':'ms-3'
                            ]"
                        >
                            <img :src="getBrandLogo" alt="" class="w-100">
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-end">
                            <div class="text-end text-nowrap" :style="getWindowWidth() > 450 && 'font-size: 1.3rem'">
                                <small v-if="wheel.Brand" class="px-1 ps-4 fw-medium">{{wheel.Brand}}</small>
                                <small v-if="wheel.Model" class="fw-medium">|</small>
                                <small v-if="wheel.Model" class="px-1 fw-medium">{{wheel.Model}}</small>
                                <small v-if="wheel.Submodel" class="px-1 fw-medium">{{wheel.Submodel}}</small>
                            </div>

                            <div v-if="wheel.Pn" class="px-1 ps-4 text-muted text-end fw-medium py-1" :style="getWindowWidth() < 450 && 'font-size: 80%'">
                                <small>P/N: </small>
                                <small>{{wheel.Pn}}</small>
                            </div>

                            <div class="px-1 ps-4 text-muted text-end text-nowrap fw-regular" :style="getWindowWidth() < 450 && 'font-size: 80%'">
                                <ul class="d-flex flex-wrap justify-content-end align-items-center m-0 p-0">
                                    <li 
                                        v-if="wheel.Diameter && wheel.Width"
                                        class="ms-4"
                                        style="list-style-type: none"
                                    >
                                        <small>{{wheel.Diameter}}"x{{wheel.Width}}"</small>
                                    </li>
                                    <li class="ms-4">
                                        <small v-if="wheel.Finish">{{wheel.Finish}}</small>
                                        <small v-if="wheel.ShortColor && wheel.Finish != wheel.ShortColor" :class="wheel.Finish && wheel.ShortColor && 'ms-1'">{{wheel.ShortColor}}</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <ShareButton
                        :vehicle="vehicle"
                        :wheel="wheel"
                        big
                    />
                </div>
            </div>
        </Overlay>
    </div>
</template>

<script>
import Overlay from '@/components/shared/Overlay.vue';
import ImagesCarrouselController from '@/components/shared/ImagesCarrouselController.vue';
import ShareButton from '@/components/shared/ShareButton.vue';

export default {
    name: "ZoomWheelImages",
    components: {
        Overlay,
        ImagesCarrouselController,
        ShareButton
    },
    emits: ["close"],
    data: function () {
        return {
            image: '',
            loaded: false,
            showZoomImage: false,
            currentSelectedOption: null,
            moveToNext: false,
            moveToPrevious: false,
            disallowOptionPrevious: false,
            disallowOptionNext: false
        };
    },
    props: {
        open: Boolean,
        wheelView: String,
        wheel: {
            type: Object,
            required: true
        },
        wheelImgUrlBase: {
            type: String,
            required: true
        },
        vehicle: Object
    },
    computed: {
        getWheelViewKeys: function () {
            return {
                face: 'one',
                standar: 'two',
                beauty: 'three',
                one: 'face',
                two: 'standar',
                three: 'beauty'
            }
        },
        getWheelImages: function () {
            return {
                one: this.getWheelImagesUrl(this.wheel.Img0003),
                two: this.getWheelImagesUrl(this.wheel.Img0001),
                three: this.getWheelImagesUrl(this.wheel.Img0002)
            };
        },
        getBrandLogo: function () {
            if (this.wheel) {
                return `${this.$store.getters.wheelsBrandsUrlBase}${this.$store.getters.wheelsBrands[this.wheel.Brand]}`;
            }
            return '';
        },
        getClientLogo: function () {
            if (this.$store.getters.clientSettings.logo) {
                return this.$store.getters.clientSettings.logo;
            }
            return null;
        }
    },
    methods: {
        getWindowWidth: function () {
            return screen.width;
        },
        close: function () {
            this.showZoomImage = false;
            this.$emit('close', false);
        },
        getWheelImagesUrl: function (wheelImagePath) {
            if (this.wheelImgUrlBase && wheelImagePath) {
                return `${this.wheelImgUrlBase}${wheelImagePath}`;
            }
            return '';
        },
        selectedOption: function (val) {
            this.loaded = false;
            this.image = this.getWheelImages[val];
            this.currentSelectedOption = val;
            this.moveToNext = false;
            this.moveToPrevious = false;
        },
        wheelImageLoaded: function () {
            this.loaded = true;
        },
        disallowOption: function (val) {
            this.disallowOptionPrevious = val.previous;
            this.disallowOptionNext = val.next;
        },
        touchStart: function (touchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            const posYStart = touchEvent.changedTouches[0].clientY;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart, posYStart), {once: true});
        },
        touchEnd: function (touchEvent, posXStart, posYStart) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            const posYEnd = touchEvent.changedTouches[0].clientY;

            if (posXStart < posXEnd && Math.abs(posXStart - posXEnd) > 20 && Math.abs(posYStart - posYEnd) < 20) {
                if (this.disallowOptionPrevious) {
                    this.moveToNext = true; // swipe left
                } else {
                    this.moveToPrevious = true; // swipe right
                }
            } else if (posXStart > posXEnd) {
                if (this.disallowOptionNext) {
                    this.moveToPrevious = true; // swipe right
                } else {
                    this.moveToNext = true; // swipe left
                }
            }
        }
    },
    watch: {
        open: function (newVal) {
            this.showZoomImage = newVal;
            this.currentSelectedOption = this.getWheelViewKeys[this.wheelView];
        },
        wheel: function () {
            this.image = this.getWheelImages[this.getWheelViewKeys[this.wheelView]];
        },
        wheelView: function (newVal) {
            if (newVal == 'face' || newVal == 'beauty' || newVal == 'standar') {
                this.image = this.getWheelImages[this.getWheelViewKeys[newVal]];
                this.currentSelectedOption = this.getWheelViewKeys[newVal];
            }
        }
    }
}
</script>

<style lang="scss">
.zoom-container-wheels {
    position: relative;
    width: calc(500px + 0.5rem);
    max-width: 96vw;
    cursor: auto;

    .carrousel-arrows-container {
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 100%;
        padding: 0 5px;
    }

    img {
        max-width: 500px;
        filter: none;
        transition: filter .3s ease;
        -o-transition: filter .3s ease;
        -moz-transition: filter .3s ease;
        -webkit-transition: filter .3s ease;

        &.filter-blur {
            filter: blur(5px);
        }
    }

    .wheel-image-containter {
        width: 100%;
        max-width: 500px; //IMAGE SIZE
    }

    .client-logo-container {
        position: absolute;
        padding: 0 0;
        top: 0;
        left: 0;
        height: 50px;

        &.mobile {
            max-height: 30px;
        }
    }

    .info-container {
        font-size: 80%;
        line-height: 1.125;

        .wheel-brand-logo {
            max-height: 100%;
            max-width: 120px;

            &.small {
                max-height: 60px;
                max-width:70px;
            }
        }
    }
}
</style>
