<template>
  <div ref="mapContainer">
    <div class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
    name: "Locations",
    emits: [
        'updateLocations'
    ],
    props: {
        clientLocation: String,
        centerOn: String
    },
    data() {
        return {
            locations: null,
            google: null,
            map: null,
            marker: null,
            locationsCoordinates: {},
            latlngbounds : null
        }
    },
    computed: {
        customIcon: function () {
            return {
                path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
                fillColor: this.$store.getters.clientSettings.brandColor || process.env.VUE_APP_DEFAULT_BRAND_COLOR,
                fillOpacity: 1,
                strokeColor: '#000',
                strokeWeight: 2,
                scale: 1,
            }
        },
        clientIcon: function () {
            let icon = {...this.customIcon};
            icon.fillColor = '#EBEBEB';
            icon.strokeColor = '#1F456E';
            return icon;
        }
    },
    methods: {
        rad: function(x) {
            return x * Math.PI / 180;
        },
        getDistance: function(p1, p2) {
            var R = 6378137; // Earth’s mean radius in meter
            var dLat = this.rad(p2.lat() - p1.lat());
            var dLong = this.rad(p2.lng() - p1.lng());
            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.rad(p1.lat())) * Math.cos(this.rad(p2.lat())) *
                Math.sin(dLong / 2) * Math.sin(dLong / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d; // returns the distance in meter
        },
        orderLocationsByDistance: function(clientLocation) {
            let distances = [];
            let locations = [];
            for (const locationName in this.locationsCoordinates) {
                
                if (Object.hasOwnProperty.call(this.locationsCoordinates, locationName)) {
                    const location = this.locationsCoordinates[locationName];
                    distances.push([locationName, this.getDistance(clientLocation, location)]);
                }
            }

            distances.sort((a, b) => a[1] - b[1]);
            distances.forEach(distance => locations.push(this.locations[this.locations.findIndex( location => location.Name === distance[0])]));

            this.$emit('updateLocations', locations);
        },
        contentString: function (location) {
            return `<div id="content">
                <div id="siteNotice"></div>
                <h5 id="firstHeading" class="firstHeading">${location.Name}</h5>
                <div><small>${location.Address}</small></div>
                <div><small>${location.City}, ${location.State}, ${location.Zip}, ${location.Country}</small></div>
            `;
        },
        googleMaps: function () {
            const loader = new Loader({
                apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY,
                version: "weekly",
                libraries: ["places"]
            });

            loader
            .load()
            .then((google) => {
                this.google = google;
                const myLatlng = new google.maps.LatLng(37.90401510606881, -122.56466484617219);
                
                let mapOptions = {
                    zoom: 4,
                    center: myLatlng,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: [{
                        featureType: "road",
                        stylers: [
                            {visibility: "on"}
                        ]
                    }]
                }

                this.map = new google.maps.Map(this.$refs.googleMap, mapOptions);
                this.latlngbounds = new google.maps.LatLngBounds();

                this.locations.forEach((location) => {
                    const map = this.map;

                    const infowindow = new google.maps.InfoWindow({
                        content: this.contentString(location),
                    });

                    if (location.Coordinates[0] && location.Coordinates[1]) {
                        const locationLatlng = new google.maps.LatLng(location.Coordinates[0], location.Coordinates[1]);
                        const marker = new google.maps.Marker({
                            map: map,
                            position: locationLatlng,
                            icon: this.customIcon,
                            title: location.Name
                        });

                        marker.addListener("click", () => {
                            infowindow.open({
                            anchor: marker,
                            map,
                            shouldFocus: false,
                            });
                        });

                        this.locationsCoordinates[location.Name] = marker.getPosition();
                        this.latlngbounds.extend(marker.position);
                    }
                });
            });
        }
    },
    watch: {
        clientLocation: function (newVal) {
            this.googleMaps();

            if (newVal) {
                const geocoder = new this.google.maps.Geocoder();
                geocoder
                .geocode({ address: newVal })
                .then(({ results }) => {
                    const map = this.map;
                    const locationPosition = results[0].geometry.location;

                    if (this.marker) {
                        this.marker.setMap(null);
                    }

                    this.marker = new this.google.maps.Marker({
                        map: map,
                        position: locationPosition,
                        icon: this.clientIcon,
                        title: location.Name,
                        label: { color: '#000', fontWeight: 'bold', fontSize: '14px', text: 'Your location' }
                    });
                    this.latlngbounds.extend(this.marker.position);
                    this.map.setCenter(locationPosition);
                    this.orderLocationsByDistance(locationPosition);
 
                    //Center map and adjust Zoom based on the position of all markers.
                    this.map.setCenter(this.latlngbounds.getCenter());
                    this.map.fitBounds(this.latlngbounds);
                });
            }
        },
        centerOn: function (newVal) {
            this.map.setZoom(10);
            this.map.setCenter(this.locationsCoordinates[newVal]);
        }
    },
    mounted: function () {
        this.googleMaps();
    },
    beforeMount: function () {
        this.locations = this.$store.getters.clientSettings.locations || [];
    }
}
</script>

<style lang="scss" scoped>
    .google-map {
        width: 100%;
        height: 61.803vh;
    }
</style>