<template>
    <div class="position-relative">
        <div
            :class="[
                'color-picker-toggler mx-0 mx-sm-2 mx-md-0',
                enabled ? 'toggle-active cursor-pointer':'non-available-colorized'
            ]"
            @click="displayColorPicker"
        ></div>
    </div>
</template>

<script>

export default {
    name: "ColorPicker",
    emits: ['displayColorPicker'],
    props: {
        enabled: Boolean,
    },
    methods: {
        displayColorPicker: function () {
            this.$emit('displayColorPicker');
        }
    }
}
</script>

<style scoped lang="scss">
.color-picker-toggler {
    height: 32px;
    width: 32px;
    background-image: url('https://vvs.autosyncstudio.com/static/icons/colorpicker.png');
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 300px) {
    .color-picker-toggler {
        height: 28px;
        width: 28px;
    }
}
</style>
