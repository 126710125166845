<template>
    <div :class="['previewer-container px-4 px-xl-0', $store.getters.device.isLandscape && 'landscape']">
        <div
            v-if="(allowFrontImage || allowSideImage || allowBackImage) && hasWheels"
            class="h-100 d-flex justify-content-between align-items-center vehicle-view"
        >
            <div
                :class="[
                    'd-flex justify-content-center',
                    sideSelected ? 'align-items-end':'align-items-center',
                    sideSelected ? 'vehicle-view-selected':'vehicle-view-unselected',
                    loading && 'loading-blur',
                    getViewOrder.side, 
                ]"
            >
                <VehicleImageSmothSwap
                    v-if="allowSideImage && vehicleLargeImageSide"
                    :vehicle-large-image="vehicleLargeImageSide"
                    :vehicle-small-image="vehicleSmallImageSide"
                    :selected="sideSelected"
                    :view-side="'side'"
                    :wheel-images="wheelImages"
                    :coordinates="vehicleCoordinates"
                    :angles-img-codes="vehicleAnglesImgCodes"
                    :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
                    :wheel-diameter="wheelDiameter"
                    clicklable
                    @viewSelected="viewSelected('side', true)"
                    @showWheels="showWheels"
                    @touchstart="touchStart"
                />
            </div>
            <div
                :class="[
                    'd-flex justify-content-center',
                    frontSelected ? 'align-items-end':'align-items-center',
                    frontSelected ? 'vehicle-view-selected':'vehicle-view-unselected',
                    loading && 'loading-blur',
                    getViewOrder.front
                ]"
            >
                <VehicleImageSmothSwap
                    v-if="allowFrontImage && vehicleLargeImageFront"
                    :vehicle-large-image="vehicleLargeImageFront"
                    :vehicle-small-image="vehicleSmallImageFront"
                    :selected="frontSelected"
                    :view-side="'front'"
                    :wheel-images="wheelImages"
                    :coordinates="vehicleCoordinates"
                    :angles-img-codes="vehicleAnglesImgCodes"
                    :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
                    :wheel-diameter="wheelDiameter"
                    clicklable
                    @viewSelected="viewSelected('front', true)"
                    @showWheels="showWheels"
                    @touchstart="touchStart"
                    @imageLoaded="imageLoaded"
                />
            </div>
            <div 
                :class="[
                    'd-flex justify-content-center',
                    backSelected ? 'align-items-end':'align-items-center',
                    backSelected ? 'vehicle-view-selected':'vehicle-view-unselected',
                    loading && 'loading-blur',
                    getViewOrder.back
                ]"
            >
                <VehicleImageSmothSwap
                    v-if="allowBackImage && vehicleLargeImageBack"
                    :vehicle-large-image="vehicleLargeImageBack"
                    :vehicle-small-image="vehicleSmallImageBack"
                    :selected="backSelected"
                    :view-side="'back'"
                    :wheel-images="wheelImages"
                    :coordinates="vehicleCoordinates"
                    :angles-img-codes="vehicleAnglesImgCodes"
                    :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
                    :wheel-diameter="wheelDiameter"
                    clicklable
                    @viewSelected="viewSelected('back', true)"
                    @showWheels="showWheels"
                    @touchstart="touchStart"
                />
            </div>

            <div
                v-if="allowCarrousel && (!disallowOptionPrevious || !disallowOptionNext)"
                class="d-flex carrousel-arrow-controller ms-md-2 ms-xl-2 ps-1 ps-md-4"
                style="left: 0"
            >
                <span 
                    class="carrousel-arrow-option toggle-active cursor-pointer user-select-none p-1 rounded"
                    @click.stop="moveView('previous')"
                >
                    <Icon icon-name="navArrowLeft"/>
                </span>
            </div>
            
            <div
                v-if="allowCarrousel && (!disallowOptionPrevious || !disallowOptionNext)"
                class="d-flex carrousel-arrow-controller me-md-2 pe-1 pe-md-4"
                style="right: 0"
            >
                <span
                    class="carrousel-arrow-option toggle-active cursor-pointer user-select-none p-1 rounded"
                    @click.stop="moveView('next')"
                >
                    <Icon icon-name="navArrowRight"/>
                </span>
            </div>
        </div>

        <div
            v-else-if="!hasWheels"
            class="h-100 d-flex justify-content-between align-items-center vehicle-view"
        >
            <div
                :class="[
                    'd-flex justify-content-center position-relative',
                    'align-items-end',
                    'vehicle-view-unselected'
                ]"
            >
                <picture class="text-center">
                    <source media="(max-width:576px)" :srcset="vehicleSmallImageSide">
                    <img
                        :src="vehicleLargeImageSide"
                        alt=""
                        class="user-select-none w-50"
                    >
                </picture>
            </div>
            <div
                :class="[
                    'd-flex justify-content-center position-relative',
                    'align-items-end',
                    'vehicle-view-selected'
                ]"
            >
                <picture class="text-center">
                    <source media="(max-width:576px)" :srcset="vehicleSmallImageFront">
                    <img
                        :src="vehicleLargeImageFront"
                        alt=""
                        class="user-select-none w-100"
                    >
                </picture>
            </div>
            <div
                :class="[
                    'd-flex justify-content-center position-relative',
                    'align-items-end',
                    'vehicle-view-unselected'
                ]"
            >
                <picture class="text-center">
                    <source media="(max-width:576px)" :srcset="vehicleSmallImageBack">
                    <img
                        :src="vehicleLargeImageBack"
                        alt=""
                        class="user-select-none w-50"
                    >
                </picture>
            </div>
        </div>

        <div
            v-else
            class="h-100 d-flex justify-content-center align-items-center vehicle-view"
        >
            <div
                v-if="!allowCarrousel"
                :class="[
                    'd-flex justify-content-center position-relative',
                    'align-items-end',
                    'vehicle-view-selected'
                ]"
            >
                <picture class="text-center">
                    <source media="(max-width:576px)" srcset="https://vvs.autosyncstudio.com/static/not-available-350.png">
                    <img
                        src="https://vvs.autosyncstudio.com/static/not-available-960.png"
                        alt=""
                        class="user-select-none w-100"
                    >
                </picture>
            </div>
        </div>

        <slot/>
    </div>
</template>

<script>
import VehicleImageSmothSwap from '@/components/builder/VehicleImageSmothSwap.vue';
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'Previewer',
    components: {
        VehicleImageSmothSwap,
        Icon
    },
    emits: [
        'sideSelected',
        'zoomImageOn',
        'imageLoaded'
    ],
    data: function () {
        return {
            wheelImages: {},
            frontSelected: true,
            sideSelected: false,
            backSelected: false,
            allowFrontImage: true,
            allowSideImage: true,
            allowBackImage: true,
            allowZoom: false,
            allowCarrousel: false,
            disallowOptionPrevious: false,
            disallowOptionNext: false
        };
    },
    props: {
        loading: Boolean,
        vehicleSmallImageFront: {
            type: String,
            required: true
        },
        vehicleSmallImageSide: {
            type: String,
            required: true
        },
        vehicleSmallImageBack: {
            type: String,
            required: true
        },
        vehicleLargeImageFront: {
            type: String,
            required: true
        },
        vehicleLargeImageSide: {
            type: String,
            required: true
        },
        vehicleLargeImageBack: {
            type: String,
            required: true
        },
        vehicleCoordinates: {
            type: Object,
            required: false
        },
        vehicleAnglesImgCodes: {
            type: Object,
            required: false
        },
        vehicleImageWheelDiameter: {
            type: Object,
            required: false
        },
        wheel: {
            type: Object,
            required: false
        },
        wheelDiameter: {
            type: Number,
            required: false
        },
        wheelImgUrlBase: {
            type: String,
            required: true
        },
        hasWheels: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        getViewOrder: function () {
            if (this.sideSelected) {
                return {
                    back: 'order-0',
                    front: 'order-2',
                    side: 'order-1'
                };
            }

            if (this.frontSelected) {
                return {
                    back: 'order-2',
                    front: 'order-1',
                    side: 'order-0'
                };
            }
            
            return {
                back: 'order-1',
                front: 'order-0',
                side: 'order-2'
            };
        },
        getSelectedView: function () {
            if (this.sideSelected) {
                return 'side';
            }

            if (this.frontSelected) {
                return 'front';
            }

            if (this.backSelected) {
                return 'back';
            }

            return null;
        }
    },
    methods: {
        viewSelected: function(view, click) {
            let emit = false;

            if (view === 'front' && this.frontSelected) {
                emit = true;
            }
            if (view === 'side' && this.sideSelected) {
                emit = true;
            }
            if (view === 'back' && this.backSelected) {
                emit = true;
            }

            if (emit && click) {
                this.$emit('zoomImageOn', view);
            }

            switch (view) {
                case 'front':
                    this.frontSelected = true;
                    this.sideSelected = false;
                    this.backSelected = false;
                    break;
                    
                case 'side':
                    this.frontSelected = false;
                    this.sideSelected = true;
                    this.backSelected = false;
                    break;
                    
                case 'back':
                    this.frontSelected = false;
                    this.sideSelected = false;
                    this.backSelected = true;
                    break;
            
                default:
                    this.viewSelected('front');
                    break;
            }

            this.$emit('sideSelected', view);
        },
        moveView: function (side) {
            if (this.disallowOptionPrevious && side === 'previous') {
                side = 'next';
            }

            if (this.disallowOptionNext && side === 'next') {
                side = 'previous';
            }

            switch (side) {
                case 'previous':
                    if (this.frontSelected) {
                        this.viewSelected('side');
                    } else if (this.sideSelected) {
                        this.viewSelected('back');
                    } else if (this.backSelected) {
                        this.viewSelected('front');
                    }
                    break;

                case 'next':
                    if (this.frontSelected) {
                        this.viewSelected('back');
                    } else if (this.sideSelected) {
                        this.viewSelected('front');
                    } else if (this.backSelected) {
                        this.viewSelected('side');
                    }
                    break;
            
                default:
                    break;
            }
        },
        getOptionPreviousState: function () {
            let val = true;
            if (this.getSelectedView == 'front' && this.vehicleLargeImageSide && this.allowSideImage) {
                val = false;
            }
            if (this.getSelectedView == 'back' && this.vehicleLargeImageFront && this.allowFrontImage) {
                val = false;
            }
            if (this.getSelectedView == 'side' && this.vehicleLargeImageBack && this.allowBackImage) {
                val = false;
            }
            this.disallowOptionPrevious = val;
        },
        getOptionNextState: function () {
            let val = true;

            if (this.getSelectedView === 'front' && this.vehicleLargeImageBack && this.allowBackImage) {
                val = false;
            }
            if (this.getSelectedView === 'back' && this.vehicleLargeImageSide && this.allowSideImage) {
                val = false;
            }
            if (this.getSelectedView === 'side' && this.vehicleLargeImageFront && this.allowFrontImage) {
                val = false;
            }
            this.disallowOptionNext = val;
        },
        getCarrouselOptionState: function () {
            this.getOptionPreviousState();
            this.getOptionNextState();
        },
        selectedViewByImagesLoaded: function () {
            if (this.vehicleLargeImageFront && this.vehicleLargeImageFront != '') {
                this.viewSelected('front');
            } else if (this.vehicleLargeImageSide &&  this.vehicleLargeImageSide != '') {
                this.viewSelected('side');
            } else if (this.vehicleLargeImageBack &&  this.vehicleLargeImageBack != '') {
                this.viewSelected('back');
            }
            this.getCarrouselOptionState();
        },
        getWheelImages: function (wheel) {
            if (wheel) {
                return {
                    imgBaseUrl: this.wheelImgUrlBase || null,
                    images: wheel || null
                };
            }
            return {};
        },
        showWheels: function (response) {
            if (response.view === 'front') {
                this.allowFrontImage = response.allow;
            } else if (response.view === 'side') {
                this.allowSideImage = response.allow;
            } else if (response.view === 'back') {
                this.allowBackImage = response.allow;
            }

            if (this.allowFrontImage && this.frontSelected && this.vehicleLargeImageFront) {
                this.viewSelected('front');
            } else if (this.allowSideImage && this.sideSelected && this.vehicleLargeImageSide) {
                this.viewSelected('side');
            } else if (this.allowBackImage && this.backSelected && this.vehicleLargeImageBack) {
                this.viewSelected('back');
            } else {
                if (this.allowFrontImage && this.vehicleLargeImageFront) {
                    this.viewSelected('front');
                } else if (this.allowSideImage && this.vehicleLargeImageSide) {
                    this.viewSelected('side');
                } else if (this.allowBackImage && this.vehicleLargeImageBack) {
                    this.viewSelected('back');
                } else {
                    this.viewSelected('front');
                }
            }
            this.getCarrouselOptionState();
        },
        touchStart: function (touchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
        },
        touchEnd: function (touchEvent, posXStart) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            if (posXStart < posXEnd && !this.disallowOptionPrevious) {
                this.moveView('previous'); // swipe right
            } else if (posXStart > posXEnd && !this.disallowOptionNext) {
                this.moveView('next'); // swipe left
            }
        },
        imageLoaded: function (newVal) {
            if (!this.allowCarrousel && newVal) {
                this.$emit('imageLoaded', newVal);
            }
            this.allowCarrousel = newVal;
        }
    },
    watch: {
        vehicleLargeImageFront: function (newVal, oldVal) {
            if (newVal != oldVal && !newVal && newVal == '') {
                this.selectedViewByImagesLoaded();
            }
        },
        vehicleSmallImageFront: function (newVal, oldVal) {
            if (newVal != oldVal && !newVal && newVal == '') {
                this.selectedViewByImagesLoaded();
            }
        },
        vehicleLargeImageSide: function (newVal, oldVal) {
            if (newVal != oldVal && !newVal && newVal == '') {
                this.selectedViewByImagesLoaded();
            }
        },
        vehicleSmallImageSide: function (newVal, oldVal) {
            if (newVal != oldVal && !newVal && newVal == '') {
                this.selectedViewByImagesLoaded();
            }
        },
        vehicleLargeImageBack: function (newVal, oldVal) {
            if (newVal != oldVal && !newVal && newVal == '') {
                this.selectedViewByImagesLoaded();
            }
        },
        vehicleSmallImageBack: function (newVal, oldVal) {
            if (newVal != oldVal && !newVal && newVal == '') {
                this.selectedViewByImagesLoaded();
            }
        },
        backSelected: function (newVal) {
            if (newVal) {
                this.getCarrouselOptionState();
            }
        },
        sideSelected: function (newVal) {
            if (newVal) {
                this.getCarrouselOptionState();
            }
        },
        frontSelected: function (newVal) {
            if (newVal) {
                this.getCarrouselOptionState();
            }
        },
        wheel: function (newVal) {
            this.wheelImages = this.getWheelImages(newVal);
            
            this.allowFrontImage = true;
            this.allowSideImage = true;
            this.allowBackImage = true;
            this.getCarrouselOptionState();
        }
    },
    beforeMount: async function () {
        if (this.wheel) {
            this.wheelImages = this.getWheelImages(this.wheel);
            this.allowFrontImage = true;
            this.allowSideImage = true;
            this.allowBackImage = true;
        }

        this.selectedViewByImagesLoaded();
        this.getCarrouselOptionState();
        
        if (!this.hasWheels) {
            this.$emit('imageLoaded', true);
        }
    }
}
</script>

<style scoped lang="scss">
.previewer-container {
    position: relative;
    height: calc(100vw * (493/1920)); // 100vw * IMG_HEIGHT/IMG_WIDTH
    max-height: 493px;
    width: 100%;
    z-index: 0;
}

.vehicle-view {
    &#{-selected}, &#{-unselected} {
        transition: .3s;
    }

    &#{-selected} {
        opacity: 1;
        width: 50%;

        img {
            max-width: 100%;
        }
    }

    &#{-unselected} {
        opacity: .6;
        width: 25%;

        img {
            width: 75%;
        }
    }
}

.loading-blur {
    filter: grayscale(50%);
}

.carrousel {
    &#{-arrow-controller} {
        display: block;
        position: absolute;
        z-index: 4;
    }

    &#{-arrow-option} {
        background-color: rgba($color: white, $alpha: .3);
        color: rgba($color: black, $alpha: .5);
        font-size: 1.803rem;

        &:hover {
            color: rgba($color: black, $alpha: .61803);
        }

        &.carrousel-arrow-option-disabled {
            background-color: rgba($color: white, $alpha: .05);
            color: rgba($color: black, $alpha: .1);
            cursor: auto;
        }
    }
}

@media only screen and (min-width: 577px) and (max-width: 1200px) {
    .previewer-container {
        height: 38.197vh;
    
        .vehicle-view {
            &#{-selected} {
                width: calc(61.803vh * (376/320)); // 61.803vh * IMG_WIDTH/IMG_HEIGHT
                margin-left: auto;
                margin-right: auto;
            }

            &#{-unselected} {
                display: none !important;
            }
        }

        &.landscape {
            height: 100vh;

            .vehicle-view {
                &#{-selected} {
                    width: 76.394%;
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .previewer-container {
        height: calc(100vw * (320/376)); // 100vw * IMG_HEIGHT/IMG_WIDTH

        &.landscape {
            height: 100vh;
        }
    }
    
    .vehicle-view {
        &#{-selected} {
            width: 100%;
        }

        &#{-unselected} {
            display: none !important;
        }
    }
}
</style>
