<template>
    <div 
        :class="[
            'd-sm-none',
            'w-100',
            'text-muted text-end',
            !enableTools && 'disable-tools'
        ]"
        style="z-index: 3"
    >
        <div class="vehicle-info-mobile text-end mx-2">
            <small class="fw-medium">
                {{vehicle.Year}} {{vehicle.Make}} {{`${vehicle.Model} ${vehicle.Submodel || ''}`}} {{vehicle.Body}} {{ Object.keys(getRearAxleBySubmodel).length > 1 ? (vehicle.DRW ? 'DRW':'SRW'):''}}
            </small>
        </div>
    </div>

    <div 
        :class="[
            'd-flex justify-content-between align-items-center bg-customize-tools',
            'position-relative',
            'w-100 py-2 px-2 px-md-1 px-lg-4',
            !enableTools && 'disable-tools'
        ]"
        style="z-index: 3"
    >
        <div
            v-if="enableTools && wheel"
            class="position-relative d-flex justify-content-between justify-content-lg-start align-items-center pe-sm-3 mx-lg-2 order-2 order-lg-0"
            style="z-index: 0"
        >
            <div
                v-if="wheel.StyleId"
                class="d-none d-lg-flex justify-content-end align-items-center mx-0 px-2 px-sm-3 py-1 rounded bg-brand-primary text-white shadow-sm cursor-pointer toggle-active wheel-details"
                @click.stop="wheelDetails"
            >
                <h6 class="fw-medium m-0 py-1 px-4 px-xxl-5 user-select-none text-nowrap">INFO / BUY</h6>
            </div>

            <div
                v-if="wheel.StyleId"
                class="d-lg-none mx-0 px-3 py-1 rounded bg-brand-primary text-white shadow-sm cursor-pointer toggle-active user-select-none"
                @click.stop="wheelDetails"
            >
                <small class="fw-regular text-nowrap">INFO / BUY</small>
            </div>
            
            <div
                v-if="wheel.StyleId && wheelImgUrlBase"
                class="d-none d-lg-block wheel-banner text-center cursor-pointer"
                :style="getWheelImage"
                @click.stop="wheelDetails"
            ></div>
            
            <div
                v-if="wheel.StyleId && wheelImgUrlBase"
                class="d-none d-xl-flex flex-column justify-content-center align-items-center wheel-banner-detail-brand px-2 py-2 text-center"
            >
                <div v-if="wheel.Model" class="m-0 p-0" >
                    <h5 v-if="wheel.Model" class="m-0 p-0 text-nowrap fw-bold">{{wheel.Model}}</h5>
                </div>
                <div v-if="wheel.Brand" style="line-height: .8">
                    <span class="text-nowrap fw-medium">{{wheel.Brand}}</span>
                </div>
            </div>
        </div>
        <div v-else class="px-sm-3 mx-lg-2 order-1 order-lg-0"></div>

        <div
            v-if="wheel.StyleId"
            class="d-flex flex-fill justify-content-end d-lg-none px-2 order-1"
        >
            <div class="d-none d-sm-flex d-md-none flex-fill">
                <div class="vehicle-info-mobile text-end px-2">
                    <small class="fw-medium">
                        {{vehicle.Year}} {{vehicle.Make}} {{`${vehicle.Model} ${vehicle.Submodel || ''}`}} {{vehicle.Body}} {{ Object.keys(getRearAxleBySubmodel).length > 1 ? (vehicle.DRW ? 'DRW':'SRW'):''}}
                    </small>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between justify-content-lg-start align-items-center text-uppercase ps-0 ps-sm-2 ps-lg-0 mx-lg-2 order-0 order-lg-1">
            <div class="d-none d-md-flex justify-content-end align-items-center order-1 order-lg-0">
                <div class="rounded mx-3">
                    <img class="vehicle-make-logo cursor-pointer" :src="vehicleMakeLogoUrl" alt="" @click="$router.push(`/vehicles/make/${vehicle.Make}`)">
                </div>
                <div class="d-flex justify-content-start align-items-start text-uppercase">
                    <div v-if="vehicle.Make" class="vehicle-info rounded border has-options cursor-pointer">
                        <small
                            class="fw-medium px-2"
                            @click="$router.push(`/vehicles/make/${vehicle.Make}`)"
                        >
                            {{vehicle.Year}} {{vehicle.Make}}
                        </small>
                    </div>

                    <div class="position-relative user-select-none">
                        <div
                            v-if="vehicle.Make && (vehicle.Model || vehicle.Submodel)"
                            :class="[
                                'vehicle-info ms-2',
                                Object.keys(vehicleConfigurations.submodels).length > 1 && 'border rounded cursor-pointer has-options'
                            ]"
                            @click.stop="Object.keys(vehicleConfigurations.submodels).length > 1 && displayVehicleConfiguration('submodel')"
                        >
                            <small class="px-2 fw-medium">
                                {{`${vehicle.Model} ${vehicle.Submodel || ''}`}}
                            </small>
                        </div>

                        <div
                            v-show="showSubmodelOptions"
                            :class="[
                                'configuration-options-container rounded',
                                showSubmodelOptions && 'h-auto'
                            ]"
                            id="configuration-options-container-submodels"
                        >
                            <div class="bg-brand-body-secondary mx-2">
                                <div
                                    v-for="(vehiclesId, submodel) in vehicleConfigurations.submodels"
                                    :key="submodel"
                                    :class="[
                                        'd-flex flex-column justify-content-center align-items-start',
                                        'text-left text-nowrap',
                                        'submodel-options',
                                        'px-3 py-2 fw-regular',
                                        'position-relative',
                                        vehiclesId.includes(vehicle.Id) && 'text-brand-primary fw-medium selected'
                                    ]"
                                    @click.stop="changeVehicleConfiguration('submodel', submodel)"
                                >
                                    <small>{{submodel}}</small>

                                    <div v-if="hasDually(vehiclesId)" class="dually-tag fw-medium">SRW / DRW</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="position-relative user-select-none">
                        <div
                            v-if="vehicle.Body"
                            :class="[
                                'vehicle-info ms-2',
                                Object.keys(getBodysBySubmodel).length > 1 && 'border rounded cursor-pointer has-options',
                                Object.keys(getBodysBySubmodel).length <= 1 && Object.keys(vehicleConfigurations.submodels).length <= 1 && 'border-start'
                            ]"
                            @click.stop="Object.keys(getBodysBySubmodel).length > 1 && displayVehicleConfiguration('body')"
                        >
                            <small class="px-2 fw-medium">
                                {{vehicle.Body}}
                            </small>
                        </div>
                        
                        <div
                            v-show="showBodyOptions"
                            :class="[
                                'configuration-options-container rounded',
                                showBodyOptions && 'h-auto'
                            ]"
                            id="configuration-options-container-bodys"
                        >
                            <div class="bg-brand-body-secondary rounded px-2 py-1">
                                <div
                                    v-for="(vehiclesId, body) in getBodysBySubmodel"
                                    :key="body"
                                    :class="[
                                        'text-center',
                                        'body-options',
                                        'rounded py-2 my-3 my-md-2 fw-medium',
                                        vehiclesId.includes(vehicle.Id) && 'text-brand-primary selected',
                                        body === 'Standard Cab Pickup' && 'order-0',
                                        body === 'Extended Cab Pickup' && 'order-1',
                                        body === 'Crew Cab Pickup' && 'order-2'
                                    ]"
                                    @click.stop="changeVehicleConfiguration('body', body)"
                                >
                                    <img v-if="getBodyTypeData[body]" :src="getBodyTypeData[body].image || ''" :alt="getBodyTypeData[body].altText || ''" class="w-100">

                                    <div><small v-if="getBodyTypeData[body]">{{getBodyTypeData[body].description || ''}}</small></div>
                                    
                                    <div v-if="hasDually(vehiclesId)" class="dually-tag fw-medium text-center w-100">
                                        <small>SRW / DRW</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="position-relative user-select-none">
                        <div
                            v-if="Object.keys(getRearAxleBySubmodel).length > 1"
                            :class="[
                                'cursor-pointer',
                                'vehicle-info border',
                                'rounded has-options ms-2'
                            ]"
                            @click.stop="Object.keys(getRearAxleBySubmodel).length > 1 && displayVehicleConfiguration('dually')"
                        >
                            <small v-if="vehicle.Drw" class="px-2 fw-medium">DRW</small>
                            <small  v-else class="px-2 fw-medium">SRW</small>
                        </div>

                        <div
                            v-show="showDuallyOptions"
                            :class="[
                                'configuration-options-container rounded',
                                showDuallyOptions && 'h-auto'
                            ]"
                            id="configuration-options-container-duallys"
                        >
                            <div class="bg-brand-body-secondary rounded p-2">
                                <div
                                    v-for="(vehiclesId, dually) in getRearAxleBySubmodel"
                                    :key="dually"
                                    :class="[
                                        'text-center',
                                        'dually-options text-nowrap',
                                        'rounded py-2 my-3 my-md-2 fw-regular',
                                        vehiclesId.includes(vehicle.Id) && 'text-brand-primary fw-medium selected',
                                        dually === 'false' && 'order-0',
                                        dually === 'true' && 'order-1'
                                    ]"
                                    @click.stop="changeVehicleConfiguration('dually', dually)"
                                >
                                    <div v-if="dually === 'false'">
                                        <img src="https://vvs.autosyncstudio.com/static/icons/rear-axle/2WD.png" alt="NotDually" class="w-75 pb-1">
                                        <br>
                                        <small class="fw-medium">SRW</small>
                                    </div>
                                    
                                    <div v-else-if="dually === 'true'">
                                        <img src="https://vvs.autosyncstudio.com/static/icons/rear-axle/4WD.png" alt="Dually" class="w-75 pb-1">
                                        <br>
                                        <small class="fw-medium">DRW</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ms-lg-4 order-0 order-lg-2 order-xxl-1 position-relative">
                <ColorPickerToggler :enabled="colorPickerAllowed" @displayColorPicker="displayColorPicker"/>
            </div>

            <div
                v-if="Object.keys(vehicleConfigurations.submodels).length > 1 || Object.keys(vehicleConfigurations.bodys).length > 1 || Object.keys(vehicleConfigurations.duallys).length > 1"
                class="d-block d-md-none ms-2 configuration-icon cursor-pointer toggle-active order-1 order-lg-1 order-xxl-2"
                @click.stop="displayVehicleConfiguration('all')"
            ></div>
        </div>
    </div>
</template>

<script>
import ColorPickerToggler from '@/components/builder/ColorPickerToggler.vue';
import vehiclesBodyType from '@/components/builder/mixins/vehiclesBodyType';
import vehiclesBodyTypeBySubmodel from '@/components/builder/mixins/vehiclesBodyTypeBySubmodel';
import vehiclesRearAxleBySubmodel from '@/components/builder/mixins/vehiclesRearAxleBySubmodel';
import hasDually from '@/components/builder/mixins/hasDually';

export default {
    name: "CustomizeTools",
    emits: [
        'wheelDetails',
        'displayColorPicker',
        'displayVehicleConfiguration',
        'changeVehicleConfiguration'
    ],
    components: {
        ColorPickerToggler
    },
    mixins : [
        vehiclesBodyType,
        vehiclesBodyTypeBySubmodel,
        vehiclesRearAxleBySubmodel,
        hasDually
    ],
    data: function () {
        return {
            vehicleMakeLogoUrl: null,
            showSubmodelOptions: false,
            showBodyOptions: false,
            showDuallyOptions: false
        };
    },
    props: {
        vehicle: Object,
        vehicleConfigurations: Object,
        wheel: Object,
        wheelImgUrlBase: String,
        vehicleConfigurationOnBar: Boolean,
        enableTools: Boolean,
        colorPickerAllowed: Boolean
    },
    computed: {
        getWheelImage: function () {
            return `background-image: url('${this.wheelImgUrlBase}${this.wheel.Img0001}')`;
        }
    },
    methods: {
        getVehicleMake: async function () {
            if (this.vehicle) {

                const makes = this.$store.getters.vehiclesMakes.makes;
                const makeBaseUrl = this.$store.getters.vehiclesMakes.baseUrl;
                const makeId = this.vehicle.Make;
                const filterMake = (make) => make.Make === makeId;
                const make = makes.filter(filterMake);

                if (make && make.length > 0) {
                    return `${makeBaseUrl}${make[0].Logo}`;
                }
                return null;
            }
            return null;
        },
        displayColorPicker: function() {
            if (this.enableTools && this.colorPickerAllowed) {
                this.$emit('displayColorPicker', true);
            }
        },
        wheelDetails: function () {
            if (this.enableTools) {
                this.$emit('wheelDetails');
            }
        },
        displayVehicleConfiguration: function (option) {
            if (!this.enableTools) {
                return;
            }

            if (option === 'all') {
                this.$emit('displayVehicleConfiguration', option);
                return;
            }

            if (!this.vehicleConfigurationOnBar) {
                this.$emit('displayVehicleConfiguration', option);
            } else {
                const containerId = `configuration-options-container-${option}s`;
                const el = document.getElementById(containerId);
                const fnElementContains = ((e) => {
                    if (!el) {
                        window.removeEventListener('click', fnElementContains);
                    } else if (!el.contains(e.target)){
                        if (option=== 'submodel') {
                            this.showSubmodelOptions = false;
                        } else if (option=== 'body') {
                            this.showBodyOptions = false;
                        } else if (option=== 'dually') {
                            this.showDuallyOptions = false;
                        }
                        window.removeEventListener('click', fnElementContains);
                    }
                });

                if (option === 'submodel' && !this.showSubmodelOptions) {
                    this.showBodyOptions = false;
                    this.showDuallyOptions = false;
                    this.showSubmodelOptions = true;
                    window.addEventListener('click', fnElementContains);
                } else if (option === 'submodel' && this.showSubmodelOptions) {
                    this.showSubmodelOptions = false;
                }

                if (option === 'body' && !this.showBodyOptions) {
                    this.showSubmodelOptions = false;
                    this.showDuallyOptions = false;
                    this.showBodyOptions = true;
                    window.addEventListener('click', fnElementContains);
                } else if (option === 'body' && this.showBodyOptions) {
                    this.showBodyOptions = false;
                }

                if (option === 'dually' && !this.showDuallyOptions) {
                    this.showSubmodelOptions = false;
                    this.showBodyOptions = false;
                    this.showDuallyOptions = true;
                    window.addEventListener('click', fnElementContains);
                } else if (option === 'dually' && this.showDuallyOptions) {
                    this.showDuallyOptions = false;
                }
            }
        },
        changeVehicleConfiguration: function (option, value) {
            if (this.enableTools) {
                this.showSubmodelOptions = false;
                this.showBodyOptions = false;
                this.showDuallyOptions = false;
                this.$emit('changeVehicleConfiguration', {
                    option: option,
                    value: value
                });
            }
        }
    },
    beforeMount: async function () {
        this.getBodysBySubmodel;
        this.vehicleMakeLogoUrl = await this.getVehicleMake();
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.bg-customize-tools {
    background-color: rgba($color: white, $alpha: .12);
}

.disable-tools {
    filter: grayscale(100%);
}

small {
    font-size: 80% !important;
}

.configuration-icon {
    height: 30px;
    width: 30px;
    background-image: url('https://vvs.autosyncstudio.com/static/icons/car-information.svg');
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
}

.dually-tag {
    font-size: 9px;
    line-height: .61803;
    letter-spacing: 1px;
}

.wheel-details {
    max-width: none;

    .wheel-details-row {
        margin-left: .5rem;
    }
}

.vehicle-make-logo {
    max-width: 38px;
    max-height: 38px;
}

.vehicle-info-mobile {
    max-width: 96%;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.vehicle-info {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    div > &:hover.cursor-pointer,
    div > &:active.cursor-pointer {
        background: rgba($color: #000000, $alpha: .1);
    }

    &.border-start {
        border-color: map-get($theme-color, "text") !important;
    }

    &.border {
        border-color: map-get($theme-color, "text") !important;
        
        &.has-options {
            border-color: var(--client-color) !important;
            color: var(--client-color);

            &:hover {
                background-color: var(--client-color) !important;
                color: white;
            }
        }
    }
}

.wheel-banner {
    position: absolute;
    top: -300%;
    left: 0;
    height: 305%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    z-index: -1;
}

.wheel-banner-detail-brand {
    position: absolute;
    top: -300%;
    left: 90%;
    height: 400%;
    z-index: -2;
    text-shadow: 1px 1px map-get($theme-color, "light-gray") !important;

    span {
        font-size: 70%;
    }

    span {
        letter-spacing: 1px;
    }
}

.tool-tag {
    &:hover,
    &:active {
        background: rgba($color: #000000, $alpha: .1);
    }
}

.fs-tools {
    font-size: 11px;
}

// Vehicle configuration
.configuration-options-container {
    position: absolute;
    transform-origin: 100% 100%;
    bottom: calc(100% + 6.8103px);
    left: 0;
    height: 0;
    max-height: calc(100vw * (493/1920) - 65px);  // 100vw * IMG_HEIGHT/IMG_WIDTH (CHECK THIS ON PREVIEWER COMPONENT, IS THE RELATION TO CALCULATE THE PREVIEWER HEIGHT WITH THE CAR IMAGES STANDAR 1920x493 PX)
    transition: height .3s ease;
    -o-transition: height .3s ease;
    -moz-transition: height .3s ease;
    -webkit-transition: height .3s ease;
    font-size: .98em;
    letter-spacing: 1px;

    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;

    &::-webkit-scrollbar {
        display: none;
    }
}

.submodel-options {
    &:hover {
        @extend .cursor-pointer;
        @extend .text-brand-white;
        @extend .bg-brand-primary;
    }

    &.selected {
        border-left: 2px solid var(--client-color) !important;
    }
}

.body-options {
    @extend .cursor-pointer;
    
    &:hover {
        box-shadow: 0 0 0 1px map-get($theme-color, "muted") !important;
    }

    img {
        min-width: 120px;
        max-width: 120px;
    }

    &.selected {
        border: 1px solid var(--client-color) !important;

        &:hover {
            box-shadow: none !important;
        }
    }

    small {
        font-size: 70% !important;
    }
}

.dually-options {
    min-width: 75px;
    max-width: 75px;
    @extend .cursor-pointer;

    &:hover {
        box-shadow: 0 0 0 1px map-get($theme-color, "muted") !important;
    }

    &.selected {
        border: 1px solid var(--client-color) !important;

        &:hover {
            box-shadow: none !important;
        }
    }
}

@media only screen and (max-width: 1600px) {
    .vehicle-info {
        max-width: 150px;
    }
}

@media only screen and (max-width: 1399px) {
    .wheel-banner {
        position: absolute;
        top: -225%;
        left: 0;
        height: 225%;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        background-repeat: no-repeat;
        background-position: top right;
        background-size: cover;
        z-index: -1;
    }

    .wheel-banner-detail-brand {
        position: absolute;
        top: -225%;
        left: 85%;
        height: 325%;
        z-index: -2;

        h5 {
            font-size: 16px;
        }

        span {
            font-size: 10px;
        }
    }
}

@media only screen and (max-width: 1270px) {
    .vehicle-info {
        max-width: 120px;
    }
}


@media only screen and (max-width: 420px) {
    .wheel-details {
        max-width: 80px;

        span:nth-child(1) {
            max-width: 45px;
        }

        .wheel-details-row {
            margin-left: 0rem;
        }
    }
}

@media only screen and (max-width: 300px) {
    .configuration-icon {
        height: 26px;
        width: 26px;
    }
}

</style>
