<template>
    <div>
        <div v-if="arrows" class="w-100 user-select-none">
            <div class="d-flex justify-content-between align-items-center w-100">
                <span
                    :class="[
                        'carrousel-arrow-option toggle-active cursor-pointer p-1 rounded',
                        disallowOptionPrevious && !allowCircularSelection && 'carrousel-arrow-option-disabled',
                    ]"
                    @click.stop="(!disallowOptionPrevious || allowCircularSelection) && moveView('previous')"
                >
                    <Icon icon-name="navArrowLeft"/>
                </span>
                <span
                    :class="[
                        'carrousel-arrow-option toggle-active cursor-pointer p-1 rounded',
                        disallowOptionNext && !allowCircularSelection && 'carrousel-arrow-option-disabled',
                    ]"
                    @click.stop="(!disallowOptionNext || allowCircularSelection) && moveView('next')"
                >
                    <Icon icon-name="navArrowRight"/>
                </span>
            </div>
        </div>
        <div v-else class="user-select-none">
            <div class="d-flex justify-content-center align-items-center">
                <span
                    :class="[
                        'carrousel-option toggle-active cursor-pointer',
                        disallowOptionPrevious && !allowCircularSelection && 'carrousel-option-disabled',
                    ]"
                    @click.stop="(!disallowOptionPrevious || allowCircularSelection) && moveView('previous')"
                ></span>
                <span class="carrousel-option-selected"></span>
                <span
                    :class="[
                        'carrousel-option toggle-active cursor-pointer',
                        disallowOptionNext && !allowCircularSelection && 'carrousel-option-disabled',
                    ]"
                    @click.stop="(!disallowOptionNext || allowCircularSelection) && moveView('next')"
                ></span>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'ImagesCarrouselController',
    components: {
        Icon
    },
    emits: [
        'selectedOption',
        'disallowOption'
    ],
    data: function () {
        return {
            oneSelected: false,
            twoSelected: false,
            threeSelected: true,
            disallowOptionPrevious: false,
            disallowOptionNext: false,
        }
    },
    props: {
        arrows: Boolean,
        images: Object,
        defaultSelectedOption: String,
        moveToNext: Boolean,
        moveToPrevious: Boolean,
        allowCircularSelection: Boolean
    },
    computed: {
        getViewOrder: function () {
            if (this.oneSelected) {
                return {
                    two: 'order-0',
                    three: 'order-2',
                    one: 'order-1'
                };
            }

            if (this.threeSelected) {
                return {
                    two: 'order-2',
                    three: 'order-1',
                    one: 'order-0'
                };
            }
            
            return {
                two: 'order-1',
                three: 'order-0',
                one: 'order-2'
            };
        },
        getSelectedView: function () {
            if (this.twoSelected) {
                return 'two';
            }

            if (this.threeSelected) {
                return 'three';
            }

            if (this.oneSelected) {
                return 'one';
            }

            return null;
        }
    },
    methods: {
        viewSelected: function(view) {
            switch (view) {
                case 'three':
                    this.threeSelected = true;
                    this.twoSelected = false;
                    this.oneSelected = false;
                    break;
                    
                case 'two':
                    this.threeSelected = false;
                    this.twoSelected = true;
                    this.oneSelected = false;
                    break;
                    
                case 'one':
                    this.threeSelected = false;
                    this.twoSelected = false;
                    this.oneSelected = true;
                    break;
            
                default:
                    break;
            }

            this.$emit('selectedOption', view);
        },
        moveView: function (side) {
            if (this.allowCircularSelection && this.disallowOptionPrevious && side === 'previous') {
                side = 'next';
            }

            if (this.allowCircularSelection && this.disallowOptionNext && side === 'next') {
                side = 'previous';
            }

            switch (side) {
                case 'previous':
                    if (this.threeSelected) {
                        this.viewSelected('one');
                    } else if (this.twoSelected) {
                        this.viewSelected('three');
                    } else if (this.oneSelected) {
                        this.viewSelected('two');
                    }
                    break;

                case 'next':
                    if (this.threeSelected) {
                        this.viewSelected('two');
                    } else if (this.twoSelected) {
                        this.viewSelected('one');
                    } else if (this.oneSelected) {
                        this.viewSelected('three');
                    }
                    break;
            
                default:
                    break;
            }
            this.getCarrouselOptionState();
        },
        getOptionPreviousState: function () {
            let val = true;
            if (this.getSelectedView == 'three' && this.images.one) {
                val = false;
            }
            if (this.getSelectedView == 'one' && this.images.two) {
                val = false;
            }
            if (this.getSelectedView == 'two' && this.images.three) {
                val = false;
            }
            this.disallowOptionPrevious = val;
        },
        getOptionNextState: function () {
            let val = true;

            if (this.getSelectedView === 'three' && this.images.two) {
                val = false;
            }
            if (this.getSelectedView === 'one' && this.images.three) {
                val = false;
            }
            if (this.getSelectedView === 'two' && this.images.one) {
                val = false;
            }
            this.disallowOptionNext = val;
        },
        getCarrouselOptionState: function () {
            this.getOptionPreviousState();
            this.getOptionNextState();

            this.$emit('disallowOption', {
                previous: this.disallowOptionPrevious,
                next: this.disallowOptionNext
            });
        },
        selectedViewByImagesLoaded: function () {
            if (this.getSelectedView === 'three' && this.images.one) {
                this.viewSelected('three');
            } else if (this.getSelectedView === 'two' && this.images.two) {
                this.viewSelected('two');
            } else if (this.getSelectedView === 'one' && this.images.three) {
                this.viewSelected('one');
            } else{
                if (this.images.one && this.images.one != '') {
                    this.viewSelected('three');
                } else if (this.images.two &&  this.images.two != '') {
                    this.viewSelected('two');
                } else if (this.images.three &&  this.images.three != '') {
                    this.viewSelected('one');
                }
            }
        },
    },
    watch: {
        images: function (newVal) {
            if (newVal) {
                this.selectedViewByImagesLoaded();
                this.getCarrouselOptionState();
            }
        },
        defaultSelectedOption: function(newVal) {
            if (!this[`${newVal}Selected`]) {
                if (newVal === 'one' || newVal === 'two' || newVal === 'three') {
                    this.viewSelected(newVal);
                    this.getCarrouselOptionState();
                }
            }
        },
        moveToNext: function (newVal) {
            if (newVal && !this.disallowOptionNext) {
                this.moveView('next');
            }
        },
        moveToPrevious: function (newVal) {
            if (newVal && !this.disallowOptionPrevious) {
                this.moveView('previous');
            }
        }
    },
    beforeMount: function () {
        this.viewSelected(this.defaultSelectedOption);
        this.getCarrouselOptionState();
    }
}
</script>

<style scoped lang="scss">
.carrousel {
    $carrousel-option-size: 10px;

    &#{-option} {
        width: $carrousel-option-size * 3.14159265;
        height: $carrousel-option-size / 1.2;
        border: 1px solid gray;
        border-radius: $carrousel-option-size;
        background-color: rgba($color: gray, $alpha: .38197);
        margin: 1.1em $carrousel-option-size/3;

        &:hover {
            background-color: rgba($color: gray, $alpha: .61803);
        }

        &.carrousel-option-disabled {
            background-color: rgba($color: gray, $alpha: .2);
            cursor: auto;
            transform: none;
            border: none;
        }
    }

    &#{-arrow-option} {
        background-color: rgba($color: white, $alpha: .3);
        color: rgba($color: black, $alpha: .5);
        font-size: 1.803rem;

        &:hover {
            color: rgba($color: black, $alpha: .61803);
        }

        &.carrousel-arrow-option-disabled {
            background-color: rgba($color: white, $alpha: .05);
            color: rgba($color: black, $alpha: .1);
            cursor: auto;
        }
    }

    &#{-option-selected} {
        width: $carrousel-option-size * 3.14159265;
        height: $carrousel-option-size / 1.2;
        border: 1px solid gray;
        border-radius: $carrousel-option-size;
        background-color: rgba($color: white, $alpha: .61803);
        margin: 1.15em $carrousel-option-size;
    }
}
</style>