<template>
    <div class="position-relative w-100 h-100">
        <img
            :src="vehicleLargeImage || vehicleSmallImage"
            alt=""
            style="opacity: 0"
            :class="[
                selected ? 'w-100':'w-50'
            ]"
        >
        <VehicleImage
            :class="[
                'vehicle-image',
                'main-image',
                !loading && 'loaded'
            ]"
            :vehicle-large-image="vehicleLargeImage"
            :vehicle-small-image="vehicleSmallImage"
            :selected="selected"
            :view-side="viewSide"
            :wheel-images="wheelImages"
            :coordinates="coordinates"
            :angles-img-codes="anglesImgCodes"
            :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
            :wheel-diameter="wheelDiameter"
            :clicklable="clicklable"
            :zoom="zoom"
            @imageLoaded="imageLoaded"
            @viewSelected="viewSelected"
            @showWheels="showWheels"
            @touchstart="touchStart"
        />

        <div v-if="oldWheelImages" class="vehicle-image w-100">
            <VehicleImage
                :class="[
                    'preloader-image',
                    !loading && showOldImage ? (!oldVehicleUpdated ? 'loaded':''):'',
                ]"
                :vehicle-large-image="oldVehicle.vehicleLargeImage"
                :vehicle-small-image="oldVehicle.vehicleSmallImage"
                :selected="selected"
                :view-side="viewSide"
                :wheel-images="oldWheelImages"
                :coordinates="oldVehicle.coordinates"
                :angles-img-codes="oldVehicle.anglesImgCodes"
                :vehicle-image-wheel-diameter="vehicleImageWheelDiameter"
                :wheel-diameter="wheelDiameter"
                :zoom="zoom"
                @showWheels="showOldWheels"
            />
        </div>
    </div>
</template>

<script>
import VehicleImage from '@/components/builder/VehicleImage.vue';

export default {
    name: 'VehicleImageSmothSwap',
    components: {
        VehicleImage
    },
    emits: [
        'viewSelected',
        'showWheels',
        'touchstart',
        'imageLoaded'
    ],
    props: {
        vehicleLargeImage: {
            type: String,
            required: true
        },
        vehicleSmallImage: {
            type: String,
            required: false
        },
        selected: {
            type: Boolean,
            required: true
        },
        viewSide: {
            type: String,
            required: true
        },
        wheelImages: {
            type: Object,
            required: true
        },
        coordinates: {
            type: Object,
            required: true
        },
        anglesImgCodes: {
            type: Object,
            required: true
        },
        vehicleImageWheelDiameter: {
            type: Object,
            required: false
        },
        wheelDiameter: {
            type: Number,
            required: false
        },
        clicklable: Boolean,
        zoom: Boolean
    },
    data: function () {
        return {
            loading: false,
            showOldImage: false,
            oldWheelImages: null,
            oldVehicle: {},
            oldVehicleUpdated: false
        };
    },
    methods: {
        viewSelected: function () {
            this.$emit('viewSelected');
        },
        showWheels: function (value) {
            this.loading = false;

            if (this.oldVehicleUpdated) {
                this.oldVehicleUpdated = false;
                this.oldVehicle.vehicleLargeImage = this.vehicleLargeImage;
                this.oldVehicle.vehicleSmallImage = this.vehicleSmallImage;
                this.oldVehicle.coordinates = this.coordinates;
                this.oldVehicle.anglesImgCodes = this.anglesImgCodes;
            }

            if (this.oldWheelImages != this.wheelImages) {
                setTimeout(async () => {
                    this.oldWheelImages = {...this.wheelImages};
                }, 680);
            }
            
            this.$emit('showWheels', value);
        },
        showOldWheels: function (value) {
            this.showOldImage = value.allow;
        },
        touchStart: function (touchEvent) {
            this.$emit('touchstart', touchEvent);
        },
        imageLoaded: function (value) {
            if (this.oldVehicleUpdated && value) {
                this.loading = false;
                this.oldVehicleUpdated = false;
                this.oldVehicle.vehicleLargeImage = this.vehicleLargeImage;
                this.oldVehicle.vehicleSmallImage = this.vehicleSmallImage;
                this.oldVehicle.coordinates = this.coordinates;
                this.oldVehicle.anglesImgCodes = this.anglesImgCodes;
            }
            this.$emit('imageLoaded', value);
        }
    },
    watch: {
        wheelImages: function () {
            this.loading = true;
        },
        vehicleLargeImage: function () {
            if (!this.oldVehicleUpdated) {
                this.loading = true;
                this.oldVehicleUpdated = true;
            }
        },
        vehicleSmallImage: function () {
            if (!this.oldVehicleUpdated) {
                this.loading = true;
                this.oldVehicleUpdated = true;
            }
        }
    },
    beforeMount: function () {
        this.oldVehicle.vehicleLargeImage = this.vehicleLargeImage;
        this.oldVehicle.vehicleSmallImage = this.vehicleSmallImage;
        this.oldVehicle.coordinates = this.coordinates;
        this.oldVehicle.anglesImgCodes = this.anglesImgCodes;
    }
}
</script>

<style scoped lang="scss">
.vehicle-image {
    position: absolute;
    top: 0;
    left: 0;

    &.main-image {
        opacity: 0;
        filter: grayscale(100%);
        z-index: 1;

        &.loaded {
            opacity: 1;
            filter: grayscale(0);
            transition: opacity .6s ease, filter 1.2s ease-in-out;
            -o-transition: opacity .6s ease, filter 1.2s ease-in-out;
            -moz-transition: opacity .6s ease, filter 1.2s ease-in-out;
            -webkit-transition: opacity .6s ease, filter 1.2s ease-in-out;
        }
    }

    .preloader-image {
        opacity: 1;
        filter: grayscale(100%);
        z-index: 0;

        &.loaded {
            opacity: 0;
            transition: opacity 1.3606s ease;
            -o-transition: opacity 1.3606s ease;
            -moz-transition: opacity 1.3606s ease;
            -webkit-transition: opacity 1.3606s ease;
        }
    }
}
</style>