<template>
    <div
        :class="[
            !fullSize && 'col-6 col-md-4 col-lg-3 col-xl-2',
            'p-2 p-xxl-3 position-relative'
        ]"
    >
        <div
            :class="[
                'position-absolute',
                isFavorite ? 'text-brand-primary' : 'text-brand-muted',
                'mt-2 me-3 me-md-4'
            ]"
            style="right: 0; font-size: 18px"
            @click.stop="wheelFavoriteToggle"
        >
            <Icon
                class="toggle-active cursor-pointer"
                :icon-name="isFavorite ? 'star' : 'starOutline'"
            />
        </div>
        
        <div
            :class="[
                'wheel-card h-100',
                'd-flex flex-column justify-content-between',
                'px-1 py-2 p-sm-3 p-md-2 p-xxl-3',
                'border-1 rounded',
                'bg-brand-light-gray shadow-sm',
                'toggle-active',
                wheelIsSelected && 'bg-brand-light-gray',
                wheelIsSelected && wheelSelectionBorder && 'border border-2'
            ]"
            @click="wheelSelect()"
        >
            <div class="w-100 text-center wheel-image-container px-2 px-sm-0">
                <img
                    :src="wheelImage"
                    class="wheel-image user-select-none"
                    alt=""
                    width="100"
                    height="100"
                >
            </div>

            <div class="w-100 pt-2 pt-sm-3 px-2 text-center wheel-model">
                <span class="fw-medium">{{wheel.Model || ''}}</span>
            </div>
            
            <div v-if="favoriteCard" class="text-center" style="font-size: 90%">
                <small class="fw-medium py-1">
                    <span v-if="(wheel.ShortFinish || wheel.ShortColor) && finishAndColorAreDifferents(wheel.ShortFinish, wheel.ShortColor)">{{wheel.ShortFinish}} {{wheel.ShortColor}}</span>
                    <span v-else>{{wheel.ShortColor}}</span>

                    <span v-if="(wheel.ShortFinish || wheel.ShortColor) && wheel.Accent"> / </span>
                    {{wheel.Accent}}
                </small>
            </div>
            <div v-else-if="wheelIsSelected" class="text-center" style="font-size: 90%">
                <small class="fw-medium py-1">
                    <span v-if="(wheelSelected.ShortFinish || wheelSelected.ShortColor) && finishAndColorAreDifferents(wheelSelected.ShortFinish, wheelSelected.ShortColor)">{{wheelSelected.ShortFinish}} {{wheelSelected.ShortColor}}</span>
                    <span v-else>{{wheelSelected.ShortColor}}</span>

                    <span v-if="(wheelSelected.ShortFinish || wheelSelected.ShortColor) && wheelSelected.Accent"> / </span>
                    {{wheelSelected.Accent}}
                </small>
            </div>
            <div v-else class="text-center" style="font-size: 90%">
                <small class="fw-medium py-1">
                    <span v-if="(wheel.ImgShortFinish || wheel.ImgShortColor) && finishAndColorAreDifferents(wheel.ImgShortFinish, wheel.ImgShortColor)">{{wheel.ImgShortFinish}} {{wheel.ImgShortColor}}</span>
                    <span v-else>{{wheel.ImgShortColor}}</span>

                    <span v-if="(wheel.ImgShortFinish || wheel.ImgShortColor) && wheel.ImgAccent"> / </span>
                    {{wheel.ImgAccent}}
                </small>
            </div>

            <div class="text-center">
                <small>{{wheel.Brand || ''}}</small>
            </div>

            <div v-if="favoriteCard" class="w-100 my-1 border-top border-bottom py-2">
                <span class="fw-medium user-select-none cursor-pointer toggle-active">{{wheel.Diameter}}" x {{wheel.Width}}"</span>
            </div>

            <div v-if="favoriteCard && wheel.Pn" class="my-1">
                <small class="fw-medium user-select-none cursor-pointer toggle-active mx-1 p-1 text-wrap">P/N:</small>
                <br>
                <small class="fw-medium user-select-none cursor-pointer toggle-active mx-1 p-1 text-wrap">{{wheel.Pn}}</small>
            </div>

            <div v-if="!favoriteCard">
                <div class="w-100 mt-1 text-center wheel-model">
                    <div class="d-flex flex-colum flex-wrap justify-content-center align-items-center w-100">
                        <div
                            v-for="(diameter, index) in wheel.Diameters"
                            :key="index"
                            class="my-2"
                        >
                            <small
                                :class="[
                                    'diameter-option',
                                    wheelIsSelected && wheelDiameter === diameter && 'diameter-selected',
                                    'border border-2 rounded',
                                    'fw-medium',
                                    'user-select-none cursor-pointer toggle-active',
                                    'mx-1 p-1'
                                ]"
                                @click.stop="wheelSelect('diameter', diameter)"
                            >{{diameter}}"</small>
                        </div>
                    </div>
                </div>

                <div class="my-1 my-sm-2 mx-2 px-1 px-sm-3 text-brand-gray">
                    <div v-if="(!wheelSelected.Colors || !wheelIsSelected) && wheel.ShortColors && wheel.ShortColors.length > 1" class="d-flex flex-colum flex-wrap justify-content-center align-items-center w-100">
                        <div
                            v-for="(color, index) in wheel.ShortColors"
                            :key="index"
                            :class="[
                                'color-sample px-1',
                                wheelIsSelected && 'cursor-pointer',
                                wheel.ShortColors.length > 3 && 'mt-1'
                            ]"
                        >
                            <div
                                :class="[
                                    'w-100 h-100',
                                    'border border-1 rounded',
                                    'shadow-sm',
                                    wheelIsSelected && colorSelected === color && 'color-selected'
                                ]"
                                :style="getColorBrushed(color)"
                                @click.stop="wheelSelect('color', color)"
                            ></div>
                        </div>
                    </div>

                    <div
                        v-if="wheelSelected.Colors && wheelIsSelected && Object.keys(wheelSelected.Colors).length > 1"
                        class="d-flex flex-colum flex-wrap justify-content-center align-items-center w-100"
                    >
                        <div
                            v-for="(color, index) in wheelSelected.Colors"
                            :key="index"
                            :class="[
                                'color-sample px-1',
                                wheelIsSelected && 'cursor-pointer',
                                Object.keys(wheelSelected.Colors).length > 3 && 'mt-1'
                            ]"
                        >
                            <div
                                :class="[
                                    'w-100 h-100',
                                    'border border-1 rounded',
                                    'shadow-sm',
                                    wheelIsSelected && colorSelected === index && 'color-selected'
                                ]"
                                :style="getColorBrushed(index)"
                                @click.stop="wheelSelect('color', index)"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import colorBrushed from '@/utils/colorBrushed.js';
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'WheelCard',
    components: {
        Icon
    },
    emits: [
        'wheelSelect',
        'wheelFavoriteToggle'
    ],
    data: function () {
        return {
            styleImage: null,
            colorSelected: null,
            diameterSelected: null
        };
    },
    props: {
        wheel: {
            type: Object,
            required: true
        },
        wheelBaseUrl: {
            type: String,
            required: true
        },
        wheelSelected: {
            type: Object,
            required: false
        },
        wheelSelectedImage: {
            type: String,
            required: false
        },
        wheelSelectionBorder: {
            type: Boolean,
            default: false,
            required: false
        },
        wheelDiameter: {
            type: Number,
            required: false
        },
        vehicleId: {
            type: Number,
            required: false
        },
        fullSize: Boolean,
        favoriteCard: Boolean
    },
    computed: {
        getWheelUrl: function () {
            if (this.wheel.Img0001) {
                return `${this.wheelBaseUrl}${this.wheel.Img0001}`;
            }
            return null;
        },
        wheelImage: function () {
            if (this.wheelIsSelected && this.styleImage && this.styleImage !== this.getWheelUrl) {
                return this.styleImage;
            } else {
                return this.getWheelUrl;
            }
        },
        wheelIsSelected: function () {
            if (!this.wheelSelected) return false;
            
            return this.wheelSelected.StyleId == this.wheel.StyleId;
        },
        isFavorite: function() {
            const storedFavorites = this.$store.getters.wheelFavorites;
            const wheelId = this.wheelIsSelected ? this.wheelSelected.Id : this.wheel.ImgWheelId || this.wheel.Id;
            const styleId = `${this.wheel.StyleId}`;
            
            if (this.favoriteCard) {
                return true;
            }

            if (storedFavorites) {
                const isFavorite = Object.keys(storedFavorites)
                    .filter( key => storedFavorites[key]['styles'] && storedFavorites[key]['styles'][styleId] && storedFavorites[key]['styles'][styleId][`${wheelId}`] );
                
                return isFavorite.length > 0;
            }

            return false;
        }
    },
    methods: {
        wheelSelect: function (action, value, addToFavorites = null) {
            if (action && this.wheelIsSelected) {
                this.colorSelected = action === 'color' ? value : this.colorSelected;
                this.diameterSelected = action === 'diameter' ? value : this.diameterSelected;
                this.$emit('wheelSelect', {style: this.wheel, color: this.colorSelected, diameter: this.diameterSelected});
            } else {
                this.$emit('wheelSelect', {style: this.wheel, color: null, diameter: null, favorite: addToFavorites});
            }
        },
        getColorBrushed: function (primary, secondary) {
            return colorBrushed(primary, secondary);
        },
        finishAndColorAreDifferents: function (finish, color) {
            const finishParsed = finish ? finish.toUpperCase().replace(' ', '') : '';
            const colorParsed = color ? color.toUpperCase().replace(' ', '') : '';

            const finishOptions = [finishParsed, `DARK${finishParsed}`];
            const colorOptions = [colorParsed, `DARK${colorParsed}`];
            return !(finishOptions.filter(x => colorOptions.includes(x)).length > 0);
        },
        wheelFavoriteToggle: function () {
            if (!this.wheelIsSelected && !this.isFavorite) {
                this.wheelSelect(null, null, true);
            } else {
                const wheel =this.wheelIsSelected ? this.wheelSelected : this.wheel;
                this.$emit('wheelFavoriteToggle', wheel);
            }
        }
    },
    watch: {
        wheelSelected: function(newVal) {
            if (newVal.StyleId === this.wheel.StyleId) {
                this.colorSelected = newVal.ShortColor;
            } 
        },
        wheelIsSelected: function (newVal) {
            if (!newVal) {
                this.colorSelected = null;
                this.diameterSelected = null;
            } else {
                this.colorSelected = this.wheelSelected.ShortColor || (this.wheel.ImgShortColor ? this.wheel.ImgShortColor : null);
                this.diameterSelected = this.wheelSelected.Diameter || (this.wheel.ImgDiameter ? this.wheel.ImgDiameter : null);
            }
        },
        wheelSelectedImage: function (newVal) {
            if (newVal && this.wheelIsSelected)  {
                this.styleImage = `${this.wheelBaseUrl}${newVal}`;
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

img {
    height: auto;
}

.color-sample {
    min-width: 30%;
    max-width: 30%;
    height: 15px;

    & .border {
        border-color: gray !important;

        &.color-selected {
            border-color: var(--client-color) !important;
            border-width: 2px !important;
        }
    }
}

.wheel-card {
    cursor: pointer;
    
    &:hover, &:active {
        box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
        @extend .bg-brand-light-gray;
    }

    &.border {
        border-color: var(--client-color) !important;

        &, & img {
            cursor: auto;
        }

        &:active {
            transform: none;
        }
    }

    .wheel-image-container {
        min-height: 100px;
        min-width: 100px;
    }

    .wheel-image {
        width: 100%;
    }

    .wheel-model {
        margin-top: -10px;
        line-height: 1.1rem;
    }

    .diameter-option {
        &.diameter-selected {
            border-color: var(--client-color) !important;
        }

        &:hover {
            background-color: rgba($color: #000000, $alpha: .1);
        }
    }
}

@media only screen and (max-width: 576px) {
    .wheel-model {
        span {
            font-size: 13px;
        }

        small {
            font-size: 11px;
        }
    }
}
</style>
