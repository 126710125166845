var vehiclesBodyTypeBySubmodel = {
    computed: {
        getBodysBySubmodel: function () {
            if (!this.vehicleConfigurations) {
                return {}
            }

            const submodelVehicles = this.vehicleConfigurations.submodels[this.vehicle.Submodel] || [];
            const bodyTypes = this.vehicleConfigurations.bodys;
            let bodyTypesBySubmodel = {};

            submodelVehicles.forEach(vehicleId => {
                for (const body in bodyTypes) {
                    if (bodyTypes[body].includes(vehicleId)) {
                        if (!bodyTypesBySubmodel[body]) {
                            bodyTypesBySubmodel[body] = [vehicleId];
                        } else {
                            bodyTypesBySubmodel[body].push(vehicleId);
                        }
                    } 
                }
            });

            return bodyTypesBySubmodel;
        },
        conflicting: function () {
            // eslint-disable-next-line
            console.log('From mixin vehiclesBodyTypeBySubmodel')
        }
    }
}

export default vehiclesBodyTypeBySubmodel;
